import { Api } from '@services';
import { useEffect, useState } from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { IdentifiersInput, KeywordsInput, SingleAssetInput } from './inputs';

const StyledWrapper = styled.div<{ direction: string }>`
  display: grid;
  grid-template-columns: ${(props) => (props.direction === 'row' ? '1fr 2fr' : 'none')};
  grid-template-rows: ${(props) => (props.direction === 'column' ? 'auto' : 'auto')};
  grid-gap: ${(props) => (props.direction === 'column' ? '.8rem' : '1rem')};
  padding: ${(props) => (props.direction === 'column' ? '0 0 .4rem 0' : '0')};
`;

interface IAssetFilterProps {
  onChange: (filter: string) => void;
  assetFilterIsValid?: boolean;
  assetFilterIsInvalid?: boolean;
  onFilterOptionChange?: (option: string) => void;
  direction?: string;
  disabled?: boolean;
  disabledFilters: any[];
  filterIsValid?: boolean;
  filterIsInvalid?: boolean;
  idDirection?: string;
  initFilter?: any;
  location?: string;
  reset?: boolean;
  cancel?: boolean;
}

export function AssetFilter({
  onChange,
  assetFilterIsValid,
  assetFilterIsInvalid,
  onFilterOptionChange,
  direction,
  disabled,
  disabledFilters,
  filterIsValid,
  filterIsInvalid,
  idDirection,
  initFilter,
  reset,
  cancel,
  location,
}: IAssetFilterProps) {
  const [batteryComparison, setBatteryComparison] = useState(0); // 0 for greater than, 1 for less than
  const [batteryValue, setBatteryValue] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterType, setFilterType] = useState<string>(null);
  const [filter, setFilter] = useState<any>(null);
  const [key, setKey] = useState<string>('0');
  const [isDockLoaded, setIsDockLoaded] = useState<boolean>(true);

  const allFilterTypes = [
    location === 'bulkTrigger' ? 'Alert All' : 'Load All',
    'Device ID',
    'Identifier',
    'Keyword',
    'Map',
    'Name',
    'Place',
    'Single Asset',
    'Active Assets',
    'Inactive Assets',
    'Battery Value',
  ];
  let filterTypes = allFilterTypes.filter(
    (item) => !disabledFilters.some((value) => item.toLowerCase().includes(value)),
  );

  useEffect(() => {
    if (reset) {
      setFilterType('-1');
    }
  }, [reset]);

  useEffect(() => {
    if (cancel) {
      setKey('1');
    } else {
      setKey('0');
    }
  }, [cancel]);

  // Reset batteryValue state when a new filter is selected
  useEffect(() => {
    setBatteryValue('');
  }, [filterType]);

  // This is used for default asset filters
  useEffect(() => {
    let str = null;
    let type = filterType;

    if (initFilter) {
      const fromIndex = initFilter.indexOf('(');
      const toIndex = initFilter.indexOf(')');

      if (initFilter.includes('active')) {
        type = initFilter;
      } else {
        type = initFilter === 'all' ? 'all' : initFilter?.substring(0, fromIndex);
      }

      switch (type) {
        case 'all':
          type = location === 'bulkTrigger' ? 'Alert All' : 'Load All';
          str = 'all';
          break;
        case 'id':
          type = 'Single Asset';
          setIsLoading(true);
          getAssetName(initFilter.substring(fromIndex + 1, toIndex));
          break;
        case 'identifiers':
          type = 'Identifier';
          str = initFilter.substring(fromIndex + 1, toIndex);
          break;
        case 'keywords':
          type = 'Keyword';
          str = initFilter.substring(fromIndex + 1, toIndex);
          break;
        case 'map':
          type = 'Map';
          str = initFilter.substring(fromIndex + 1, toIndex);
          break;
        case 'name':
          type = 'Name';
          str = initFilter.substring(fromIndex + 1, toIndex);
          break;
        case 'place':
          type = 'Place';
          str = initFilter.substring(fromIndex + 1, toIndex);
          break;
        case 'deviceId':
          type = 'Device ID';
          str = initFilter.substring(fromIndex + 1, toIndex);
          break;
        case 'active(true)':
          type = 'Active Assets';
          str = initFilter.substring(fromIndex + 1, toIndex);
          break;
        case 'active(false)':
          type = 'Inactive Assets';
          str = initFilter.substring(fromIndex + 1, toIndex);
          break;
      }
    }
    setFilterType(type);
    setFilter(str);

    return () => {
      setIsDockLoaded(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initFilter]);

  /**
   * Func to fetch assetNames for certain asset filter types. Ex. Single Name
   * @param assetId - The assetId that will be used for search
   */
  const getAssetName = async (assetId: string) => {
    const fetch = async () => {
      try {
        const resp = await Api.get<any[]>(`/assets/filtered/id(${assetId})/namesAndIds`);
        if (isDockLoaded) {
          setFilter(resp.data[0]?.name);
          setIsLoading(false);
        }
      } catch (e) {
        console.warn(e);
      }
    };
    fetch();
  };

  return (
    <>
      <StyledWrapper direction={direction}>
        <Form.Select
          isInvalid={assetFilterIsInvalid ?? false}
          isValid={assetFilterIsValid ?? false}
          disabled={disabled}
          value={filterType ?? '-1'}
          onChange={(e) => {
            setFilter(null);
            onChange(null);
            let value: any = e.target.value;

            if (value === 'Active Assets') {
              onChange('active(true)');
              setFilterType(value);
            } else if (value === 'Inactive Assets') {
              onChange('active(false)');
              setFilterType(value);
            } else if (value !== '-1') {
              if (value === (location === 'bulkTrigger' ? 'Alert All' : 'Load All')) {
                onChange('all');
                setFilterType(location === 'bulkTrigger' ? 'Alert All' : 'Load All');
              } else {
                setFilterType(value);
              }
            }
          }}
        >
          <option disabled key={-1} value={-1}>
            {location === 'bulkTrigger' || disabledFilters.includes('all')
              ? 'Select Filter'
              : 'Select Filter or Load All'}
          </option>
          {filterTypes.map((value, index) => {
            return (
              <option key={index} value={value}>
                {value === 'Identifier' ? 'Key-Value Pair' : value}
              </option>
            );
          })}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          * Please select an Asset Filter
        </Form.Control.Feedback>
        {filterType === 'Identifier' && (
          <>
            <IdentifiersInput
              key={key}
              direction={idDirection}
              valid={filterIsValid ?? false}
              invalid={filterIsInvalid ?? false}
              disabled={disabled}
              initFilter={filter || ''}
              onFilterChange={onChange}
            />
          </>
        )}
        {filterType === 'Keyword' && (
          <>
            <KeywordsInput
              key={key}
              valid={filterIsValid ?? false}
              invalid={filterIsInvalid ?? false}
              disabled={disabled}
              initFilter={filter || ''}
              onFilterChange={onChange}
            />
          </>
        )}
        {filterType === 'Map' && (
          <div title="Type out words, phrases, letters, numbers, or special characters to filter Map Names containing those items.">
            <Form.Control
              key={key}
              className="w-100"
              isValid={filterIsValid ?? false}
              isInvalid={filterIsInvalid ?? false}
              disabled={disabled}
              type={'text'}
              placeholder="Map Name contains..."
              defaultValue={filter || ''}
              onChange={(e) =>
                e.target.value === '' ? onChange(null) : onChange(`map(${e.target.value})`)
              }
            />
          </div>
        )}
        {filterType === 'Name' && (
          <div title="Type out words, phrases, letters, numbers, or special characters to filter Asset Names containing those items.">
            <Form.Control
              key={key}
              className="w-100"
              isValid={filterIsValid ?? false}
              isInvalid={filterIsInvalid ?? false}
              disabled={disabled}
              type={'text'}
              placeholder="Asset Name contains..."
              defaultValue={filter || ''}
              onChange={(e) =>
                e.target.value === '' ? onChange(null) : onChange(`name(${e.target.value})`)
              }
            />
          </div>
        )}
        {filterType === 'Place' && (
          <div title="Type out words, phrases, letters, numbers, or special characters to filter Place Names containing those items.">
            <Form.Control
              key={key}
              className="w-100"
              isValid={filterIsValid ?? false}
              isInvalid={filterIsInvalid ?? false}
              disabled={disabled}
              type={'text'}
              placeholder="Place Name contains..."
              defaultValue={filter || ''}
              onChange={(e) =>
                e.target.value === '' ? onChange(null) : onChange(`place(${e.target.value})`)
              }
            />
          </div>
        )}
        {filterType === 'Single Asset' && !isLoading && (
          <>
            <SingleAssetInput
              key={key}
              valid={filterIsValid ?? false}
              invalid={filterIsInvalid ?? false}
              disabled={disabled}
              initFilter={filter ?? ''}
              onFilterChange={onChange}
            />
          </>
        )}
        {filterType === 'Device ID' && !isLoading && (
          <div title="Type out words, phrases, letters, numbers, or special characters to filter Device ID's containing those items.">
            <Form.Control
              key={key}
              className="w-100"
              isValid={filterIsValid ?? false}
              isInvalid={filterIsInvalid ?? false}
              disabled={disabled}
              type={'text'}
              placeholder="Device ID contains..."
              defaultValue={filter || ''}
              onChange={(e) =>
                e.target.value === '' ? onChange(null) : onChange(`deviceId(${e.target.value})`)
              }
            />
          </div>
        )}
        {filterType === 'Battery Value' && !isLoading && (
          <div title="Select greater or less than and type out numbers between 0-100 to filter Battery Values.">
            <p>Battery Value is:</p>

            <Col sm="12" className="mb-3">
              <InputGroup>
                <Form.Select
                  defaultValue={0}
                  name="batteryValueGreaterLess"
                  as="select"
                  onChange={(e) => {
                    setBatteryComparison(parseInt(e.target.value));
                  }}
                >
                  <option id={'0'} key={'greater'} value={0}>
                    Greater than
                  </option>
                  <option id={'1'} key={'less'} value={1}>
                    Less than
                  </option>
                </Form.Select>
                <Form.Control
                  key={key}
                  id="battery"
                  type="number"
                  name="batteryValueNumber"
                  placeholder="0-100"
                  min={0}
                  max={100}
                  value={batteryValue ? batteryValue : ''}
                  onChange={(e) => {
                    let value = e.target.value;

                    // Remove leading zeros if the number is not just '0'
                    if (value !== '0') {
                      value = value.replace(/^0+/, '');
                    }

                    // Ensure the entered value doesn't include a '-' and doesn't start with it
                    if (!value.includes('-') && !value.startsWith('-')) {
                      // Ensure the entered value is within the specified range (0-100)
                      if (
                        !isNaN(Number(value)) &&
                        value !== '' &&
                        parseInt(value) >= 0 &&
                        parseInt(value) <= 100
                      ) {
                        setBatteryValue(value);
                        onChange(`batteryValue(${batteryComparison}, ${value})`);
                      } else {
                        // Handle cases where the input is not a valid number or outside the range
                        setBatteryValue('');
                        onChange(null);
                      }
                    } else {
                      // Handle cases where the input contains or starts with '-'
                      setBatteryValue('');
                      onChange(null);
                    }
                  }}
                  onKeyDown={(e) => {
                    // Prevent the hyphen character from being typed
                    if (e.key === '-') {
                      e.preventDefault();
                    }
                  }}
                />
              </InputGroup>
            </Col>
          </div>
        )}
      </StyledWrapper>
    </>
  );
}
