import './ScanIn.scss';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { noop } from '../../utils';

const schema = Yup.object().shape({
  scanName: Yup.string().min(1).required(),
});

function ScanIn({ onAddScanIn = noop }) {
  //useRef Hooks
  const scanNameRef = useRef(null);

  //useFormik Hook
  const formik = useFormik({
    initialValues: {
      scanName: '',
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      try {
        onAddScanIn({ value: values.scanName, notes: '' });
        resetForm({ values: '' });
        scanNameRef.current.focus();
      } catch (e) {
        console.log('ScanIn Errors - ', e);
      }
    },
  });

  return (
    <Form
      onSubmit={formik.handleSubmit}
      className="d-flex flex-column justify-content-around scan-in"
    >
      <h1 className="scaninTitle justify-content-center pb-5">New Scan</h1>

      <Form.Control
        ref={scanNameRef}
        type="text"
        name="scanName"
        placeholder="Scan or Type Barcode"
        value={formik.values.scanName}
        autoComplete="off"
        isInvalid={formik.errors.scanName && formik.touched.scanName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <Form.Control.Feedback type="invalid" className="text-align-left">
        Please scan or type a valid barcode.
      </Form.Control.Feedback>

      <div className="d-flex flex-row justify-content-center">
        <Button
          type="submit"
          variant="primary"
          className="next-btn"
          disabled={!isEmpty(formik.errors) || !formik.dirty}
        >
          Submit
        </Button>
      </div>
    </Form>
  );
}

ScanIn.propTypes = {
  onAddScanIn: PropTypes.func.isRequired,
};

export default ScanIn;
