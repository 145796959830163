import './PlaceListContainer.scss';
import { IconValueCellRenderer, LinkCellRenderer } from '@components';
import { Api } from '@services';
import Axios from 'axios';
import { useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { PagedQueryParams, ServerSideGrid } from '../../components/grids/AgGrid/ServerSideGrid';
import { PagedModel } from '../../models/PagedModel';
import { PlaceListModel } from '../../models/PlaceListModel';
import { placeListColumnDefs } from './columnDefs';

export function PlaceListContainer<T>() {
  const [rowData, setRowData] = useState<any>(new PagedModel<T>());
  const [csvData, setCsvData] = useState<any>(new PagedModel<T>());
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const id = 'Places';
  const cancelToken = Api.CancelToken;
  let fetchDataCancel;

  const fetchData = async (payload, csvExport, queryState) => {
    if (fetchDataCancel !== null && typeof fetchDataCancel === 'function') {
      fetchDataCancel();
      fetchDataCancel = null;
    }
    setIsLoading(true);
    Api.get(
      `places?top=${queryState.pageSize}&skip=${queryState.skip()}&filter=${
        queryState.filter
      }&orderBy=${queryState.orderBy()}`,
      {
        cancelToken: new cancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      },
    )
      .then((data) => {
        let resp = data.data;

        setRowData(resp);

        setIsLoading(false);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          setIsLoading(false);
        }
      });
  };

  return (
    <Card className="h-100 place-list-container">
      <Card.Body>
        <Row className="h-100">
          <Col className="h-100">
            <ServerSideGrid<PlaceListModel>
              id={id}
              columnDefs={placeListColumnDefs}
              initQueryParams={new PagedQueryParams(id)}
              handleFetchData={fetchData}
              rowData={rowData}
              csvData={csvData}
              isLoading={isLoading}
              disabledFilters={[]}
              filterControl={false}
              searchControl={true}
              viewingControl={true}
              pagerControl={true}
              gridProps={{
                frameworkComponents: {
                  LinkCellRenderer,
                  IconValueCellRenderer,
                },
              }}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
