import '../../../../scss/variables.scss';
import './MapTypeCellRenderer.scss';
import Icon from '../../../Icon/Icon';

function MapTypeCellRenderer({ data, iconType, showIcon }) {
  if (!showIcon) return null;

  return (
    <div>
      <Icon
        name={iconType}
        className="primary-icon"
        containerClassName="map-list-icon"
        variant="light"
        disabled={false}
        size="lg"
      />
    </div>
  );
}

export default MapTypeCellRenderer;
