import 'leaflet';
import './modules/location/services/leaflet-icons';
import './index.scss';
import './scss/comps/Button.scss';
import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ThemeProvider } from './context/ThemeContext';
import { DataRefreshProvider } from './context/DataRefreshContext';
import { unregister } from './registerServiceWorker';

if (window._sonar_env.NODE_ENV !== 'development' && !window._sonar_env.REACT_APP_DISABLE_SENTRY) {
  Sentry.init({
    dsn: 'https://c168b1674a16482b948344ac7910b67e@o402895.ingest.sentry.io/5264761',
    environment: window._sonar_env.REACT_APP_ENV || 'n/a',
  });
}

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ThemeProvider>
    <DataRefreshProvider>
      <App />
    </DataRefreshProvider>
  </ThemeProvider>,
);
unregister();
