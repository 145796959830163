import '../../scss/variables.scss';
import './DeviceChart.scss';
import Plot from 'react-plotly.js';

const DeviceChart = ({ type, online, offline, inInventory }) => {
  const chartData = [
    {
      values: [online, offline, inInventory],
      labels: ['Online', 'Offline', 'In Inventory'],
      type: 'pie',
      textinfo: 'value',
      marker: {
        colors: [
          getComputedStyle(document.documentElement).getPropertyValue(
            type === 'Gateways' ? '--alt3' : '--alt2',
          ),
          getComputedStyle(document.documentElement).getPropertyValue('--secondary'),
          getComputedStyle(document.documentElement).getPropertyValue('--alt4'),
        ],
      },
    },
  ];
  let layout = {
    title: {
      text: 'Device Stats',

      font: {
        family: 'Arial',
        size: 22,
        color: getComputedStyle(document.documentElement).getPropertyValue('--dark'),
      },
      xref: 'paper',
      x: 0.01,
    },
    showlegend: true,
    legend: {
      font: {
        color: getComputedStyle(document.documentElement).getPropertyValue('--dark'),
      },
    },

    paper_bgcolor: getComputedStyle(document.documentElement).getPropertyValue('--white'),
    plot_bgcolor: getComputedStyle(document.documentElement).getPropertyValue('--white'),
  };
  let config = {
    displaylogo: false,
  };

  return (
    <Plot
      data={chartData}
      layout={layout}
      config={config}
      useResizeHandler={true}
      className="w-100 h-100 device-stat-graph"
    />
  );
};

export default DeviceChart;
