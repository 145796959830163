import '../../../../scss/variables.scss';
import '../../MapMenu.scss';
import classNames from 'classnames';
import React, { forwardRef, useState } from 'react'; // Import forwardRef from React
import { Button, Card } from 'react-bootstrap';
import { useApp } from '../../../../react';
import { MapInfoItem } from './MapInfoItem';
import { MapInfoList } from './MapInfoList';
import { MapInfoOverlay } from './MapInfoOverlay';
import { MapInfoTabs } from './MapInfoTabs';
import { MapInfoTitle } from './MapInfoTitle';

// Wrap the component with forwardRef
export const MapInfoMenu = forwardRef(function MapInfoMenu(
  {
    mapId,
    selected,
    setSelected,
    mapData,
    placeList,
    assetList,
    gatewayList,
    stationaryList,
    reset,
    resetAsset,
    resetPlace,
    resetGateway,
    resetStationary,
    showMore,
    setShowMore,
    fetchAssetData,
    fetchPlaceData,
    fetchGatewayData,
    fetchStationaryData,
  },
  ref, // Accept the forwarded ref
) {
  const app = useApp();
  const [showInfo, setShowInfo] = useState<boolean>(false);

  let infoClasses = classNames({
    'info-container': !showInfo,
    'info-container-hide': showInfo,
  });

  let collapseClasses = classNames({
    'collapse-button': showInfo,
    'collapse-button-hide': !showInfo,
  });

  return (
    <>
      <div className={collapseClasses}>
        <Card style={{ backgroundColor: 'var(--primary)' }}>
          <Button
            variant="dark"
            type="submit"
            className="collapse-card"
            style={{
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
              width: '50px',
            }}
            onClick={() => {
              setShowInfo(!showInfo);
            }}
          >
            <i
              className={'sonarIcon sonarIcon-right-arrow align-sub fs-lg'}
              style={{
                transform: showInfo ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.5s ease-in-out',
                color: 'var(--white)',
              }}
            />
          </Button>
        </Card>
      </div>

      <div className={infoClasses} ref={ref}>
        <Card className="d-flex flex-row" style={{ backgroundColor: 'var(--primary)' }}>
          <div className="collapse-container">
            <Button
              variant="dark"
              type="submit"
              className="collapse-card"
              style={{
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px',
                width: '50px',
              }}
              onClick={() => {
                setShowInfo(!showInfo);
              }}
            >
              <i
                className={'sonarIcon sonarIcon-right-arrow align-sub fs-lg'}
                style={{
                  transform: showInfo ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.5s ease-in-out',
                  color: 'var(--white)',
                }}
              />
            </Button>
          </div>
          <div className="menu-bar">
            <MapInfoTitle
              app={app}
              mapId={mapId}
              mapType={mapData.features[0].properties.mapType}
              mapTitle={mapData.features[0].properties.Name}
              reset={reset}
            />
            <MapInfoTabs selected={selected} setSelected={setSelected} />
          </div>
        </Card>
        <Card
          className={selected === '' ? 'object-list' : 'object-list-expand'}
          style={{
            backgroundColor: 'var(--light)',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
          }}
        >
          {selected === 'overlay' && (
            <MapInfoOverlay mapData={mapData} selected={selected} setSelected={setSelected} />
          )}

          <MapInfoList
            key="asset"
            type="asset"
            listData={assetList}
            visible={selected === 'asset' && showMore === null}
            disabledFilters={['name', 'map', 'single asset', 'device id', 'place', 'battery value']}
            selected={selected}
            setViewItem={setShowMore}
            setSelected={setSelected}
            fetchData={fetchAssetData}
            reset={resetAsset}
          />
          <MapInfoList
            key="place"
            type="place"
            listData={placeList}
            visible={selected === 'place' && showMore === null}
            disabledFilters={[
              'name',
              'map',
              'single asset',
              'device id',
              'place',
              'battery value',
              'identifier',
              'active assets',
              'inactive assets',
            ]}
            selected={selected}
            setViewItem={setShowMore}
            setSelected={setSelected}
            fetchData={fetchPlaceData}
            reset={resetPlace}
          />
          <MapInfoList
            key="gateway"
            type="gateway"
            listData={gatewayList}
            visible={selected === 'gateway' && showMore === null}
            disabledFilters={[
              'name',
              'map',
              'single asset',
              'place',
              'keyword',
              'identifier',
              'battery value',
              'active assets',
              'inactive assets',
            ]}
            selected={selected}
            setViewItem={setShowMore}
            setSelected={setSelected}
            fetchData={fetchGatewayData}
            reset={resetGateway}
          />
          <MapInfoList
            key="stationary"
            type="stationary"
            listData={stationaryList}
            visible={selected === 'stationary' && showMore === null}
            disabledFilters={['name', 'map', 'single asset', 'device id', 'place', 'battery value']}
            selected={selected}
            setViewItem={setShowMore}
            setSelected={setSelected}
            fetchData={fetchStationaryData}
            reset={resetStationary}
          />

          {showMore !== null && (
            <MapInfoItem
              mapName={mapData.features[0].properties.Name}
              feature={showMore}
              selected={selected}
              setShowMore={setShowMore}
            />
          )}
        </Card>
      </div>
    </>
  );
});
