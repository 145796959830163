import './MapList.scss';
import { Api } from '@services';
import { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import {
  EditCellRenderer,
  Icon,
  LinkCellRenderer,
  MapThumbnailCellRenderer,
} from '../../../components';
import { PagedQueryParams, ServerSideGrid } from '../../../components/grids/AgGrid/ServerSideGrid';
import { useDataRefresh } from '../../../context/DataRefreshContext';
import { PagedModel } from '../../../models/PagedModel';
import { mapListColumnDefs } from './columnDefs';

export function MapList() {
  const [rowData, setRowData] = useState<any>(new PagedModel());
  const [csvData, setCsvData] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const csvFileName = 'Maps_Export';
  const history = useHistory();
  const { refreshCount } = useDataRefresh(); // keep this for allowing map list to remove stars when unfavoriting from slide out

  const FetchData = async (payload: any, isCSV: any, queryState: any) => {
    setIsLoading(true);

    Api.get(
      `maps?top=${queryState.pageSize}&skip=${queryState.skip()}&filter=${queryState.filter}`,
    ).then((results: any) => {
      setRowData(results.data);
      setIsLoading(false);
    });

    // Help create call to export Assets
    if (isCSV) {
      const config = {
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
      };

      Api.get(`maps/export?search=${queryState.filter}`, config)
        .then((res) => {
          setCsvData(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  return (
    <Card className="map-list-card h-100">
      <Card.Body>
        <ServerSideGrid
          id={'MapListGrid'}
          columnDefs={mapListColumnDefs}
          handleFetchData={FetchData}
          rowData={rowData}
          csvData={csvData}
          csvFileName={csvFileName}
          isLoading={isLoading}
          disabledFilters={[]}
          filterControl={false}
          searchControl={true}
          viewingControl={true}
          pagerControl={true}
          exportControl={true}
          gridProps={{
            frameworkComponents: {
              EditCellRenderer,
              LinkCellRenderer,
              MapThumbnailCellRenderer,
            },
            rowHeight: 80,
            suppressClickEdit: true,
            editType: 'fullRow',
            enableBrowserTooltips: false,
            tooltipShowDelay: 0,
            tooltipMouseTrack: true,
          }}
          initQueryParams={new PagedQueryParams()}
        />
      </Card.Body>
    </Card>
  );
}
