import { createTheme } from '@mui/material';

export const DateTheme = createTheme({
  palette: {
    primary: {
      main: '#0048FF',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--c-primary)',
          },
        },
        notchedOutline: {
          borderColor: 'var(--primary)',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&.MuiOutlinedInput-input': {
            color: 'var(--dark)',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiPickersDay-root.Mui-selected': {
            backgroundColor: '#0048FF',
          },
          '&.MuiPickersDay-root:focus.Mui-selected': {
            backgroundColor: '#0048FF',
          },
          '&.MuiPickersDay-root.Mui-selected:hover': {
            backgroundColor: '#0048FF',
          },
          '&.MuiIconButton-root': {
            color: 'var(--primary)',
          },
        },
      },
    },
  },
});
