import './PaginatedSearch.scss';
import '../../scss/animations/animations.scss';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Form, InputGroup,ListGroup, Spinner } from 'react-bootstrap';
import CheckboxComponent from '../../components/checkboxes/CheckboxComponent';
import { noop } from '../../utils';
import Text from '../Text/Text';

function PaginatedSearch({
  items = [],
  search = '',
  setSearch = noop,
  page = 1,
  pageCount = 1,
  setPage = noop,
  placeholder = '',
  disabled = false,
  loading = false,
  renderItem = noop,
  renderLastItem = null,
  onSelectItem = noop,
  renderSelectedItem = null,
  renderNotSelectedItem = null,
  onClearSelectedItem = noop,
  initSelectedItem = null,
  refetch = null,
  itemFilter = () => true,
  helpText = '',
  renderExtra = () => null,
}) {
  const [selectedItem, setSelectedItem] = useState(initSelectedItem);
  const [showItems, setShowItems] = useState(false);

  const hideItems = () => setShowItems(false);
  const openItems = () => setShowItems(true);

  const clearSelectedItem = () => {
    setSelectedItem(null);
    onClearSelectedItem();
    setSearch('');
  };

  const renderOpts = {
    hideItems,
    openItems,
  };

  const renderCloseIcon = () => {
    return (
      <div className="d-flex flex-row justify-content-between align-items-center filter-close-wrapper">
        <div>{renderExtra()}</div>

        <Button variant="link" className="text-danger d-flex" onClick={() => setShowItems(false)}>
          Close Search
        </Button>
      </div>
    );
  };

  const renderRefetch = () => {
    if (!refetch) return null;

    return (
      <ListGroup.Item>
        <div className="d-flex flex-row justify-content-end">
          <Button variant="link" onClick={refetch}>
            Refetch
          </Button>
        </div>
      </ListGroup.Item>
    );
  };

  const _renderLastItem = () => {
    if (!renderLastItem) return null;

    return <ListGroup.Item>{renderLastItem()}</ListGroup.Item>;
  };

  const renderItems = () => {
    if (loading) {
      return (
        <ListGroup>
          <ListGroup.Item>
            <Spinner animation="border" variant="primary" size="sm" />
          </ListGroup.Item>
        </ListGroup>
      );
    }

    if (!items.length) {
      return (
        <ListGroup>
          <ListGroup.Item>No items.</ListGroup.Item>
          {renderRefetch()}
          {_renderLastItem()}
        </ListGroup>
      );
    }

    const renderPagination = () => {
      if (pageCount === 1) return null;

      const renderPrevPage = () => {
        if (page === 1) return <div />;

        return (
          <Button
            variant="link"
            onClick={() => {
              setPage(page - 1);
            }}
          >
            Previous Page
          </Button>
        );
      };

      const renderNextPage = () => {
        if (page === pageCount) return <div />;

        return (
          <Button
            variant="link"
            onClick={() => {
              setPage(page + 1);
            }}
          >
            Next Page
          </Button>
        );
      };

      return (
        <ListGroup.Item className="d-flex flex-row justify-content-between align-items-center">
          {renderPrevPage()}
          <span>{`(${page}/${pageCount})`}</span>
          {renderNextPage()}
        </ListGroup.Item>
      );
    };

    const filteredItems = items.filter((item, index) => itemFilter({ item, index, search }));

    return (
      <ListGroup>
        {/* Items */}
        {filteredItems.map((item, index) => {
          return (
            <ListGroup.Item
              key={index}
              className="pointer text-break"
              onClick={() => {
                setSelectedItem(item);
                onSelectItem({ item, index, ...renderOpts });
              }}
            >
              {renderItem({ item })}
            </ListGroup.Item>
          );
        })}

        {renderRefetch()}
        {_renderLastItem()}

        {/* Paginate */}
        {renderPagination()}
      </ListGroup>
    );
  };

  const _renderSelectedItem = () => {
    if (!selectedItem || !renderSelectedItem) return null;

    return (
      <div className="beacon-assign-checkmark-wrapper">
        <CheckboxComponent checked={true} />
      </div>
    );
  };

  const _renderSelectedItemText = () => {
    if (!selectedItem || !renderSelectedItem) return null;

    return (
      <div className="d-flex flex-row align-items-center ms-5">
        {renderSelectedItem({ item: selectedItem })}
        <Button
          variant="link"
          size="sm"
          className="selectedItem-ClosedSearchTxt"
          onClick={clearSelectedItem}
        >
          <Text className="fs-sm selectedItem-ClosedSearchTxt">Clear Selection</Text>
        </Button>
      </div>
    );
  };

  const _renderNotSelectedItem = () => {
    if (selectedItem || !renderNotSelectedItem) return null;

    return <div className="d-flex flex-row align-items-center">{renderNotSelectedItem()}</div>;
  };

  return (
    <div className="position-relative">
      <div className="d-flex">
        {_renderSelectedItem()}
        <InputGroup>
          <Form.Control
            type="text"
            placeholder={placeholder}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onFocus={() => setShowItems(true)}
            disabled={disabled}
          />
        </InputGroup>
      </div>

      {helpText && <Form.Text muted>{helpText}</Form.Text>}
      {_renderSelectedItemText()}
      {_renderNotSelectedItem()}
      {showItems && renderCloseIcon()}
      {showItems && <div className="paginatedSearch-items">{renderItems()}</div>}
    </div>
  );
}

PaginatedSearch.propTypes = {
  items: PropTypes.array,
  onSelectItem: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,

  // defaults
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  pageCount: PropTypes.number,
  page: PropTypes.number,
  setPage: PropTypes.func,
  renderLastItem: PropTypes.func,
  loading: PropTypes.bool,
  renderSelectedItem: PropTypes.func,
  renderNotSelectedItem: PropTypes.func,
  onClearSelectedItem: PropTypes.func,
  refetch: PropTypes.func,
  initSelectedItem: PropTypes.object,
  itemFilter: PropTypes.func,
  helpText: PropTypes.string,
  renderExtra: PropTypes.func,
};

export default PaginatedSearch;
