import './StartEndDatePicker.scss';
import MomentUtils from '@date-io/moment';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { useEffect } from 'react';
import styled from 'styled-components';

// Single Version Start-End Date Picker for Analytics Overview Page

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#0048FF',
    },
  },
  components: {
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: 'var(--danger)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root': {
            color: 'var(--dark)',
            top: '-7px',
          },
          '&.MuiInputLabel-shrink': {
            top: '0px',
          },
          '&.MuiInputLabel-shrink.Mui-focused': {
            color: 'var(--primary)',
          },
          '&.MuiInputLabel-shrink.Mui-error': {
            color: 'var(--danger)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--c-primary)',
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--danger)',
          },
        },
        notchedOutline: {
          borderColor: '#dee2e6',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&.MuiOutlinedInput-input': {
            color: 'var(--dark)',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiPickersDay-root.Mui-selected': {
            backgroundColor: '#0048FF',
          },
          '&.MuiPickersDay-root:focus.Mui-selected': {
            backgroundColor: '#0048FF',
          },
          '&.MuiPickersDay-root.Mui-selected:hover': {
            backgroundColor: '#0048FF',
          },
          '&.MuiIconButton-root': {
            color: 'var(--primary)',
          },
        },
      },
    },
  },
});

const SingleDateTimeWrapper = styled.div`
  width: 100%;
  justify-items: center;
  margin-top: 3px;
  display: flex;
`;

const SingleStartEndDatePicker = ({ input, formik }) => {
  const clearDate = null;
  let startDate = formik.values.startDate;
  let endDate = formik.values.endDate;

  const handleStartDateChange = (e) => {
    if (endDate === undefined) {
      const newEndDate = moment(e).add(24, 'hours').toDate();
      formik.setValues({
        ...formik.values,
        startDate: e,
        endDate: newEndDate,
      });
    } else {
      formik.setValues({
        ...formik.values,
        startDate: e,
        endDate: endDate,
      });
    }
  };

  const handleEndDateChange = (e) => {
    if (startDate === undefined) {
      const newStartDate = moment(e).subtract(24, 'hours').toDate();
      formik.setValues({
        ...formik.values,
        startDate: newStartDate,
        endDate: e,
      });
    } else {
      formik.setValues({
        ...formik.values,
        startDate: startDate,
        endDate: e,
      });
    }
  };

  // Check date range user selected to set error message
  useEffect(() => {
    if (moment(startDate).isAfter(endDate)) {
      formik.setFieldError('startDate', 'Start date must be before end date.');
    }
    if (moment(startDate).isAfter(moment())) {
      formik.setFieldError('startDate', 'Start Date cannot be in the future.');
    }
    if (moment(endDate).isAfter(moment())) {
      formik.setFieldError('endDate', 'End Date cannot be in the future.');
    }
  }, [startDate, endDate, formik]);

  return (
    <div>
      <SingleDateTimeWrapper>
        <ThemeProvider theme={defaultMaterialTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} utils={MomentUtils}>
            <>
              {input.label === 'Start Date' && (
                <DesktopDateTimePicker
                  label={input.label}
                  maxDate={endDate ? new Date(endDate) : null}
                  value={startDate || clearDate}
                  onChange={handleStartDateChange}
                  disableFuture
                  slotProps={{
                    textField: {
                      error: Boolean(formik.errors.startDate),
                      helperText: formik.errors.startDate,
                    },
                  }}
                />
              )}
              {input.label === 'End Date' && (
                <DesktopDateTimePicker
                  label={input.label}
                  minDate={startDate ? new Date(startDate) : null}
                  value={endDate || clearDate}
                  onChange={handleEndDateChange}
                  disableFuture
                  slotProps={{
                    textField: {
                      error: Boolean(formik.errors.endDate),
                      helperText: formik.errors.endDate,
                    },
                  }}
                />
              )}
            </>
          </LocalizationProvider>
        </ThemeProvider>
      </SingleDateTimeWrapper>
    </div>
  );
};

export default SingleStartEndDatePicker;
