import React, { createContext, useContext, useState } from 'react';

const DataRefreshContext = createContext<{ refreshCount: number; refresh: () => void } | undefined>(
  undefined,
);

export const DataRefreshProvider = ({ children }) => {
  const [refreshCount, setRefreshCount] = useState(0);

  const refresh = () => {
    setRefreshCount((count) => count + 1);
  };

  return (
    <DataRefreshContext.Provider value={{ refreshCount, refresh }}>
      {children}
    </DataRefreshContext.Provider>
  );
};

export const useDataRefresh = () => {
  const context = useContext(DataRefreshContext);
  if (context === undefined) {
    throw new Error('useDataRefresh must be used within a DataRefreshProvider');
  }
  return context;
};
