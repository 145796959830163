import '../../../scss/variables.scss';
import { useEffect,useState } from 'react';
import Plot from 'react-plotly.js';

function LineChart({
  header,
  data,
  labels,
  className = '',
  useYAxisBuffer = false,
  ability,
  onRangeUpdate,
}) {
  const [selectedInteractiveRange, setSelectedInteractiveRange] = useState(null);

  const noData = !data.length;
  const lowest = Math.min(...data);
  const highest = Math.max(...data);
  const range = highest - lowest;
  const minYTick = lowest - range * 0.25;
  const maxYTick = highest + range * 0.25;

  const handleRelayout = (eventData) => {
    if (eventData && eventData['xaxis.range[0]'] && eventData['xaxis.range[1]']) {
      const rangeStart = eventData['xaxis.range[0]'];
      const rangeEnd = eventData['xaxis.range[1]'];
      setSelectedInteractiveRange([rangeStart, rangeEnd]);
    }
  };

  useEffect(() => {
    // Update the range in the parent component using the callback
    if (selectedInteractiveRange) {
      onRangeUpdate(selectedInteractiveRange);
    }
  }, [selectedInteractiveRange]);

  let trace1 = {
    x: [...labels],
    y: [...data],
    type: 'scatter',
    mode: 'lines+markers',
    marker: {
      color: getComputedStyle(document.documentElement).getPropertyValue('--c-primary'),
    },
    line: {
      color: getComputedStyle(document.documentElement).getPropertyValue('--c-primary'),
    },
    fill: 'tonexty',
  };

  data = [trace1];

  let layout = {
    title: {
      text: header,
      font: {
        family: 'Arial',
        size: 22,
      },
    },
    xaxis: {
      type: 'date',
      tickformat: '%I:%M %p \n %B %d, %Y',
    },
    height: 500,
    plot_bgcolor: getComputedStyle(document.documentElement).getPropertyValue('--light'),
    paper_bgcolor: getComputedStyle(document.documentElement).getPropertyValue('--light'),
    font_color: getComputedStyle(document.documentElement).getPropertyValue('--dark'),
  };

  if (selectedInteractiveRange) {
    layout.xaxis.range = selectedInteractiveRange;
  }

  if (ability === 'Battery') {
    layout['yaxis'] = {
      range: [0, 105],
    };
  } else {
    layout['yaxis'] = {
      range: [minYTick, maxYTick],
    };
  }

  let config = {
    responsive: true,
    displaylogo: false,
    modeBarButtonsToRemove: ['select2d', 'lasso2d'],
  };

  if (noData) {
    layout['annotations'] = [
      {
        x: 0.5,
        y: 0.5,
        xref: 'paper',
        yref: 'paper',
        text: 'No Data',
        showarrow: false,
        font: {
          size: 44,
          family: 'Arial',
        },
        opacity: 0.7,
      },
    ];
    layout.xaxis = {
      showticklabels: false,
      showgrid: false,
      zeroline: false,
      linecolor: getComputedStyle(document.documentElement).getPropertyValue('--dark'),
      linewidth: 1,
      mirror: true,
    };
    layout.yaxis = {
      showticklabels: false,
      showgrid: false,
      zeroline: false,
      linecolor: getComputedStyle(document.documentElement).getPropertyValue('--dark'),
      linewidth: 1,
      mirror: true,
    };
  }

  return <Plot data={data} layout={layout} config={config} onRelayout={handleRelayout} />;
}

export default LineChart;
