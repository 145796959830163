import './CheckboxComponent.scss';

const CheckboxComponent = ({ checked, onChange }) => {
  return (
    <div className="checkbox-wrapper-1">
      <input
        className="inp-cbx"
        id="cbx-15"
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <label className="cbx" htmlFor="cbx-15">
        <span>
          <svg width="12px" height="9px" viewBox="0 0 12 9">
            <polyline points="1 5 4 8 11 1"></polyline>
          </svg>
        </span>
      </label>
    </div>
  );
};

export default CheckboxComponent;
