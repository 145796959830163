import './ChangePassword.scss';
import '../../scss/animations/animations.scss';
import axios from 'axios';
import { useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Errors, LoadingButton, toast } from '../../components';
import Toast from '../../components/utils/toastComponent';
import { appToggleApplication, store } from '../../redux';
import { Api, appService, authService } from '../../services';
import { usePasswordValidation } from './../../hooks/usePasswordValidation';

// Specifically for New Users Changing their PW's for the first time after login
async function updatePassword(data) {
  if (data.newPassword === data.oldPassword) {
    toast.error('New password must be different from the current password!');
    return false;
  }
  return axios
    .post(`${window._sonar_env.REACT_APP_BASE_API_URL_V2}/authentication/password-reset`, {
      email: data.email,
      newPassword: data.newPassword,
      passwordResetToken: data.passwordToken,
    })
    .then((response) => {
      return response.data; // Return the data received from the API call
    })
    .catch((error) => {
      console.error('Error updating password:', error);
      throw error; // Re-throw the error to handle it outside
    });
}

function NewUserChangePassword() {
  const { mutateAsync, reset } = useMutation(authService.login);
  const location = useLocation();
  const userData = location.state?.userData;

  const [newPassword, setNewPassword] = useState({
    firstPassword: '',
    secondPassword: '',
  });

  const [validLength, hasNumber, upperCase, lowerCase, match, specialChar] = usePasswordValidation({
    firstPassword: newPassword.firstPassword,
    secondPassword: newPassword.secondPassword,
  });
  const [oldPassword, setOldPassword] = useState('');
  const [errors, setErrors] = useState([]);

  const setFirst = (event) => {
    setNewPassword({ ...newPassword, firstPassword: event.target.value });
  };
  const setSecond = (event) => {
    setNewPassword({ ...newPassword, secondPassword: event.target.value });
  };

  const history = useHistory();

  const onError = (err) => {
    setErrors([
      ...errors,
      err ? err.response.data.message : 'There was an issue resetting your password.',
    ]);
    toast.error('Failed to update password.');
  };

  const disabled = () => {
    return validLength === true &&
      hasNumber === true &&
      upperCase === true &&
      lowerCase === true &&
      match === true &&
      specialChar === true
      ? false
      : true;
  };

  const callUpdatePassword = async () => {
    // Check if old password is different from the new password before making the API call
    if (oldPassword !== newPassword.firstPassword) {
      try {
        const res = await updatePassword({
          email: userData.email,
          passwordToken: userData.newUserPWToken,
          newPassword: newPassword.firstPassword,
        });

        if (res) {
          toast.success('Password updated successfully!');

          // login again then get users/apps and direct to dashboard
          await mutateAsync({
            username: res.user.email,
            password: newPassword.firstPassword,
          });

          const apps = await appService.getApps(2000);
          const { data } = await Api.get(`/users`);
          if (apps.length >= 1) {
            const defaultAppId = data.defaultApplication;
            let defaultApp = apps.find((app) => app.id === defaultAppId);
            if (defaultApp) {
              defaultApp = { id: defaultApp.id, name: defaultApp.name };
              store.dispatch(appToggleApplication(defaultApp));
            } else {
              defaultApp = apps[0];
              store.dispatch(appToggleApplication(defaultApp));
            }
          } else {
            toast.info(
              'There are no applications assigned to you. Contact an Admin to get Applications assigned.',
            );
          }

          if (sessionStorage.getItem('redirectAfterLogin')) {
            const redirectURL = sessionStorage.getItem('redirectAfterLogin');
            sessionStorage.removeItem('redirectAfterLogin');
            history.push(redirectURL);
          } else {
            history.push('/dashboard');
          }
        } else {
          toast.error('Failed to update password.');
        }
      } catch (error) {
        onError(error);
      } finally {
        reset();
      }
    } else {
      toast.error('Current password and new password cannot be the same.');
    }
  };

  return (
    <div className="new-user-change-pw">
      <div className="background-img">
        <Container className="fade-in">
          <div className="above-txt-section">
            <h5>Welcome to</h5>
            <img className="logo" src={'/img/thinaer-sonar-white.png'} alt="Thinaer Sonar" />
            <p>For security purposes, please change your password.</p>
          </div>

          <Row>
            <Col>
              <Errors errors={errors} setErrors={setErrors} />
            </Col>
          </Row>

          <Card>
            <Form className="change-password-form">
              <Form.Group>
                <Row>
                  <Col>
                    <Form.Control
                      className="mb-4"
                      placeholder="Current Password"
                      type="password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />

                    <div className="d-flex position-relative">
                      <Form.Control
                        className="mb-4"
                        placeholder="New Password"
                        type="password"
                        onChange={setFirst}
                      />
                      {validLength && hasNumber && upperCase && lowerCase && specialChar && (
                        <span className="d-flex flex-row mt-2 ">
                          <div className="circle-anim">
                            <div className="checkmark draw"></div>
                          </div>
                        </span>
                      )}
                    </div>

                    <div className="d-flex position-relative">
                      <Form.Control
                        className="mb-2"
                        placeholder="Confirm New Password"
                        type="password"
                        onChange={setSecond}
                      />
                      {newPassword.firstPassword !== '' &&
                        validLength &&
                        hasNumber &&
                        upperCase &&
                        lowerCase &&
                        specialChar &&
                        match && (
                          <span className="d-flex flex-row mt-2 ">
                            <div className="circle-anim">
                              <div className="checkmark draw"></div>
                            </div>
                          </span>
                        )}
                      {newPassword.secondPassword !== '' && !match && (
                        <span className="d-flex flex-row mt-2">
                          <div className="circle-anim danger">
                            <div className="x-mark draw"></div>
                          </div>
                        </span>
                      )}
                    </div>

                    <small
                      className={`pw-error ${
                        newPassword.secondPassword !== '' && !match ? '' : 'errorHidden'
                      }`}
                    >
                      Passwords do not match.
                    </small>

                    <LoadingButton
                      text="Change My Password"
                      isLoading={false}
                      className="my-3"
                      id="update-pw"
                      onClick={callUpdatePassword}
                      disabled={disabled()}
                    />

                    <p className="mt-4">Passwords must contain:</p>

                    <div className="d-flex position-relative">
                      {validLength && <div className="primary-checkmark draw me-4"></div>}
                      <Form.Text className={validLength ? 'crossedOutCompleted' : ''}>
                        At least 8 characters.
                      </Form.Text>
                    </div>

                    <div className="d-flex position-relative">
                      {hasNumber && <div className="primary-checkmark draw me-4"></div>}
                      <Form.Text className={hasNumber ? 'crossedOutCompleted' : ''}>
                        At least one number.
                      </Form.Text>
                    </div>

                    <div className="d-flex position-relative">
                      {upperCase && <div className="primary-checkmark draw me-4"></div>}
                      <Form.Text className={upperCase ? 'crossedOutCompleted' : ''}>
                        At least one upper case letter (A-Z).
                      </Form.Text>
                    </div>

                    <div className="d-flex position-relative">
                      {lowerCase && <div className="primary-checkmark draw me-4"></div>}
                      <Form.Text className={lowerCase ? 'crossedOutCompleted' : ''}>
                        At least one lower case letter (a-z).
                      </Form.Text>
                    </div>

                    <div className="d-flex position-relative">
                      {specialChar && <div className="primary-checkmark draw me-4"></div>}
                      <Form.Text className={specialChar ? 'crossedOutCompleted' : ''}>
                        At least one special character.
                      </Form.Text>
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Card>
        </Container>
      </div>
      <Toast />
    </div>
  );
}

export default NewUserChangePassword;
