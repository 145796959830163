import classnames from 'classnames';
import { Component, createContext, createRef } from 'react';
import { withDock } from '../../../react';

export const DockContext = createContext(null);

const defaultDock = {
  width: 0,
  render: () => null,
};

class Dock extends Component {
  constructor() {
    super();
    this.ref = createRef(null);
  }

  render() {
    const { width, render } = this.props?.dock || defaultDock;
    return (
      <DockContext.Provider value={this.ref}>
        <div className={`${width === 0 ? 'dock-close' : 'dock'}`} ref={this.ref}>
          {render()}
        </div>
      </DockContext.Provider>
    );
  }
}

export default withDock(Dock);
