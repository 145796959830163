import { noop } from '../../../../utils';
import Icon from '../../../Icon/Icon';

function IconCellRenderer({
  iconName,
  hideIcon,
  variant = 'primary',
  disabled = true,
  onClick = noop,
  className = '',
  size = 'lg',
}) {
  if (hideIcon) {
    return null;
  }

  return (
    <Icon
      name={iconName}
      className={className}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      size={size}
      showPointer={true}
    />
  );
}

export default IconCellRenderer;
