import '../../MapMenu.scss';
import { useEffect, useState } from 'react';
import { Card, Form, InputGroup } from 'react-bootstrap';
import { Icon, Keywords, Text } from '../../..';

export function MapEditOverlay({
  selected,
  setSelected,
  description,
  keywords,
  isStatic,
  newScaleCreated,
  setDescription,
  setKeywords,
  setImage,
  setAddScale,
  setCreateScale,
  setScaleLength,
  setRemoveTempItem,
}) {
  const [showSave, setShowSave] = useState<boolean>(false);
  const [length, setLength] = useState<string>('');

  useEffect(() => {
    setScaleLength(length);
  }, [length, setScaleLength]);

  useEffect(() => {
    newScaleCreated !== null ? setShowSave(true) : setShowSave(false);
  }, [newScaleCreated]);

  // Handle submission of item
  function startDraw() {
    setCreateScale(true);
  }

  return (
    <Form className={selected !== 'overlay' ? 'object-wrapper' : 'object-wrapper-expand'}>
      <Card.Body className="object-edit d-flex flex-wrap flex-column align-items-left justify-content-between">
        <div className="d-flex align-items-center justify-content-between">
          <Text className="title-text fw-bold fs-md">Description:</Text>
          <div>
            <Icon
              name={'add'}
              title="Close"
              className="sonarIcon-rotate-45 align-sub nav-text-icon"
              variant="light"
              showPointer={true}
              disabled={false}
              size="md"
              onClick={() => {
                setSelected('');
                setCreateScale(false);
                setRemoveTempItem(true);
              }}
            />
          </div>
        </div>
        <Form.Control
          className="mb-3"
          as="textarea"
          value={description ? description : ''}
          placeholder="Enter map description"
          onChange={(e) => setDescription(e.target.value)}
        />

        {isStatic && (
          <>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <Text className="title-text fw-bold fs-md">Scale:</Text>
              {showSave ? (
                <Icon
                  name={'check'}
                  title="Confirm"
                  className="align-sub primary-icon"
                  variant="light"
                  disabled={length === '' ? true : false}
                  size="md"
                  onClick={() => {
                    setAddScale(true);
                    setShowSave(false);
                  }}
                />
              ) : (
                <Icon
                  name={'pin-edit'}
                  title="Draw Scale"
                  className="align-sub primary-icon"
                  variant="light"
                  disabled={newScaleCreated !== null}
                  size="md"
                  onClick={() => {
                    startDraw();
                  }}
                />
              )}
            </div>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Scale length"
                type="number"
                step={1}
                min={1}
                placeholder="Length in feet"
                onChange={(e) => {
                  setLength(e.target.value);
                }}
              />
            </InputGroup>
          </>
        )}

        {/* <Text className="fw-bold fs-md">Overlay Image:</Text>
        <Form.Control className="mb-3" type="file" onChange={(file) => setImage(file)} /> */}

        <Text className="title-text fw-bold fs-md">Keywords:</Text>
        <div className="keywords-wrap">
          <Keywords
            isAddingKeyword
            initKeywords={keywords}
            onChange={(keywords) => setKeywords(keywords)}
          />
        </div>
      </Card.Body>
    </Form>
  );
}
