import './StartEndDatePicker.scss';
import MomentUtils from '@date-io/moment';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#0048FF',
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: 'var(--danger)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-root': {
            color: 'var(--dark)',
            top: '-7px',
          },
          '&.MuiInputLabel-shrink': {
            top: '0px',
          },
          '&.MuiInputLabel-shrink.Mui-focused': {
            color: 'var(--primary)',
          },
          '&.MuiInputLabel-shrink.Mui-error': {
            color: 'var(--danger)',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--c-primary)',
          },
          '&.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--danger)',
          },
        },
        notchedOutline: {
          borderColor: '#dee2e6',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&.MuiOutlinedInput-input': {
            color: 'var(--dark)',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiPickersDay-root.Mui-selected': {
            backgroundColor: '#0048FF',
          },
          '&.MuiPickersDay-root:focus.Mui-selected': {
            backgroundColor: '#0048FF',
          },
          '&.MuiPickersDay-root.Mui-selected:hover': {
            backgroundColor: '#0048FF',
          },
          '&.MuiIconButton-root': {
            color: 'var(--primary)',
          },
        },
      },
    },
  },
});

const SingleDateTimeWrapper = styled.div`
  width: 100%;
  justify-items: center;
  margin-top: 3px;
`;

// Single Version Start-End Date Picker for Advanced Asset Page & Add Run

const AdvancedAssetDatePicker = ({ label, value, onChange }) => {
  const [hasDateError, setHasDateError] = useState(false);
  const [dateErrorMsg, setDateErrorMsg] = useState('');
  const clearDate = null;

  useEffect(() => {
    // check if value date is in the future
    if (moment(value).isAfter(moment())) {
      setHasDateError(true);
      setDateErrorMsg('Start Date cannot be in the future.');
    } else {
      setHasDateError(false);
      setDateErrorMsg('');
    }
  }, [value]);

  return (
    <Form>
      <SingleDateTimeWrapper>
        <ThemeProvider theme={defaultMaterialTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} utils={MomentUtils}>
            <DesktopDateTimePicker
              label={label}
              value={value === undefined ? clearDate : value}
              onChange={onChange}
              disableFuture
              className="me-3"
              slotProps={{
                textField: {
                  error: hasDateError,
                  helperText: dateErrorMsg,
                },
              }}
            />
          </LocalizationProvider>
        </ThemeProvider>
      </SingleDateTimeWrapper>
    </Form>
  );
};

export default AdvancedAssetDatePicker;
