import Icon from '../../components/Icon/Icon';

export default function CloseModal({ close }) {
  return (
    <div className="d-flex justify-content-end">
      <Icon
        name="add"
        variant="light"
        size="lg"
        disabled={false}
        onClick={close}
        showPointer={true}
        className="sonarIcon-rotate-45 align-sub exit-icon"
      />
    </div>
  );
}
