import './Favorites.scss';
import '../../scss/variables.scss';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { Text } from '../../components';

export function FavoriteTab({
  eventKey,
  favorites,
  onClickItem,
  tabName,
  colorClass,
  onDone,
  handleRemove,
}) {
  // Sort favorites alphabetically by name
  const sortedFavorites = favorites.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Accordion.Item eventKey={eventKey} className="favorite-accordion">
      <Accordion.Header className="d-flex fs-lg fw-bold btn-light cursor-pointer align-items-center favorite-accordion-header">
        <div className={`${colorClass} icon-wrap d-flex align-items-center text-right p-2`}>
          <i className={`sonarIcon sonarIcon-${tabName.toLowerCase()} cursor-pointer`} />
        </div>
        &emsp; Favorite {tabName}s
      </Accordion.Header>
      <Accordion.Body className="favorite-accordion-body">
        <Row>
          <Col>
            <Card.Body>
              <div className="d-flex flex-column">
                {!sortedFavorites.length && (
                  <p className="fs-md">
                    Click the Star icon on a Place, Asset, or Map to save Favorites!
                  </p>
                )}

                {sortedFavorites.map((fav, index) => {
                  return (
                    <div
                      key={index}
                      className="favorite-tab d-flex flex-row justify-content-left align-items-center"
                    >
                      <i
                        className={`remove-favorite sonarIcon sonarIcon-rotate-45 sonarIcon-add fs-1x cursor-pointer`}
                        onClick={() => handleRemove(fav)}
                      />
                      &emsp;
                      <Button
                        className="d-flex flex-row justify-content-left mb-1"
                        variant="light"
                        onClick={() => {
                          onClickItem(fav);
                          onDone();
                        }}
                      >
                        <Text title={fav.name}>&nbsp; {fav.name}</Text>
                      </Button>
                    </div>
                  );
                })}
              </div>
            </Card.Body>
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
}
