import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './NewAssetOptions.scss';

interface NewAssetOptionsProps {
  onDone(): void;
  onCreateAnotherAsset(): void;
}

/**
 * @desc Renders options a user may select from after creating an Asset.
 */
function NewAssetOptions({ onDone, onCreateAnotherAsset }: NewAssetOptionsProps) {
  return (
    <div className="create-asset-success d-flex flex-column w-100 mb-3 align-items-center ">
      <Button className="mb-3 create-asset" variant="secondary" onClick={onCreateAnotherAsset}>
        Create Another Asset
      </Button>
      <Button className="mb-3 create-asset" variant="primary" onClick={onDone}>
        Done
      </Button>
    </div>
  );
}

NewAssetOptions.propTypes = {
  onDone: PropTypes.func.isRequired,
  onCreateAnotherAsset: PropTypes.func.isRequired,
};

export default NewAssetOptions;
