import './measurementRow.scss';
import { Api } from '@services';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Icon } from '../../../../components';

class ZoneEditRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'Measurement',
      meters: '0',
      distanceUnit: 'Metric', // Initialize with a default value
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // Make a GET request to the API endpoint /users
    Api.get('/users')
      .then((response) => {
        this.setState({ distanceUnit: response.data.distanceUnit });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    this.props.changedDistance(Number(event.target.value));
  };

  render() {
    const { pxPerMeter, initPxPerMeter } = this.props;
    const { meters, distanceUnit } = this.state;

    // Convert meters to feet if the distanceUnit is 'Imperial'
    const displayDistancePxPer = distanceUnit === 'Imperial' ? pxPerMeter * 3.28084 : pxPerMeter;
    const displayDistanceinitPxPer =
      distanceUnit === 'Imperial' ? initPxPerMeter * 3.28084 : initPxPerMeter;
    const renderTooltip = (tip) => <Tooltip id="button-tooltip">{tip}</Tooltip>;
    return (
      <div className="measurement-row">
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip(
            'Set Map Scale: This tool helps set the scale of the map. Adjust the input value and click the pin to draw a line between points to set the scale.',
          )}
        >
          <span className="d-inline-block">
            <div className="" variant="link">
              <Icon name="info" variant="dark" size="md" disabled={false} />
            </div>
          </span>
        </OverlayTrigger>

        <div className="d-flex align-items-center justify-content-between w-100 edit-static-m-btn">
          <div className="form-group w-25">
            Distance between points in {distanceUnit === 'Imperial' ? 'Ft' : 'Meters'}
          </div>
          <div className="form-group w-25">
            <input className="form-control" value={meters} onChange={this.handleChange('meters')} />
          </div>

          <div className="w-25">
            <div className="btn flat-btn btn-default p-2 mb-3">
              <span
                className="me-4"
                onClick={this.props.startMeasurement}
                title="Change location on map"
              >
                <i className="fa fa-map-pin" />
              </span>
            </div>
          </div>
        </div>

        <div>
          <p>
            Saved {distanceUnit === 'Imperial' ? 'Ft' : 'Meters'}: {displayDistanceinitPxPer}
          </p>
          <p>
            Current {distanceUnit === 'Imperial' ? 'Ft' : 'Meters'}{' '}
            {displayDistancePxPer || 'Change the Distance and use the pin to update this value.'}
          </p>
        </div>
      </div>
    );
  }
}

ZoneEditRow.propTypes = {
  startMeasurement: PropTypes.func,
  changedDistance: PropTypes.func,
  pxPerMeter: PropTypes.number,
  initPxPerMeter: PropTypes.number,
};

export default ZoneEditRow;
