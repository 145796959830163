import './Loading.scss';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';
import * as loadingAnim from '../../animations/loading.json';
function Loading() {
  return (
    <div className="loading-lottie h-100 w-100 d-flex flex-row flex-wrap justify-content-center align-items-center">
      <Lottie animationData={loadingAnim.default} loop play />
    </div>
  );
}

Loading.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default Loading;
