import './beaconEditRow.scss';
import PropTypes from 'prop-types';
import { Component } from 'react';
import Search from '../../../../components/search';

class BeaconEditRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      beacon: this.props.beacon.beacon,
    };

    this.onSearchItemSelected = this.onSearchItemSelected.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onSearchItemSelected(item) {
    this.setState({ beacon: item });
  }

  onConfirm() {
    this.props.onEditCompleted('beacons', {
      index: this.props.beacon.index,
      beaconId: this.state.beacon.id,
      location: this.props.beacon.location,
      beacon: this.state.beacon,
      isVisible: true,
    });
  }

  onCancel() {
    if (!this.props.beacon.beacon) {
      this.props.onAddCanceled('beacons', this.props.beacon.index);
      this.props.beacon.location = null;
    } else {
      this.props.onEditCompleted('beacons', this.props.beacon);
    }
  }

  render() {
    return (
      <div className="align-items-center w-100 ps-4 pe-4 mt-4 edit-maps-group">
        <div className="form-group">
          <label className="form-label">Search Beacon *</label>
          <Search
            items={this.props.searchItems}
            onSelect={this.onSearchItemSelected}
            exceptIds={this.props.excludedSearchItems}
            onChange={this.props.onSearchFilterChanged}
            selected={this.state.beacon}
            title="deviceId"
            subTitle="name"
            showInitial={this.state.beacon !== null}
          />
        </div>

        <div className="d-flex justify-content-center mt-4">
          <span
            className="btn"
            onClick={() => this.props.onEditMap(this.props.beacon)}
            title="Set Coordinates"
          >
            <button
              className={`beacons btn btn-primary ${
                this.props.beacon.location ? 'coords-set' : ''
              }`}
              type="button"
            >
              {this.props.beacon.location
                ? `Coordinates Set: ${this.props.beacon.location.x}, ${this.props.beacon.location.y}`
                : 'Set Map Coordinates'}
            </button>
          </span>
        </div>

        <div className="d-flex justify-content-center mt-5 edit-map-submit-cancel-container">
          <button
            onClick={this.state.beacon && this.props.beacon.location ? this.onConfirm : undefined}
            type="button"
            className={`btn btn-primary pe-4 ps-4 confirm ${
              this.state.beacon && this.props.beacon.location ? 'enabled-btn' : 'disabled-btn'
            }`}
            title="Confirm"
          >
            Confirm
          </button>
          <button
            onClick={this.onCancel}
            type="button"
            className="btn btn-primary pe-4 ps-4 btn-danger"
            title="Cancel"
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

BeaconEditRow.propTypes = {
  beacon: PropTypes.object,
  onSearchFilterChanged: PropTypes.func,
  searchItems: PropTypes.array,
  excludedSearchItems: PropTypes.array,
  onEditCompleted: PropTypes.func,
  onAddCanceled: PropTypes.func,
};

export default BeaconEditRow;
