import './DetailOptionsOverlay.scss';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import { Button, Col, Overlay, Popover, Row } from 'react-bootstrap';
import { isUser } from '../../utils';
import Icon from '../Icon/Icon';

function DetailOptionsOverlay({
  placement,
  setAssetNameDirty,
  showEditBtn,
  setEditModeEnabled,
  onClickDelete,
}) {
  const [showOptions, setShowOptions] = useState(false);

  const optionsRef = useRef();

  const closeOptions = () => setShowOptions(false);

  const _onClickDelete = useCallback(() => {
    onClickDelete({ closeOptions });
    setShowOptions(false);
  }, [onClickDelete]);

  const _onClickEdit = () => {
    setEditModeEnabled(false);
    setAssetNameDirty(true);
    setShowOptions(false);
  };

  return (
    <>
      <div ref={optionsRef}>
        <Icon
          name="h-dots"
          variant="light"
          size="lg"
          className="align-sub"
          disabled={false}
          showPointer={true}
          onClick={() => setShowOptions(true)}
        />
      </div>

      <Overlay
        target={optionsRef.current}
        show={showOptions}
        placement={placement}
        rootClose
        onHide={() => setShowOptions(false)}
      >
        {({ ...props }) => {
          return (
            <Popover {...props}>
              <Popover.Body>
                <Row>
                  <Col className="d-flex flex-column">
                    {showEditBtn && (
                      <Button className="edit-btn mb-3" variant="primary" onClick={_onClickEdit}>
                        <i className="fa fa-pencil sonarIcon-white me-2" />
                        <span> Edit</span>
                      </Button>
                    )}

                    {isUser() === false && (
                      <Button className="delete-btn" variant="danger" onClick={_onClickDelete}>
                        <i className="fa fa-trash me-2" />
                        <span> Delete</span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </Popover.Body>
            </Popover>
          );
        }}
      </Overlay>
    </>
  );
}

DetailOptionsOverlay.defaultProps = {
  placement: 'bottom',
};

DetailOptionsOverlay.propTypes = {
  onClickDelete: PropTypes.func.isRequired,

  // defaults
  placement: PropTypes.string,
};

export default DetailOptionsOverlay;
