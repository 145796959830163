import './CreateAssetApi.scss';
import * as successAnim from '@animations/success.json';
import { Loading, NewAssetOptions } from '@components';
import { useOnMount } from '@react';
import { assetService } from '@services';
import { CreateAsset } from '@types';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import Lottie from 'react-lottie-player';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

interface CreateAssetApiProps {
  asset: CreateAsset;
  onSuccess(asset: CreateAsset & { id: number }): void;
  onError(error: Error): void;
  onDone(): void;
  onCreateAnotherAsset(): void;
}

/**
 * @desc Screen to create an Asset via api
 */
function CreateAssetApi({
  asset,
  onError,
  onSuccess,
  onDone,
  onCreateAnotherAsset,
}: CreateAssetApiProps) {
  const [createdAsset, setCreatedAsset] = useState<CreateAsset & { id: number }>(null);
  const [showAnim, setShowAnim] = useState(true);

  const history = useHistory();

  const mutation = useMutation(assetService.createAsset, {
    onError,
    onSuccess,
  });

  useOnMount(async () => {
    const assetWithId = await mutation.mutateAsync(asset);
    setCreatedAsset(assetWithId);
  });

  if (mutation.isLoading || mutation.isIdle) return <Loading />;

  if (mutation.isError) return <Alert variant="danger">Failed to create Asset.</Alert>;

  if (!createdAsset) return <Loading />; // success, however setCreatedAsset is async and may take time

  const onAnimComplete = () => {
    setShowAnim(false);
  };

  return (
    <Container className="p-3">
      {/* {showAnim && ( */}
      <div className="lottie-container-create-asset d-flex flex-column align-items-center">
        <Lottie
          className="lottie-check-create-asset"
          animationData={(successAnim as any).default}
          play
          loop={false}
          onComplete={onAnimComplete}
        />
      </div>
      {/* )} */}
      <div className="success-create-asset text-center">
        <p>
          You have successfully created asset <b>{createdAsset.name}</b>.
        </p>
        <p>
          Click{' '}
          <a href={`/assets/${createdAsset.id}`}>
            <b>here</b>
          </a>{' '}
          to go to your asset or use an option below.
        </p>
      </div>

      {!showAnim && <NewAssetOptions onDone={onDone} onCreateAnotherAsset={onCreateAnotherAsset} />}
    </Container>
  );
}

CreateAssetApi.propTypes = {
  asset: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  onCreateAnotherAsset: PropTypes.func.isRequired,
};

export default CreateAssetApi;
