import { Link } from 'react-router-dom';

function AssetNameCellRenderer({ data }) {
  if (data.assetId === 'N/A' || data.assetId === null || data.assetId === undefined)
    return <span>--</span>;

  return (
    <Link
      to={{
        pathname: `/assets/${data.assetId}`,
        state: { assetName: data.assetName }, // Pass the asset name in state
      }}
      className="btn-link font-weight-light"
    >
      {data.assetName || '--'}
    </Link>
  );
}

export default AssetNameCellRenderer;
