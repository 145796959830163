import './IconValueCellRenderer.scss';
import Icon from '../../../Icon/Icon';
import Text from '../../../Text/Text';

function IconValueCellRenderer({ iconName, value }) {
  return (
    <>
      <Icon
        className="icon-value-cell-renderer"
        name={iconName}
        variant="dark"
        disabled={false}
        showPointer={false}
      />
      <Text>{value}</Text>
    </>
  );
}

export default IconValueCellRenderer;
