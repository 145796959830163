import '../../MapMenu.scss';
import '../../../../scss/variables.scss';
import { Button, Card, ToggleButtonGroup } from 'react-bootstrap';

export function MapEditTabs({ selected, setSelected, setClickedItem }) {
  return (
    <div className="objects-bar">
      <Card.Header>
        <ToggleButtonGroup
          type="radio"
          name="info-tabs"
          className="d-flex flex-row justify-content-between"
        >
          <Button
            className="d-flex flex-row align-items-center justify-content-center"
            variant={selected === 'overlay' ? 'primary' : 'secondary'}
            onClick={() => {
              selected === 'overlay' ? setSelected('') : setSelected('overlay');
              setClickedItem(null);
            }}
          >
            <i className="sonarIcon sonarIcon-map fs-md" style={{ marginRight: '8px' }} />
          </Button>
          <Button
            className="d-flex flex-row align-items-center justify-content-center"
            variant={selected === 'place' ? 'primary' : 'secondary'}
            onClick={() => {
              selected === 'place' ? setSelected('') : setSelected('place');
              setClickedItem(null);
            }}
          >
            <i className="sonarIcon sonarIcon-place fs-md" style={{ marginRight: '8px' }} />
          </Button>
          <Button
            className="d-flex flex-row align-items-center justify-content-center"
            variant={selected === 'gateway' ? 'primary' : 'secondary'}
            onClick={() => {
              selected === 'gateway' ? setSelected('') : setSelected('gateway');
              setClickedItem(null);
            }}
          >
            <i className="sonarIcon sonarIcon-gateway fs-md" style={{ marginRight: '8px' }} />
          </Button>
          <Button
            className="d-flex flex-row align-items-center justify-content-center"
            variant={selected === 'stationary' ? 'primary' : 'secondary'}
            onClick={() => {
              selected === 'stationary' ? setSelected('') : setSelected('stationary');
              setClickedItem(null);
            }}
          >
            <i
              className="sonarIcon sonarIcon-stationary-beacon fs-md"
              style={{ marginRight: '8px' }}
            />
          </Button>
        </ToggleButtonGroup>
      </Card.Header>
    </div>
  );
}
