import './OnlineStatus.scss';
import PropTypes from 'prop-types';

function OnlineStatus({ variant, className = '' }) {
  return <span className={`onlineStatus onlineStatus-${variant} circle ${className}`} />;
}

OnlineStatus.propTypes = {
  variant: PropTypes.string.isRequired,
  size: PropTypes.number,
  className: PropTypes.string,
};

export default OnlineStatus;
