import { Button, ButtonProps, Spinner } from 'react-bootstrap';

export interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
  text: string;
  // TODO: change to children so more than text can be here
}

function LoadingButton(props: LoadingButtonProps) {
  const {
    isLoading,
    text,
    onClick,
    variant,
    disabled,
    className = '',
    id = '',
    type = 'button',
  } = props;

  return (
    <Button
      type={type}
      variant={variant}
      disabled={isLoading || disabled}
      onClick={onClick}
      className={className}
      id={id}
    >
      {isLoading ? <Spinner variant="light" animation="border" /> : text}
    </Button>
  );
}

export default LoadingButton;
