import './index.scss';
import { LastLocationCellRenderer } from '@components';
import { Api } from '@services';
import Axios from 'axios';
import { useEffect, useState } from 'react';
import { LinkCellRenderer } from '../../components';
import { PagedQueryParams, ServerSideGrid } from '../../components/grids/AgGrid/ServerSideGrid';
import AssetNameCellRenderer from '../deviceMgmt/AssetNameCellRenderer';
import {
  assetColumns,
  batteryColumns,
  environmentalColumns,
  triggerResolutionColumns,
} from './columnDefs';

const Reports = () => {
  //useState Hooks
  const [rowData, setRowData] = useState(null);
  const [csvData, setCsvData] = useState(null);
  const [csvFileName, setCsvFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [reportType, setReportType] = useState('');
  const [reportLabel, setReportLabel] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [disabledFilters, setDisabledFilter] = useState([]);

  const ChangeReportType = (reportType, reportLabel) => {
    setReportType(reportType);
    setReportLabel(reportLabel);
  };

  const id = 'analytic-reports';
  const cancelToken = Api.CancelToken;
  let fetchDataCancel;

  const columnDefs = {
    assets: assetColumns,
    environmental: environmentalColumns,
    'trigger-resolution': triggerResolutionColumns,
    battery: batteryColumns,
  };

  const assetFilters = JSON.parse(localStorage.getItem('assetFilters')).concat(
    JSON.parse(localStorage.getItem('tempFilters')) || [],
  );

  const [tabKey] = useState(`reports/${reportType}`);

  const fetchData = async (payload, csvExport, queryState, pageType) => {
    if (fetchDataCancel !== null && typeof fetchDataCancel === 'function') {
      fetchDataCancel();
      fetchDataCancel = null;
    }

    setIsLoading(true);

    Api.post(`/reports/${reportType}/filtered/`, payload, {
      cancelToken: new cancelToken(function executor(c) {
        fetchDataCancel = c;
      }),
    })
      .then((data) => {
        let resp = data.data;
        setRowData(resp);
        setIsLoading(false);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          setIsLoading(false);
        }
      });

    // Help create call to export reports
    if (csvExport) {
      const config = {
        responseType: 'blob',
        headers: {
          Accept: 'text/csv',
        },
      };

      Api.post(`/reports/${reportType}/export`, payload, config)
        .then((res) => {
          switch (reportType) {
            case 'battery':
              setCsvFileName('Analytics_Battery_Report_Export');
              setCsvData(res.data);
              break;
            case 'trigger-resolution':
              setCsvFileName('Analytics_Trigger_Resolution_Report_Export');
              setCsvData(res.data);
              break;
            default:
              setCsvData(res.data);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  // Set row data to undefined when switching between reports
  useEffect(() => {
    if (rowData?.count) {
      setRowData(0);
    } else {
      setRowData(null);
    }
    setCsvData(null);
  }, [reportType]);

  // Setting Disabled Filters for Reports
  useEffect(() => {
    switch (reportType) {
      case 'battery':
        setDisabledFilter(['all']);
        break;
      case 'environmental':
        setDisabledFilter(['all', 'identifier', 'keyword', 'map', 'place', 'active']);
        break;
      case 'trigger-resolution':
        setDisabledFilter([
          'all',
          'identifier',
          'keyword',
          'map',
          'place',
          'device id',
          'battery value',
        ]);
        break;
      default:
        setDisabledFilter([]);
    }
  }, [reportType]);

  return (
    <>
      <ServerSideGrid
        id={id}
        initQueryParams={new PagedQueryParams()}
        columnDefs={columnDefs[reportType]}
        rowData={rowData}
        csvData={csvData}
        csvFileName={csvFileName}
        handleFetchData={fetchData}
        isLoading={isLoading}
        assetFilters={assetFilters}
        dateControl={true}
        filterControl={true}
        disabledFilters={disabledFilters}
        searchControl={true}
        viewingControl={true}
        pagerControl={true}
        exportControl={true}
        ignoreFetch={true}
        dateFilters
        gridProps={{
          frameworkComponents: {
            AssetNameCellRenderer,
            LastLocationCellRenderer,
            LinkCellRenderer,
          },
        }}
        tabKey={tabKey}
        reportType={reportType}
        reportLabel={reportLabel}
        ChangeReportType={ChangeReportType}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      />
    </>
  );
};

export default Reports;
