import * as L from 'leaflet';

export var mapNewPointIcon = L.divIcon({
  html: "<div style='background-color: var(--nav-text);' class='marker-pin'></div><i class='sonarIcon sonarIcon-add fw-bold' style='color: var(--nav-text)'>",
  iconSize: [30, 42], // size of the icon
  iconAnchor: [15, 42], // point of the icon which will correspond to marker's location
  popupAnchor: [-1, -12],
  className: 'custom-div-icon',
});

export var mapGatewayOnlineIcon = L.divIcon({
  html: "<div style='background-color: var(--c-primary);' class='marker-pin'></div><i class='sonarIcon sonarIcon-gateway fw-bold' style='color: var(--c-primary)'>",
  iconSize: [30, 42], // size of the icon
  iconAnchor: [15, 42], // point of the icon which will correspond to marker's location
  popupAnchor: [-1, -12],
  className: 'custom-div-icon',
});

export var mapGatewayOfflineIcon = L.divIcon({
  html: "<div style='background-color: var(--danger);' class='marker-pin'></div><i class='sonarIcon sonarIcon-gateway fw-bold' style='color: var(--danger)'>",
  iconSize: [30, 42], // size of the icon
  iconAnchor: [15, 42], // point of the icon which will correspond to marker's location
  popupAnchor: [-1, -12],
  className: 'custom-div-icon',
});

export var mapStationaryOnlineIcon = L.divIcon({
  html: "<div style='background-color: var(--c-primary);' class='marker-pin'></div><i class='sonarIcon sonarIcon-stationary-beacon fw-bold' style='color: var(--c-primary)'>",
  iconSize: [30, 42], // size of the icon
  iconAnchor: [15, 42], // point of the icon which will correspond to marker's location
  popupAnchor: [-1, -12],
  className: 'custom-div-icon',
});

export var mapStationaryOfflineIcon = L.divIcon({
  html: "<div style='background-color: var(--danger);' class='marker-pin'></div><i class='sonarIcon sonarIcon-stationary-beacon fw-bold' style='color: var(--danger)'>",
  iconSize: [30, 42], // size of the icon
  iconAnchor: [15, 42], // point of the icon which will correspond to marker's location
  popupAnchor: [-1, -12],
  className: 'custom-div-icon',
});
