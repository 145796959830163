import '../../../scss/variables.scss';
import './Header.scss';
import { useDock } from '@react';
import StoreContext from '@services/Store';
import { useContext, useEffect, useRef, useState } from 'react';
import { Overlay, OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { DockContainer, Favorites, Text } from '../../../components';
import { useApp } from '../../../react';
import Icon from '../../Icon/Icon';
import { getToggledValue, NAV_ACTIONS } from '../layoutUtils';
import { ReactComponent as Logo } from './../../../img/lowercase-thinaer-logo.svg';
import { isReadOnly } from './../../../utils';
import ApplicationSwitcher from './ApplicationSwitcher/ApplicationSwitcher';
import CreateItemDropdown from './CreateNewDropdown/CreateNewDropdown';
import NavToggle from './NavToggle';
import { SettingsDropdown } from './SettingsDropdown/SettingsDropdown';

function Header({ navigation, scroll, setShowTourModal }) {
  const app = useApp();
  const location = useLocation();
  const dash = location.pathname.includes('dashboard') ? true : false;
  const edit = location.pathname.includes('-edit') ? true : false;
  const { renderDock, closeDock } = useDock();
  const [action, setAction] = useState(NAV_ACTIONS.NONE);
  const { setShowCart, cart } = useContext(StoreContext);

  useEffect(() => {
    window.addEventListener('keydown', resetActionOnKeydown);

    return () => {
      window.removeEventListener('keydown', resetActionOnKeydown);
    };
  });

  useEffect(() => {
    if (action === NAV_ACTIONS.FAVORITES) {
      renderFavorites();
    }
  }, [action]);

  const appSwitcherRef = useRef();
  const createAssetRef = useRef();
  const favoritesRef = useRef();
  const settingsRef = useRef();

  function resetAction() {
    setAction(NAV_ACTIONS.NONE);
  }

  function resetActionOnClick(e) {
    if (e.target.className.includes('header-icon')) return;

    resetAction();
  }

  function resetActionOnKeydown(e) {
    if (action === NAV_ACTIONS.NONE) return;
    if (e.code !== 'Escape') return;

    resetAction();
  }

  function toggleAction(theAction) {
    setAction(
      getToggledValue({
        firstValue: theAction,
        secondValue: NAV_ACTIONS.NONE,
        prevValue: action,
      }),
    );
  }

  const closeAppSwitcherOverlay = () => {
    resetAction(); // Reset the action to close the overlay
  };

  const renderFavorites = () => {
    renderDock({
      render: () => (
        <DockContainer>
          <Favorites
            defaultActiveKey={'0'}
            onDone={() => {
              closeDock();
              resetAction();
            }}
          />
        </DockContainer>
      ),
    });
  };

  const renderTooltip = (tip) => <Tooltip id="button-tooltip">{tip}</Tooltip>;

  const logoOpacity = navigation.isOpen ? '1' : ((100 - scroll * 2) / 100).toString();
  const menuOpacity = ((100 - scroll * 2) / 100).toString();
  const menuDisplay = menuOpacity <= 0 ? 'none' : '';

  return (
    <header className="header d-flex flex-row justify-content-between pt-3 pe-5">
      {/* Logo & Hamburger icon */}
      <div className="header-left d-flex flex-row justify-content-left align-items-middle">
        <NavToggle />
        <Link
          className={`logo ${logoOpacity >= 1 ? 'show' : 'hide'} ${
            navigation.isOpen ? 'no-marginl' : 'reg-margin'
          }`}
          to="/dashboard"
        >
          <Logo alt="Sonar" title="Sonar" />
        </Link>
      </div>

      {/* Nav actions */}
      <ul className={`header-list ${menuDisplay ? 'hidden' : 'visible'}`}>
        {Boolean(cart.products.length) && (
          <li className="me-1" onClick={() => setShowCart(true)}>
            <i class="fas fa-check-double h4 text-primary"></i>
          </li>
        )}
        <span id="application-switcher-btn">
          <Text className="fs-md fw-bold">&nbsp;{app?.name} &nbsp;</Text>
          {/* App Switcher */}
          <li>
            <div ref={appSwitcherRef}>
              <Icon
                name="circle-caret-down"
                variant="light"
                disabled={false}
                onClick={() => toggleAction(NAV_ACTIONS.APP_SWITCHER)}
                size="lg"
                className={`header-icon ${
                  action === NAV_ACTIONS.APP_SWITCHER ? 'primary-color' : 'dark-color'
                }`}
                showPointer={true}
                isOpen={action === NAV_ACTIONS.APP_SWITCHER ? true : false}
                title="Switch Application"
              />
            </div>

            <Overlay
              target={appSwitcherRef.current}
              show={action === NAV_ACTIONS.APP_SWITCHER}
              placement="bottom"
              rootClose
              onHide={closeAppSwitcherOverlay}
            >
              {({ ...props }) => {
                return (
                  <Popover {...props} className="application-popover">
                    <Popover.Header className="d-flex flex-row align-items-center justify-content-center">
                      <OverlayTrigger
                        placement="left"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(
                          'Hint: Set your default application in User Preferences under Settings.',
                        )}
                      >
                        <span className="d-inline-block info-icon-span">
                          <div className="info-icon-div">
                            <Icon
                              className="align-sub"
                              name="info"
                              variant="dark"
                              disabled={false}
                              size="md"
                            />
                          </div>
                        </span>
                      </OverlayTrigger>
                      <Text>Select Application</Text>
                    </Popover.Header>
                    <Popover.Body>
                      <ApplicationSwitcher closeDropdown={closeAppSwitcherOverlay} />
                    </Popover.Body>
                  </Popover>
                );
              }}
            </Overlay>
          </li>
        </span>
        {/* Create New */}
        {isReadOnly() === false && (
          <li id="actions-btn">
            <div ref={createAssetRef}>
              <Icon
                name="circle-add"
                variant="light"
                disabled={false}
                onClick={() => toggleAction(NAV_ACTIONS.CREATE_ASSET)}
                size="lg"
                className={`header-icon ${
                  action === NAV_ACTIONS.CREATE_ASSET ? 'primary-color' : 'dark-color'
                }`}
                showPointer={true}
                isOpen={action === NAV_ACTIONS.CREATE_ASSET ? true : false}
                title="Actions"
              />
            </div>

            <Overlay
              target={createAssetRef.current}
              show={action === NAV_ACTIONS.CREATE_ASSET}
              placement="bottom"
              rootClose
              onHide={resetActionOnClick}
            >
              {({ ...props }) => {
                return (
                  <Popover {...props}>
                    <Popover.Header>Create New</Popover.Header>
                    <Popover.Body>
                      <CreateItemDropdown closeDropdown={resetAction} />
                    </Popover.Body>
                  </Popover>
                );
              }}
            </Overlay>
          </li>
        )}
        {/* Favorites */}
        <li id="favorites-btn">
          <div ref={favoritesRef}>
            <Icon
              name="star-fill"
              variant="light"
              disabled={false}
              onClick={() => toggleAction(NAV_ACTIONS.FAVORITES)}
              size="lg"
              className={`header-icon ${
                action === NAV_ACTIONS.FAVORITES ? 'primary-color' : 'dark-color'
              }`}
              showPointer={true}
              isOpen={action === NAV_ACTIONS.FAVORITES ? true : false}
              title="Favorites"
            />
          </div>
        </li>

        {/* Settings */}
        <li id="settings-btn">
          <div ref={settingsRef} onClick={() => toggleAction(NAV_ACTIONS.SETTINGS)}>
            <Icon
              name="cog"
              variant="light"
              disabled={false}
              onClick={() => toggleAction(NAV_ACTIONS.SETTINGS)}
              size="lg"
              className={`header-icon ${
                action === NAV_ACTIONS.SETTINGS ? 'primary-color' : 'dark-color'
              }`}
              showPointer={true}
              isOpen={action === NAV_ACTIONS.SETTINGS ? true : false}
              title="Settings"
            />
          </div>

          <Overlay
            target={settingsRef.current}
            show={action === NAV_ACTIONS.SETTINGS}
            placement="bottom"
            rootClose
            onHide={resetActionOnClick}
          >
            {({ ...props }) => {
              return (
                <Popover {...props}>
                  <Popover.Header>Settings</Popover.Header>
                  <Popover.Body>
                    <SettingsDropdown
                      closeDropdown={resetAction}
                      setShowTourModal={setShowTourModal}
                    />
                  </Popover.Body>
                </Popover>
              );
            }}
          </Overlay>
        </li>
        {/* Edit Dashboard */}
        {dash && (
          <li id="edit-dash-btn">
            <a href="/dashboard-edit">
              <Icon
                name="edit"
                variant="light"
                disabled={false}
                size="lg"
                showPointer={true}
                className={`header-icon ${edit ? 'editing' : 'not-editing'}`}
                title="Edit Dashboard"
              />
            </a>
          </li>
        )}
      </ul>
    </header>
  );
}

const mapStateToProps = (state) => {
  return {
    navigation: state.navigation,
  };
};

export default connect(mapStateToProps)(Header);
