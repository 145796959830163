import './PrimaryBeaconRenderer.scss';
import { Api } from '@services';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { toast } from '../../../../components/utils';
import { emitter, EVENTS } from '../../../../screens/assets/utils';
import { isReadOnly } from '../../../../utils';
import Icon from '../../../Icon/Icon';

const PrimaryBeaconRenderer = ({ data }) => {
  const [isLocationBeacon, setIsLocationBeacon] = useState(data.isLocationBeacon);
  const [isLoading, setIsLoading] = useState(false);

  const handleIconClick = async () => {
    if (isLoading || isLocationBeacon || isReadOnly()) return; // Prevent clicking if already primary, loading, or read-only

    // Show SweetAlert confirmation
    const result = await Swal.fire({
      title: 'Change to Primary Beacon?',
      text: 'Are you sure you want to set this Beacon as the Primary on this Asset?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      reverseButtons: false,
    });

    if (!result.isConfirmed) {
      return;
    }

    setIsLoading(true);
    try {
      // Make the API call to set this beacon as the primary
      await Api.post(`/assets/${data.assetId}/beacons/${data.id}/primary`);
      emitter.emit(EVENTS.REFETCH);
      // Simulate the change in local state after successful API update
      setIsLocationBeacon(true);
      toast.success('Successfully changed Primary Beacon!');
      // Refresh the parent component or other beacons if needed
    } catch (error) {
      console.error('Error updating primary beacon:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLocationBeacon(data.isLocationBeacon);
  }, [data]);

  return (
    <div className="d-flex flex-column">
      <span className="d-inline-block">
        {/* Conditionally render based on read-only and location beacon status */}
        {isLocationBeacon || !isReadOnly() ? (
          <div
            className={`${
              isLocationBeacon ? 'asset-details-crown-icon' : 'asset-details-crown-icon-grey'
            } ${isReadOnly() ? 'read-only' : 'clickable'}`}
            variant="link"
            onClick={handleIconClick}
          >
            <Icon
              name={'crown'}
              variant="light"
              size="md"
              disabled={isLoading || isReadOnly()}
              showPointer={!isReadOnly()}
            />
          </div>
        ) : null}
      </span>
    </div>
  );
};

export default PrimaryBeaconRenderer;
