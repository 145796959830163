import { Link } from 'react-router-dom';
import Text from '../../../Text/Text';

function PlacesCellRenderer({ data }) {
  const map = data.location?.mapName ?? data.place?.mapName;
  const mapId = data.location?.mapId ?? data.place?.mapId;

  const place = data.place?.placeName;
  const placeId = data.place?.placeId;

  // If no map or places logic to display '--'

  if (map) {
    return (
      <>
        <span key={mapId}>
          <Link
            className="btn-link font-weight-light"
            to={{
              pathname: data.place?.isV2Map ? `/mapbeta/${mapId}` : `/maps/${mapId}`,
              state: { mapName: map },
            }}
          >
            <Text>{`${map}`}</Text>
          </Link>
        </span>
        {place && <Text>, </Text>}

        {place && (
          <span key={placeId}>
            <Link
              className="btn-link font-weight-light"
              to={{
                pathname: `/places/${placeId}`,
                state: { placeName: place },
              }}
            >
              <Text>{`${place}`}</Text>
            </Link>
          </span>
        )}
      </>
    );
  } else {
    return <Text>&nbsp;&nbsp;--</Text>;
  }
}

export default PlacesCellRenderer;
