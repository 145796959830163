import './CsvModal.scss';
import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Dropzone from '../../dropzones/dropzone';
import Text from '../../Text/Text';
import CloseModal from '../CloseModal';

function CsvModalV2({ title, text, onTemplate, onUpload, setModified, itemName, isDock }) {
  const [file, setFile] = useState<any>();

  const onDrop = (file) => {
    setModified(true);
    setFile(file);
  };

  const openBeaconTypesTab = () => {
    window.open('/beacon-types', '_blank');
  };

  return (
    <Container>
      <Row>
        <Col>{!isDock && <CloseModal close={() => Swal.close()} />}</Col>
      </Row>

      <Row>
        <Col>
          <h1>{title}</h1>
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <div className="d-flex flex-row justify-content-center">
            <Text>{text}</Text>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="d-flex flex-row justify-content-center">
            <Button onClick={() => onTemplate()}>Download Template</Button>
          </div>
        </Col>
      </Row>
      {itemName === 'Beacons' && (
        <Row className="my-2">
          <Col>
            <Button variant="link" className="beacon-type" onClick={openBeaconTypesTab}>
              View Beacon Types
            </Button>
          </Col>
        </Row>
      )}

      <Row className="my-4">
        <Col>
          <div className="d-flex flex-row justify-content-center">
            <Dropzone
              onDrop={onDrop}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="d-flex flex-row justify-content-center">
            <Button
              disabled={!file}
              onClick={() => {
                if (!isDock) {
                  swal.close();
                }
                onUpload(file);
              }}
            >
              Upload
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default CsvModalV2;
