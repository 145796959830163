import { appToggleApplication } from '@redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { EditDeleteCellRenderer, IconCellRenderer, toast } from '../../components';
import { store } from '../../redux';
import { CustomApi } from '../../services';
import { getFormattedDateTime } from '../../utils';

const MySwal = withReactContent(Swal);

export const adminAppColumnDefs = (dispatch) => [
  {
    headerName: 'Application Name',
    field: 'name',
    colId: 'name',
    suppressMovable: true,
    editable(params) {
      const { node } = params;
      const { editDeleteCR } = store.getState().grids;
      return node.data.id === editDeleteCR.id;
    },
  },
  {
    headerName: 'Active',
    field: 'active',
    cellRenderer: (params) => {
      return params.value === true ? 'Yes' : 'No';
    },
  },
  {
    headerName: 'Description',
    field: 'description',
    cellRenderer: (params) => {
      return params.value ? params.value : '--';
    },
  },
  {
    headerName: 'Last Login',
    field: 'lastModified',
    valueFormatter(params) {
      return getFormattedDateTime(params.value);
    },
  },
  {
    headerName: 'Edit/Delete',
    colId: 'editDelete',
    suppressMovable: true,
    sortable: false,
    width: 100,
    cellRenderer: EditDeleteCellRenderer,
    cellRendererParams(params) {
      const { data, api, node } = params;
      const id = data.id;
      return {
        id,
        onClickEdit() {
          let rowIndex = 0;
          api.forEachNodeAfterFilterAndSort((node, nodeIndex) => {
            if (node.data.id === id) rowIndex = nodeIndex;
          });
          api.startEditingCell({
            colKey: 'name',
            rowIndex: rowIndex,
          });
        },
        onClickCancelEdit() {
          api.stopEditing(true);
        },
        async onClickDelete() {
          const { value: enteredAppName } = await MySwal.fire({
            title: 'Confirm to Delete',
            input: 'text',
            inputValue: '',
            inputPlaceholder: `Type "${data.name}" to confirm.`,
            showCancelButton: true,
            confirmButtonText: 'Delete',

            cancelButtonText: 'Cancel',
            inputValidator: (value) => {
              if (!value || value.trim() !== data.name) {
                return 'Entered name does not match. Please type the application name to confirm.';
              }
            },
          });

          if (enteredAppName) {
            try {
              await CustomApi.delete(`/api/admin/applications/${id}`);
              api.gridBodyCtrl.gridOptionsService.gridOptions.rowData.splice(node.rowIndex, 1);
              api.setRowData(api.gridBodyCtrl.gridOptionsService.gridOptions.rowData);
              toast.success(`Successfully deleted app ${data.name}!`);
            } catch (error) {
              toast.error(`Failed to delete app ${data.name}.`);
            }
          }
        },
        async onClickConfirmEdit() {
          const updatedAppName = {
            name: data.name || '',
          };
          try {
            await CustomApi.put(`/api/admin/applications/${id}`, updatedAppName);
            toast.success(`Successfully updated application ${data.name}!`);
          } catch (error) {
            toast.error('Failed to update application.');
          }
        },
      };
    },
  },
  {
    headerName: 'Link To',
    field: 'url',
    cellRenderer: IconCellRenderer,
    cellRendererParams: ({ data }) => {
      const linkToApp = () => {
        dispatch(appToggleApplication({ id: data.id, name: data.name }));
        window.location.href = '/dashboard';
      };
      return {
        className: '',
        iconName: 'url',
        disabled: false,
        variant: 'light',
        size: 'md',
        onClick: () => {
          linkToApp();
        },
      };
    },
  },
];
