import { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import { Icon } from '@components';
import classnames from 'classnames';
import { TriggerWorkflowStepProps } from './TriggerWorkflowStepsProps';
import { isReadOnly } from '../../../utils';

interface IRenderSubscriptionProps {
  index: number;
  value: string;
  onRemove: (index: number) => void;
  onChange: (index: number, value: string) => void;
}

function RenderSubscription({ index, value, onRemove, onChange }: IRenderSubscriptionProps) {
  return (
    <div className={'w-100 d-flex flex-row align-items-center'} key={index}>
      <Form.Control
        className={'form-control-sm'}
        type="text"
        as={'input'}
        value={value}
        onChange={(e) => {
          onChange(index, e.target.value);
        }}
      />
      <button className={'btn btn-small ms-2'} onClick={() => onRemove(index)}>
        x
      </button>
    </div>
  );
}

export function SubscriptionsForm({ trigger, onChange }: TriggerWorkflowStepProps) {
  const [subs, setSubs] = useState<string[]>(trigger?.Webhooks ?? []);

  useEffect(() => {
    onChange(subs, 'Webhooks');
    // eslint-disable-next-line
  }, [subs]);

  function AddSubscription() {
    setSubs([...subs, '']);
  }
  function RemoveSubscription(index: number) {
    setSubs(subs.filter((s, i) => i !== index));
  }

  return (
    <Form.Group as={Row}>
      <Form.Label column sm="2">
        Subscriptions
      </Form.Label>
      <Col className="d-inline-flex">
        {!isReadOnly() && (
          <Icon
            name="add"
            size="xs"
            className={classnames('sonarIcon-circle', {
              'sonarIcon-rotate-45': false,
            })}
            disabled={false}
            showPointer={true}
            onClick={() => AddSubscription()}
            variant="dark"
          />
        )}

        <div className={'w-100 d-flex flex-column'}>
          {subs.map((s, i) => (
            <RenderSubscription
              index={i}
              value={s}
              onRemove={RemoveSubscription}
              onChange={(index, value) => {
                setSubs(
                  subs.map((s, i) => {
                    if (i === index) {
                      return value;
                    }
                    return s;
                  }),
                );
              }}
            />
          ))}
        </div>
      </Col>
    </Form.Group>
  );
}
