import '../../scss/variables.scss';
import './TriggerResolutionModal.scss';
import { Text } from '@components';
import { Loading, MultiUserSelect } from '@components';
import { toast } from '@components/utils';
import { appToggleApplication } from '@redux';
import { Api } from '@services';
import { appService } from '@services';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Modal, Row, Table } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import * as loadingAnim from '../../animations/loading.json';
import { ReactComponent as Logo } from './../../img/lowercase-thinaer-logo.svg';

const makeid = (length) => {
  let result = '';
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const Dialog = (p) => <div className="trigger-res-modal modal-content">{p.children}</div>;
const TriggerResolutionModal = ({
  show,
  handleClose,
  instanceId,
  resolvedById,
  appId,
  currentApp,
  assignedApps,
  toggleApp,
}) => {
  const [comment, setComment] = useState('');
  const [error, setError] = useState(false);
  const [subscribers, setSubscribers] = useState([]);
  const [view, setView] = useState(null);
  const [data, setData] = useState({});
  const searchContainerRef = useRef(null);
  const [statusColor, setStatusColor] = useState('light');
  const [isLoading, setIsLoading] = useState(false);
  const { data: apps } = useQuery(['appselector-applications'], () => appService.getApps(2000));

  const resolve = () => {
    setIsLoading(true);
    const sendData = {
      comment,
      ContactIds: subscribers,
    };
    Api.post(`/bulktriggers/instances/${instanceId}/resolvedBy/${resolvedById}`, sendData)
      .then((res) => {
        toast.success('Trigger resolved!');
        handleClose();
        setIsLoading(false);
      })
      .catch((e) => {
        toast.error('An error occurred while trying to resolve a trigger.');
      });
  };

  useEffect(() => {
    if (Number(currentApp.id) !== Number(appId) && assignedApps.includes(appId) && apps) {
      setView(null);
      setTimeout(() => {
        toggleApp(apps.find((a) => Number(a.id) === Number(appId)));
        window.location.reload();
      }, 1000);
    }
    if (appId === undefined) return;
    if (!assignedApps.includes(appId)) {
      setView('no-access');
    }
  }, [appId, apps]);

  useEffect(() => {
    if (Number(currentApp.id) === Number(appId)) {
      Api.get(`/bulktriggers/instances/${instanceId}/responsestatus`)
        .then((results) => {
          if (results.data) {
            setData(results.data);
            setSubscribers(
              results.data?.triggerContacts?.map((c) => ({
                value: c.contactId,
                label: c.emailAddress,
              })),
            );
            setStatusColor(results.data?.isConditionMet ? 'danger' : 'success');

            if (results.data.response) {
              setView('success');
              setComment(results.data.response.comment);
            } else {
              setView('init');
            }
          } else {
            setView('error');
          }
        })
        .catch((e) => {
          setView('error');
          console.error(e);
        });
    } else {
      setError(true);
    }
  }, [appId, instanceId]);

  const triggerUrl = window.origin + `/triggers/${data?.triggerId}`;
  const assetUrl = window.origin + `/assets/${data?.assetId}`;

  return (
    <Modal show={show} onHide={handleClose} dialogAs={Dialog}>
      {isLoading ? (
        <div className="loading">
          <Loading animationData={loadingAnim.default} width={200} height={200} />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-end position-absolute exit-trigger">
            <Button variant="light" onClick={handleClose}>
              <i className="fa fa-times" />
            </Button>
          </div>
          <Modal.Header className="border-0">
            <Modal.Title className="text-center m-auto pt-4 pb-4">
              <Logo className="logo" fill={'var(--primary)'} alt="Sonar" title="Sonar" />
            </Modal.Title>
          </Modal.Header>
          {view === 'no-access' ? (
            <Modal.Body className="text-center">
              <div className="alert alert-warning text-center">
                You do not have access to this trigger.
              </div>
            </Modal.Body>
          ) : (
            <Modal.Body>
              {view && view !== 'error' ? (
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={8}>
                      <Row>
                        <Col>
                          {view === 'init' ? (
                            <>
                              Trigger{' '}
                              <a
                                href={triggerUrl}
                                ref={searchContainerRef}
                                className="text-primary"
                              >
                                {data?.triggerName}
                              </a>{' '}
                              has been activated for Asset{' '}
                              <a href={assetUrl} className="text-primary">
                                {data?.assetName}
                              </a>
                              .
                            </>
                          ) : (
                            <>
                              Trigger{' '}
                              <a href={triggerUrl} className="text-primary">
                                {data?.triggerName}
                              </a>{' '}
                              has been resolved by user,{' '}
                              {data?.response?.contact?.emailAddress.trim()} at{' '}
                              {moment(data?.response?.contact?.timestamp).format(
                                'ddd, MMM Do, yyyy h:mm: A',
                              )}
                              .
                            </>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col>
                          {view === 'init' ? (
                            <>{data?.readingType} reading is out of range.</>
                          ) : (
                            <>{comment}</>
                          )}
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col xs={10} className="m-auto">
                          <Table className="data-table">
                            <tbody>
                              <tr>
                                <td className="border-0">Asset</td>
                                <td className="border-0">{data?.assetName}</td>
                              </tr>
                              <tr>
                                <td className="border-0">Beacon</td>
                                <td className="border-0">{data?.beaconName}</td>
                              </tr>
                              <tr>
                                <td className="border-0">Time</td>
                                <td className="border-0">
                                  {moment(data?.triggerTime).format('ddd, MMM Do, yyyy h:mm: A')}
                                </td>
                              </tr>
                              <tr>
                                <td className="border-0">Current Value</td>
                                <td className="border-0">
                                  <span
                                    className={`current-value border ps-3 pe-3 pt-1 pb-1 border-${statusColor}`}
                                  >
                                    {data.currentValue}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td className="border-0">Accepted Value</td>
                                <td className="border-0">
                                  {data?.acceptedValue?.trim().replace(/ /g, ' or ')}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      {view === 'init' && (
                        <Row>
                          <Col xs={5} className="pt-2">
                            Notify other Sonar users:
                          </Col>
                          <Col>
                            <MultiUserSelect
                              placeholder="Add Contact"
                              onSingleChange={(e) => {
                                if (
                                  !subscribers.map((s) => s.label).includes(e.label) ||
                                  e.value === null
                                ) {
                                  if (e.value === null) {
                                    e.value = makeid(5);
                                  }
                                  setSubscribers([...subscribers, ...[e]]);
                                }
                              }}
                              onMultiChange={(e) => {
                                setSubscribers(e);
                              }}
                              value={subscribers}
                            />
                          </Col>
                        </Row>
                      )}
                      <Row className="mt-3">
                        <Col>
                          {view === 'init' ? (
                            <>
                              <Text>Human Feedback:</Text>
                              <textarea
                                key={'textarea_comments'}
                                className="form-control mt-1"
                                rows={3}
                                placeholder="Enter a comment for the trigger resolution. (Optional)"
                                value={comment}
                                onChange={({ target }) => setComment(target.value)}
                              />
                            </>
                          ) : null}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-3 mb-5">
                    <Col className="text-center">
                      {view === 'init' ? (
                        <>
                          <Button
                            className="pe-3 ps-3 me-5"
                            variant="secondary"
                            onClick={handleClose}
                          >
                            Close
                          </Button>
                          <Button className="pe-3 ps-3" variant="primary" onClick={() => resolve()}>
                            Resolve
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button className="pe-3 ps-3" variant="primary" onClick={handleClose}>
                            Done
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                </Container>
              ) : !view ? (
                <Loading />
              ) : (
                <div className="alert alert-warning text-center">
                  <h6>Trigger instances not found</h6>
                </div>
              )}
            </Modal.Body>
          )}
        </>
      )}
    </Modal>
  );
};

const mapStateToProps = ({ app, auth }) => ({
  currentApp: app.app,
  assignedApps: auth.user.apps,
});

const mapDispatchToProps = (dispatch) => ({
  toggleApp: (app) => dispatch(appToggleApplication(app)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TriggerResolutionModal);
