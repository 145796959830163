import '../../../../scss/comps/Swal.scss';
import { CreateAsset, CreateAssetApi, Text } from '@components';
import { useApp } from '@react';
import { IPostAsset } from '@types';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useWizard } from 'react-wizard-primitive';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

interface Asset extends IPostAsset {
  id?: number;
}

const STEPS = {
  CREATE_ASSET_FORM: 0,
  CREATE_ASSET_API: 1,
};

interface CreateAssetWorkflowProps {
  onDone(): void;
}

function useOutsideAlerter(ref, isModified, callBack) {
  // Checks to see if the users mouse click is within 50px of the screen edge on right
  // An approximation of where the scrollbar is
  let clickedOnScrollbar = function (mouseX) {
    return mouseX >= window.innerWidth - 50;
  };

  useEffect(() => {
    let isSwalOpen = false; // Flag to track SweetAlert state

    function handleClickOutside(event) {
      const dockElement = document.querySelector('.dock');
      const isClickOutsideDock = dockElement && !dockElement.contains(event.target);
      const isClickOutsideSwal = !event.target.closest('.swal2-popup');

      if (
        isClickOutsideDock &&
        isClickOutsideSwal &&
        ref.current &&
        !ref.current.contains(event.target) &&
        !isSwalOpen
      ) {
        const selectedNav = event.target.closest('a');
        MySwal.fire({
          allowOutsideClick: false,
          title: 'Are you sure you want to leave?',
          text: 'Your changes will not be saved.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then((submit) => {
          isSwalOpen = false; // Update flag when SweetAlert is closed
          if (submit.isConfirmed) {
            callBack();
            if (selectedNav) {
              window.location = selectedNav.attributes.href.value;
            }
          }
        });
        isSwalOpen = true; // Update flag when SweetAlert is open
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModified]);
}

function CreateAssetWorkflow({ onDone }: CreateAssetWorkflowProps) {
  const wrapperRef = useRef(null);
  const [asset, setAsset] = useState<Asset>(null);
  const [isModified, setIsModified] = useState(false);

  const app = useApp();

  const onTouched = () => {
    setIsModified(true);
  };

  const wizard = useWizard({ initialStepIndex: STEPS.CREATE_ASSET_FORM });

  const Steps = {
    [STEPS.CREATE_ASSET_FORM]: (
      <div ref={wrapperRef}>
        <CreateAsset
          isModified={onTouched}
          onCreateAsset={(asset) => {
            setAsset({ ...asset, applicationId: app.id, movementThreshold: 0 });
            wizard.goToStep(STEPS.CREATE_ASSET_API);
            setIsModified(false);
          }}
        />
      </div>
    ),
    [STEPS.CREATE_ASSET_API]: (
      <div ref={wrapperRef}>
        {asset && (
          <CreateAssetApi
            asset={asset}
            onSuccess={null}
            onError={console.error}
            onCreateAnotherAsset={() => wizard.goToStep(STEPS.CREATE_ASSET_FORM)}
            onDone={onDone}
          />
        )}
      </div>
    ),
  };

  const Step = Steps[wizard.activeStepIndex];

  useOutsideAlerter(wrapperRef, isModified, onDone);

  if (!Step) {
    return (
      <div>
        <Text variant="danger">Sorry! We failed to find a step in this workflow.</Text>
      </div>
    );
  }

  return Step;
}

CreateAssetWorkflow.propTypes = {
  onDone: PropTypes.func.isRequired,
};

export default CreateAssetWorkflow;
