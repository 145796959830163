import '../../MapMenu.scss';
import './MapEdit.scss';
import { Api } from '@services';
import { useEffect, useRef, useState } from 'react';
import { Card, Form, InputGroup } from 'react-bootstrap';
import { AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Icon, Text } from '../../..';
export function MapCreateGateway({
  selected,
  newObjCreated,
  curGateways,
  selectedGateway,
  setCurGateways,
  setSelectedGateway,
  setIsCreating,
  setCreateGateway,
  setObjectId,
  setObjectName,
  setObjectFloor,
  setObjectKeywords,
  setObjectOnline,
  setAddToMap,
  setRemoveTempItem,
}) {
  const [name, setName] = useState<string>('');
  const [floor, setFloor] = useState<string>('1');
  const [id, setId] = useState<string>(null);
  const [isOnline, setIsOnline] = useState<boolean>(null);
  const [showSave, setShowSave] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<any>([]);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);
  const [cache, setCache] = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<any[]>([]);
  const [query, setQuery] = useState<string>('');
  const gatewayRef = useRef(null);
  const floorRef = useRef(null);

  const MySwal = withReactContent(Swal);

  useEffect(() => {
    setObjectId(id);
  }, [id, setObjectId]);

  useEffect(() => {
    setObjectName(name);
  }, [name, setObjectName]);

  useEffect(() => {
    setObjectFloor(floor);
  }, [floor, setObjectFloor]);

  useEffect(() => {
    setObjectKeywords(keywords);
  }, [keywords, setObjectKeywords]);

  useEffect(() => {
    setObjectOnline(isOnline);
  }, [isOnline, setObjectOnline]);

  useEffect(() => {
    newObjCreated !== null ? setShowSave(true) : setShowSave(false);
  }, [newObjCreated]);

  const handleInput = (items) => {
    if (items.length > 0) {
      if (items[0].assigned) {
        MySwal.fire({
          allowOutsideClick: false,
          title: 'You are reassigning this Gateway',
          text: 'Are you sure you want to remove this Gateway from its current map?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then((submit) => {
          if (submit.isConfirmed) {
            setSelectedGateway(items[0].id);
            setId(items[0].id.toString());
            setName(items[0].label);
            setIsOnline(items[0].isOnline);
            setIsInvalid(false);
            setTimeout(() => {
              gatewayRef.current.blur();
            }, 500);
          } else {
            MySwal.close();
            gatewayRef.current.clear();
          }
        });
      } else {
        setSelectedGateway(items[0].id);
        setId(items[0].id.toString());
        setName(items[0].label);
        setIsOnline(items[0].isOnline);
        setIsInvalid(false);
      }
    } else {
      setSelectedGateway(null);
      setId('');
      setName('');
      setIsOnline(null);
      setIsInvalid(true);
    }
  };

  // Handle submission of item
  function startDraw() {
    setCreateGateway(true);
  }

  const getResults = async (endpoint) => {
    const resp = await Api.get(endpoint);
    const newItems = (resp.data as any).data.map((gateway) => ({
      id: gateway.id,
      name: gateway.name,
      isOnline: gateway.isOnline,
      deviceId: gateway.deviceId,
      assignedMap: gateway.assignedMap?.id ? gateway.assignedMap?.id : null,
    }));
    return { newItems };
  };

  const handleSearch = (value) => {
    setQuery(value);
    // if cached query items are available then set those as the initial options
    if (cache[value]) {
      setOptions(cache[value].items);
    } else {
      // get and cache new query items
      setIsLoading(true);
      getResults(`/v2/gateways?search=${value}`).then((resp) => {
        // const result = resp.newItems.filter(({ id }) => !curGateways.includes(id));
        setOptions(resp.newItems);
        setCache({
          ...cache,
          [value]: { items: resp.newItems },
        });
        setIsLoading(false);
      });
    }
  };

  const handleClick = () => {
    setCurGateways([...curGateways, selectedGateway]);
    setAddToMap(true);
    setIsCreating(false);
    setCreateGateway(false);
    setShowSave(false);
  };

  return (
    <Form className={selected !== 'gateway' ? 'object-wrapper' : 'object-wrapper-expand'}>
      <Card.Body>
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <Text className="fw-bold fs-md">Gateway Search:</Text>
            <div>
              {showSave === true ? (
                <Icon
                  name={'check'}
                  title="Confirm"
                  className="align-sub primary-icon"
                  variant="light"
                  disabled={name === '' ? true : false}
                  size="md"
                  onClick={handleClick}
                />
              ) : (
                <Icon
                  name={'pin-edit'}
                  title="Place Gateway"
                  className="align-sub primary-icon"
                  variant="light"
                  disabled={name === '' ? true : false}
                  size="md"
                  onClick={() => {
                    startDraw();
                  }}
                />
              )}

              <Icon
                name={'add'}
                title="Close"
                className="sonarIcon-rotate-45 align-sub nav-text-icon"
                variant="light"
                disabled={false}
                size="md"
                onClick={() => {
                  setCreateGateway(false);
                  setIsCreating(false);
                  setRemoveTempItem(true);
                }}
              />
            </div>
          </div>
          <div className="input-placeholder mb-3" />
          <div className="d-flex align-items-center justify-content-between mb-3 typeahead-menu">
            <AsyncTypeahead
              ref={gatewayRef}
              filterBy={['label', 'deviceId']}
              delay={500}
              id="gateway"
              isInvalid={isInvalid}
              isLoading={isLoading}
              maxResults={10}
              minLength={1}
              onChange={(e) => {
                handleInput(e);
              }}
              onSearch={handleSearch}
              options={
                options &&
                options.map((option) => {
                  return {
                    id: option.id,
                    label: option.name,
                    isOnline: option.isOnline,
                    deviceId: option.deviceId,
                    disabled: curGateways.includes(option.id),
                    assignedCur: curGateways.includes(option.id),
                    assigned: option.assignedMap,
                  };
                })
              }
              paginate
              placeholder="Search for a Gateway..."
              renderMenuItemChildren={(option, { text }) => (
                <div
                  title={option.label}
                  style={{ opacity: curGateways.includes(option.id) ? 0.5 : 1 }}
                >
                  <Highlighter search={text}>{option.label}</Highlighter>
                  <div className="d-flex flex-column">
                    <Text className="fs-xs">ID: {option.deviceId}</Text>
                    <Text className="fs-xs">
                      Online:{' '}
                      {option.isOnline.toString().charAt(0).toUpperCase() +
                        option.isOnline.toString().slice(1)}
                    </Text>
                    {option.assigned && (
                      <div className="d-flex flex-row">
                        <Text className="fs-xs warn-text">Assigned to Map: &nbsp;</Text>
                        <Text className="fs-xs primary-text">{option.assigned}</Text>
                      </div>
                    )}
                    {option.assignedCur && (
                      <Text className="fs-xs">{'(Gateway is already assigned to this Map)'}</Text>
                    )}
                  </div>
                </div>
              )}
              useCache={false}
            />
          </div>
          <div className="d-flex flex-column justify-content-between mt-3">
            <Text className="fw-bold fs-md mb-3">Floor: </Text>
            <div className="input-placeholder mb-3" />
            <InputGroup className="me-2  floor-input">
              <Form.Control
                ref={floorRef}
                aria-label="Place Name"
                type="number"
                step={1}
                min={1}
                value={floor}
                onChange={(e) => {
                  setFloor(e.target.value);
                }}
              />
            </InputGroup>
          </div>
        </div>
      </Card.Body>
    </Form>
  );
}
