import PropTypes from 'prop-types';
import './CopyIconCellRenderer.scss';

const CopyIconCellRenderer = ({ value }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
  };

  return (
    <div className="copy-icon-cell">
      <a
        className="copy-icon btn-light nav-text-icon me-3"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handleCopy();
        }}
      >
        <i className="far fa-lg fa-copy col-1"></i>
      </a>

      <span>{value}</span>
    </div>
  );
};

CopyIconCellRenderer.propTypes = {
  value: PropTypes.string.isRequired,
};

export default CopyIconCellRenderer;
