import { TriggerWorkflowStepProps } from './TriggerWorkflowStepsProps';
import { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Text } from '@components';
import { Api } from '@services';

export function ProximityLocationForm({ trigger, onChange, disabled }: TriggerWorkflowStepProps) {
  const [maps, setMaps] = useState<any>();
  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await Api.get<any>('/maps?top=100');
        setMaps(result.data.items);
      } catch (ex) {}
    };

    fetch();
  }, []);

  return (
    <Form.Group className={'w-100 d-flex flex-row'}>
      <Form.Label className={'w-25'}>
        Proximity <span className="text-danger">*</span>
      </Form.Label>
      <div className={'w-100 d-flex flex-column'}>
        <div className={'w-100 d-flex flex-row  align-items-center'}>
          <Text className={'ms-1 me-1'}>Closer than</Text>
          <Form.Control
            name="closerThan"
            disabled={disabled}
            type="number"
            placeholder=""
            className="closer-than-form"
            value={trigger.CloserThan ?? 0}
            onChange={(e) => onChange(parseFloat(e.target.value), 'CloserThan')}
          />

          <Text className={'ms-1 me-1'}>or farther than</Text>
          <Form.Control
            disabled={disabled}
            name="fartherThan"
            type="number"
            placeholder=""
            className="farther-than-form"
            value={trigger.FartherThan ?? 0}
            onChange={(e) => onChange(parseFloat(e.target.value), 'FartherThan')}
          />

          <Form.Control
            name="units"
            as="select"
            className="w-100"
            disabled={disabled}
            value={trigger.Units ?? 'ft'}
            onChange={(e) => onChange(e.target.value, 'Units')}
          >
            <option id="ft" value="ft">
              ft
            </option>
            <option id="m" value="m">
              m
            </option>
            <option id="mi" value="mi">
              mi
            </option>
            <option id="km" value="km">
              km
            </option>
          </Form.Control>
        </div>
        <div className={'mt-2 w-100 d-flex flex-row  align-items-center'}>
          <Text className={'ms-1 me-1'}>From Coordinates (x,y)</Text>
          <Form.Control
            type={'number'}
            className="from-coords"
            value={trigger.Lat ?? '0'}
            disabled={disabled}
            onChange={(e) => onChange(parseFloat(e.target.value), 'Lat')}
          />
          <Text>&nbsp;,&nbsp; </Text>
          <Form.Control
            type={'number'}
            className="from-coords"
            value={trigger.Lng ?? '0'}
            disabled={disabled}
            onChange={(e) => onChange(parseFloat(e.target.value), 'Lng')}
          />

          <Text className={'ms-1 me-1'}>on Map</Text>
          <Form.Control
            as="select"
            className="map-form"
            disabled={disabled}
            value={trigger.MapId ?? ''}
            onChange={(e) => {
              let value = parseInt(e.target.value);
              if (value > -1) {
                onChange(value, 'MapId');
              }
            }}
          >
            <option id="0" value={-1}>
              --
            </option>
            <option id="1" value={0}>
              Earth (Gps)
            </option>
            {maps &&
              maps.map((m, i) => (
                <option key={i} value={m.id}>
                  {m.name}
                </option>
              ))}
          </Form.Control>
        </div>
      </div>
    </Form.Group>
  );
}
