import './MapThumbnailCellRenderer.scss';
import { useState } from 'react';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

function MapThumbnailCellRenderer({ to, img64, img512 }) {
  const [imgSrc, setImgSrc] = useState(img64);
  const [img256Src, setImg256Src] = useState(img512);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleError = () => {
    setImgSrc('/img/imgError.png');
  };

  const handleHoverError = () => {
    setImg256Src(imgSrc);
  };

  const handleMouseEnter = () => {
    setTooltipVisible(true);
    const image = new Image();
    image.onload = () => {
      setShowTooltip(true);
    };
    image.src = img512; // Preload image in memory
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
    setShowTooltip(false);
  };

  const renderTooltip = (props) => (
    <Popover className="map-img-popover" id="button-tooltip" {...props}>
      <img alt="expanded map" src={img256Src} onError={handleHoverError} />
    </Popover>
  );

  return (
    <div className="thumbnail-container">
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 0 }}
        overlay={renderTooltip}
        show={tooltipVisible && showTooltip}
      >
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Button variant="light" href={to} className="border-0 bg-transparent shadow-none">
            <img
              alt="map thumbnail"
              src={imgSrc}
              className="map-thumbnail-card-img"
              onError={handleError}
            />
          </Button>
        </div>
      </OverlayTrigger>
    </div>
  );
}

export default MapThumbnailCellRenderer;
