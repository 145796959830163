import '../../../scss/comps/Swal.scss';
import '../../../scss/variables.scss';
import './trigger.scss';
import { Icon, Keywords } from '@components';
import { useOnMount } from '@react';
import { Api } from '@services';
import { isAdmin, isReadOnly, isSystemAdmin, isUser } from '@utils';
import classnames from 'classnames';
import { createHashHistory } from 'history';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { TriggerSeverityLevels } from '../../../enums/TriggerSeverityLevels';
import { TriggerTypes } from '../../../enums/TriggerTypes';
import { Trigger } from '../../../models/Trigger';
import { TriggerContact } from '../../../models/TriggerContact';
import { AssetFilter } from '../../AssetFilter/AssetFilter';
import { DockContext } from '../../layout/Dock/Dock';
import { TriggerService } from './../../../services/TriggerService';
import { toast } from './../../utils';
import {
  AbilityForm,
  ContactsForm,
  MovementForm,
  PlaceFormContainer,
  ProximityLocationForm,
  SubscriptionsForm,
} from './index';
import { useCreateTrigger, useDeleteTrigger, useFormValidation } from './trigger.hooks';

const MySwal = withReactContent(Swal);

interface CreateTriggerWorkflowProps {
  triggerId: number;
  onDone(): void;
  setRowSelected: (id: any) => void;
}

function useOutsideAlerter(ref, callBack, isDirty, trigger) {
  useEffect(() => {
    function handleClickOutside(event) {
      const selectedNav = event.target.closest('a');
      const isLink = selectedNav?.className === 'text-dark w-100 d-block';
      if (ref.current && !ref.current.contains(event.target) && !isDirty && !isLink) {
        callBack();
      }
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.className.includes('swal2') &&
        isDirty
      ) {
        MySwal.fire({
          allowOutsideClick: false,
          title: 'Your progress will not be saved.',
          text: 'Are you sure you want to leave?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then((submit) => {
          if (submit.isConfirmed) {
            callBack();
            if (selectedNav) {
              let urlPath = selectedNav.attributes.href.value;
              if (urlPath.includes('triggers')) {
                window.history.replaceState(null, null, urlPath);
              } else {
                window.history.pushState(null, null, urlPath);
                let history = createHashHistory();
                history.push(null);
              }
            }
          } else {
            MySwal.close();
          }
        });
      }
    }
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [trigger, isDirty]);
}

export function CreateTriggerWorkflow({
  onDone,
  triggerId,
  setRowSelected,
}: CreateTriggerWorkflowProps) {
  //useState Hooks
  const [trigger, setTrigger] = useState<any>(new Trigger());
  const [initFilter, setInitFilter] = useState<string>(null);
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [isDirty, setIsDirty] = useState<boolean>(null);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isAddingKeyword, setIsAddingKeyword] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(false);
  const [assetCount, setAssetCount] = useState<number>(null);
  const nameInputRef = useRef(null);
  const wrapperRef = useContext(DockContext);
  const triggerService = new TriggerService();
  const createTrigger = useCreateTrigger({ triggerService });
  const deleteTrigger = useDeleteTrigger({ triggerService });
  const [
    conditionError,
    hasName,
    hasType,
    hasCondition,
    hasRisesAbove,
    hasFallsBelow,
    hasEntryExit,
    hasMap,
    hasPlace,
    hasAssetFilter,
    hasFilter,
    isValid,
  ] = useFormValidation({ trigger });
  const [isAutoComplete, setIsAutoComplete] = useState('off');
  const authorized = isAdmin() || isSystemAdmin();
  const renderTooltip = (tip) => <Tooltip id="button-tooltip">{tip}</Tooltip>;

  useOutsideAlerter(wrapperRef, onDone, isDirty, trigger);

  useOnMount(() => {
    nameInputRef.current?.focus();
    setIsDirty(false);
    if (!triggerId) setInit(true);
  });

  useEffect(() => {
    trigger.Id !== 0 ? setIsHidden(false) : setIsHidden(true);
  }, [trigger.Id]);

  useEffect(() => {
    trigger.Id !== 0 ? setIsHidden(false) : setIsHidden(true);
  }, [trigger.Id]);

  useEffect(() => {
    let isLoaded = true;
    if (Boolean(triggerId > 0)) {
      Api.get(`bulktriggers/${triggerId}`)
        .then((data: any) => {
          if (data?.data?.name) {
            const obj: any = {};
            const existingBulkTrigger = data.data;
            Object.keys(existingBulkTrigger).forEach((btKey) => {
              obj[btKey.charAt(0).toUpperCase() + btKey.slice(1)] = existingBulkTrigger[btKey];
            });
            if (isLoaded) {
              setTrigger(obj);
              setInitFilter(obj.Filter);
            }
          }
        })
        .catch((e) => {
          toast.error('This Trigger does not exist.');
          onDone();
        });
    }

    return () => {
      isLoaded = false;
    };
  }, [triggerId]);

  useEffect(() => {
    let isLoaded = true;
    if (trigger.Filter == 'all') {
      Api.get('beacons')
        .then((data: any) => {
          if (isLoaded) {
            setAssetCount(data.data.count);
          }
        })
        .catch((e) => {
          toast.error('Could not fetch asset list1.');
          onDone();
        });
    }

    return () => {
      isLoaded = false;
    };
  }, [trigger.Filter]);

  useEffect(() => {
    if (trigger.AutoResolve === false) {
      setTrigger({
        ...trigger,
        ResolveDurationValue: 0,
        ResolveDurationUnit: 1,
      });
    }
    if (trigger.AutoResolve === true) {
      setTrigger({
        ...trigger,
        ResolveDurationValue: 1,
        ResolveDurationUnit: 1,
      });
    }
  }, [trigger.AutoResolve]);

  const handleChange = (key, value, extra?) => {
    const change = { [key]: value, ...extra };
    setTrigger({
      ...trigger,
      ...change,
    });
    setIsDirty(true);
  };

  if (createTrigger.isError) return <Alert variant="danger">Failed to create Trigger.</Alert>;
  if (createTrigger.isSuccess) onDone();

  if (deleteTrigger.isError) return <Alert variant="danger">Failed to delete Trigger.</Alert>;
  if (deleteTrigger.isSuccess) onDone();

  return (
    <div className="h-100 w-100">
      <div className="d-flex flex-column">
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2" htmlFor="name">
            Name <span className="text-danger">*</span>
          </Form.Label>
          <Col>
            <Form.Control
              id="name"
              isInvalid={!hasName && isSubmitted}
              isValid={hasName && isSubmitted}
              ref={nameInputRef}
              name="name"
              type="text"
              disabled={isReadOnly()}
              placeholder="Trigger Name"
              value={trigger.Name ?? ''}
              onFocus={() => setIsAutoComplete('on')}
              autoComplete={isAutoComplete}
              onChange={(e) => handleChange('Name', e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              * Please enter a Trigger Name
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2" htmlFor="description">
            Description
          </Form.Label>
          <Col>
            <Form.Control
              name="description"
              type="text"
              as="textarea"
              placeholder="Description"
              disabled={isReadOnly()}
              value={trigger.Description ?? ''}
              onChange={(e) => handleChange('Description', e.target.value)}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <OverlayTrigger
            placement="left-start"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip(
              'This is a user defined label for Severity and will not affect Trigger Performance.',
            )}
          >
            <span className="d-flex">
              <Form.Label column sm="2" htmlFor="severity">
                Severity
              </Form.Label>

              <Col>
                <Form.Select
                  id="severity"
                  as={'select'}
                  disabled={isReadOnly()}
                  value={trigger.Severity}
                  onChange={(e) => {
                    let value = parseInt(e.target.value);
                    if (value > -1) {
                      handleChange('Severity', value as TriggerSeverityLevels);
                    }
                  }}
                >
                  <option id={'positive'} value={TriggerSeverityLevels.Positive}>
                    Positive
                  </option>
                  <option id={'mild'} value={TriggerSeverityLevels.Mild}>
                    Mild
                  </option>
                  <option id={'moderate'} value={TriggerSeverityLevels.Moderate}>
                    Moderate
                  </option>
                  <option id={'severe'} value={TriggerSeverityLevels.Severe}>
                    Severe
                  </option>
                </Form.Select>
              </Col>
            </span>
          </OverlayTrigger>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">
            Add Keywords
          </Form.Label>
          <Col className="d-inline-flex align-items-center">
            {!isReadOnly() && (
              <Icon
                name="add"
                variant="light"
                size="sm"
                className={classnames('sonarIcon-circle', {
                  'sonarIcon-rotate-45': isAddingKeyword,
                })}
                showPointer={true}
                disabled={isReadOnly()}
                onClick={() => setIsAddingKeyword((prev) => !prev)}
              />
            )}
            <Keywords
              initKeywords={trigger.Keywords}
              isAddingKeyword={isAddingKeyword}
              onChange={(keywords) => {
                handleChange('Keywords', keywords);
              }}
              EmptyComp={() => <div />}
              disabled={isReadOnly()}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2" htmlFor="triggerType" className="pe-0">
            Trigger Type <span className="text-danger">*</span>
          </Form.Label>
          <Col>
            <Form.Select
              id="triggerType"
              isInvalid={!hasType && isSubmitted}
              isValid={hasType && isSubmitted}
              as={'select'}
              disabled={isReadOnly()}
              value={trigger.TriggerType ?? -1}
              onChange={(e) => {
                let value = parseInt(e.target.value);
                if (value > -1) {
                  setInit(true);
                  handleChange('TriggerType', value as TriggerTypes);
                }
              }}
            >
              <option disabled id={'-1'} value={'-1'}>
                -- Select Trigger Type --
              </option>
              <option id={'0'} value={TriggerTypes.Ability}>
                Ability
              </option>
              {/* <option id={"1"} value={TriggerTypes.Movement}>Movement</option> */}
              {/* <option id={"2"} value={TriggerTypes.ProximityLocation}>Proximity To Location</option> */}
              <option id={'3'} value={TriggerTypes.EntryExit}>
                Entry/Exit
              </option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              * Please select a Trigger Type
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        {trigger.TriggerType === TriggerTypes.Ability && (
          <AbilityForm
            isSubmitted={isSubmitted}
            disabled={isReadOnly()}
            trigger={trigger}
            onChange={(e, f) => {
              setInit(true);
              handleChange(f, e);
            }}
          />
        )}
        {trigger.TriggerType === TriggerTypes.ProximityLocation && (
          <ProximityLocationForm
            isSubmitted={isSubmitted}
            disabled={isReadOnly()}
            trigger={trigger}
            onChange={(e, f) => {
              setInit(true);
              handleChange(f, e);
            }}
          />
        )}
        {trigger.TriggerType === TriggerTypes.Movement && (
          <MovementForm
            isSubmitted={isSubmitted}
            disabled={isReadOnly()}
            trigger={trigger}
            onChange={(e, f) => {
              setInit(true);
              handleChange(f, e);
            }}
          />
        )}
        {trigger.TriggerType === TriggerTypes.EntryExit && (
          <PlaceFormContainer
            trigger={trigger}
            isSubmitted={isSubmitted}
            mapId={trigger.MapId}
            placeId={trigger.PlaceId}
            triggerEnters={trigger.Enters}
            triggerExits={trigger.Exits}
            disabled={isReadOnly()}
            onChange={(e, f, extra?) => {
              setInit(true);
              handleChange(f, e, extra);
            }}
          />
        )}
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2" htmlFor="duration">
            Duration
          </Form.Label>
          <Col sm="6">
            <InputGroup className="h-0">
              <Form.Control
                id="duration"
                type="number"
                disabled={isReadOnly()}
                name="metDuration"
                value={trigger.MetDurationValue ?? 0}
                onChange={(e) => {
                  handleChange('MetDurationValue', e.target.value);
                }}
              />
              <Form.Select
                name="metDurationUnit"
                disabled={isReadOnly()}
                as="select"
                value={trigger.MetDurationUnit ?? 1}
                onChange={(e) => {
                  handleChange('MetDurationUnit', parseInt(e.target.value));
                }}
              >
                <option id={'0'} key={'seconds'} value={0}>
                  Seconds
                </option>
                <option id={'1'} key={'minutes'} value={1}>
                  Minutes
                </option>
                <option id={'2'} key={'hours'} value={2}>
                  Hours
                </option>
                <option id={'3'} key={'days'} value={3}>
                  Days
                </option>
              </Form.Select>
            </InputGroup>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2" htmlFor="AssetFilter" className="pe-0">
            Auto Resolve
          </Form.Label>
          <Col>
            <InputGroup>
              <Form.Control
                type="number"
                disabled={isReadOnly() || !trigger.AutoResolve}
                id="resolveDuration"
                name="resolveDuration"
                value={trigger.ResolveDurationValue ?? 0}
                onChange={(e) => {
                  let value = parseInt(e.target.value);
                  if (value <= 0) {
                    value = 1;
                  }
                  handleChange('ResolveDurationValue', value);
                }}
              />
              <Form.Select
                name="resolveDurationUnits"
                as="select"
                disabled={isReadOnly() || !trigger.AutoResolve}
                value={trigger.ResolveDurationUnit ?? 1}
                onChange={(e) => {
                  handleChange('ResolveDurationUnit', parseInt(e.target.value));
                }}
              >
                <option id={'0'} key={'seconds'} value={0}>
                  Seconds
                </option>
                <option id={'1'} key={'minutes'} value={1}>
                  Minutes
                </option>
                <option id={'2'} key={'hours'} value={2}>
                  Hours
                </option>
                <option id={'3'} key={'days'} value={3}>
                  Days
                </option>
              </Form.Select>
            </InputGroup>
          </Col>
          <Col sm="4">
            <InputGroup className="my-2">
              <Form.Check
                type="checkbox"
                disabled={isReadOnly()}
                id="enableAutoResolve"
                name="enableAutoResolve"
                label="Enable Auto Resolve"
                checked={trigger.AutoResolve || false}
                onChange={() => {
                  handleChange('AutoResolve', !trigger.AutoResolve);
                }}
              />
            </InputGroup>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2" htmlFor="resolveDuration">
            Asset Filter <span className="text-danger">*</span>
          </Form.Label>
          <Col>
            <AssetFilter
              assetFilterIsValid={hasAssetFilter && isSubmitted}
              assetFilterIsInvalid={!hasAssetFilter && isSubmitted}
              filterIsValid={hasFilter && isSubmitted}
              filterIsInvalid={!hasFilter && isSubmitted}
              initFilter={initFilter || null}
              disabled={isReadOnly()}
              disabledFilters={
                authorized
                  ? ['map', 'place', 'device id', 'active', 'battery value']
                  : ['all', 'map', 'place', 'device id', 'active']
              }
              location={'bulkTrigger'}
              onChange={(filter: string) => {
                handleChange('Filter', filter);
              }}
            />
          </Col>
        </Form.Group>
        <ContactsForm
          isSubmitted={isSubmitted}
          onAddContact={(e) => {}}
          trigger={trigger}
          disabled={isReadOnly()}
          onChange={(e: TriggerContact[]) => {
            handleChange('Contacts', e);
          }}
        />
        <SubscriptionsForm
          isSubmitted={isSubmitted}
          disabled={isReadOnly()}
          trigger={trigger}
          onChange={(e: string[]) => {
            handleChange('Webhooks', e);
          }}
        />
        {!isReadOnly() && (
          <div className="d-flex">
            <Button
              variant="primary"
              className="mt-3 mx-auto submit-trigger-workflow"
              disabled={isValid === false}
              onClick={async () => {
                setIsSubmitted(true);
                if (isValid === true) {
                  if (trigger.Filter === 'all') {
                    // Show the SweetAlert with a loading message initially
                    MySwal.fire({
                      allowOutsideClick: false,
                      title: 'Please wait...',
                      text: 'Fetching Beacon count...',
                      icon: 'info',
                      showCancelButton: true,
                      cancelButtonText: 'No',
                      confirmButtonText: 'Yes',
                      didOpen: () => {
                        // Fetch the asset count
                        Api.get('beacons')
                          .then((data: any) => {
                            const count = data.data.count; // Update asset count
                            setAssetCount(count); // Update the state

                            // Update the SweetAlert with the actual asset count
                            MySwal.update({
                              title: 'Are you sure?',
                              text: `By creating this Trigger, it will alert all Beacons in this environment. That could be up to ${count} Beacons. Are you sure you want to continue?`,
                              icon: 'warning',
                            });
                          })
                          .catch((e) => {
                            toast.error('Could not fetch asset count.');
                          });
                      },
                    }).then((submit) => {
                      if (submit.isConfirmed) {
                        toast.success('Trigger submitted!');
                        createTrigger.initiateCreateTriggerMutation
                          .mutateAsync({
                            trigger: trigger,
                          })
                          .then(() => {
                            refreshTriggerTable();
                          });
                      }
                    });
                  } else {
                    toast.success('Trigger submitted!');
                    createTrigger.initiateCreateTriggerMutation
                      .mutateAsync({
                        trigger: trigger,
                      })
                      .then(() => {
                        refreshTriggerTable();
                      });
                  }
                }
              }}
            >
              {createTrigger.isLoading ? 'Saving...' : 'Submit'}
            </Button>
            <Button
              className="mt-3 mx-auto delete-trigger-workflow"
              variant={'danger'}
              disabled={isValid !== true}
              hidden={isReadOnly() || isUser() || isHidden}
              onClick={() => {
                MySwal.fire({
                  allowOutsideClick: false,
                  title: 'Are you sure?',
                  text: 'This Trigger will be permanently deleted.',
                  icon: 'warning',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  confirmButtonText: 'Yes',
                }).then(async (submit) => {
                  if (submit.isConfirmed) {
                    toast.success('Trigger successfully deleted!');
                    const data = await deleteTrigger.initiateDeleteTriggerMutation.mutateAsync({
                      trigger: trigger,
                    });
                    setRowSelected({ id: data.trigger.Id });
                  }
                });
              }}
            >
              Delete
            </Button>
          </div>
        )}

        {isReadOnly() && (
          <em className="m-auto mt-3">You do not have permission to edit this trigger</em>
        )}
        {createTrigger.isSuccess && <Alert variant="success">Trigger created successfully!</Alert>}
      </div>
    </div>
  );
}

function refreshTriggerTable() {
  setTimeout(() => {
    if (window.location.pathname.includes('/triggers')) {
      window.location.pathname = '/triggers';
    }
  }, 2000);
}
