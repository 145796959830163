import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { EditDeleteCellRenderer, toast, UserProfileCellRenderer } from '../../components';
import { store } from '../../redux';
import { Api } from '../../services';
import { formatPhoneNumber, getFormattedBool, getFormattedDateTime } from '../../utils';
import { ROLES } from './utils';

const MySwal = withReactContent(Swal);

const customComparator = (valueA, valueB) => {
  const nullOrPlaceholder = (value) => value === '--' || value === null || value === '';
  const isValidPhoneNumber = (value) => {
    // Your phone number validation logic here
    // Return true for valid phone numbers and false otherwise
    return /^(\+\d{1,2})?\(?\d{3}\)?-?\d{3}-?\d{4}$/.test(value);
  };

  if (nullOrPlaceholder(valueA) && nullOrPlaceholder(valueB)) {
    return 0; // Both values are null or placeholders ('--')
  } else if (nullOrPlaceholder(valueA)) {
    return -1; // Placeholders or null values should be pushed to the top
  } else if (nullOrPlaceholder(valueB)) {
    return 1; // Placeholders or null values should be pushed to the top
  } else if (!isValidPhoneNumber(valueA) && !isValidPhoneNumber(valueB)) {
    return 0; // Both values are invalid phone numbers
  } else if (!isValidPhoneNumber(valueA)) {
    return -1; // Invalid phone numbers should be sorted to the top
  } else if (!isValidPhoneNumber(valueB)) {
    return 1; // Invalid phone numbers should be sorted to the top
  } else if (!isNaN(valueA) && !isNaN(valueB)) {
    // If both values are numbers, sort numerically
    return Number(valueA) - Number(valueB);
  } else if (isNaN(valueA) && isNaN(valueB)) {
    // If both values are strings, sort alphabetically
    return valueA.localeCompare(valueB);
  } else {
    // Sort based on types, numbers first
    return isNaN(valueA) ? 1 : -1;
  }
};

export const userMgmtColumnDefs = (onDeleteSuccess) => [
  {
    headerName: 'Email',
    field: 'userName',
    suppressMovable: true,
    cellRenderer: UserProfileCellRenderer,
    cellRendererParams(params) {
      const { data, api, node }: { data: IAcctMgmtData; api: any; node: any } = params;
      const lockOutExpireDate = getFormattedDateTime(data.lockoutEnd);
      const phoneNumber = formatPhoneNumber(data.phoneNumber);
      const confirmedEmail = getFormattedBool(data.emailConfirmed);
      return {
        data,
        lockOutExpireDate,
        phoneNumber,
        confirmedEmail,
        api,
        node,
        onClickEdit() {
          let rowIndex = 0;
          api.forEachNodeAfterFilterAndSort((node, nodeIndex) => {
            if (node.data.userId === data.userId) rowIndex = nodeIndex;
          });
          api.startEditingCell({ colKey: 'role', rowIndex: rowIndex });
        },
        onClickCancelEdit() {
          api.stopEditing(true);
        },
        async onClickDelete() {
          let userResponsePromise = new Promise((resolve, reject) => {
            let textContent = document.createElement('div');

            if (data.userName.length >= 30) {
              textContent.innerHTML = `<b><div class="user-name" style="font-size: 20px;">${data.userName}?</div>Are you sure you want to delete this user?</b>`;
            } else {
              textContent.innerHTML = `<b><div class="user-name" style="font-size: 27px;">${data.userName}?</div>Are you sure you want to delete this user?</b>`;
            }

            MySwal.fire({
              allowOutsideClick: false,
              title: 'Are you sure?',
              text: 'This user will be deleted.',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'No',
              confirmButtonText: 'Yes',
            })
              .then((submit) => {
                resolve(submit.isConfirmed);
              })
              .catch((err) => {
                reject(err);
              });
          });

          let userResponse;

          try {
            userResponse = await userResponsePromise;
          } catch (error) {
            userResponse = false;
            console.log(error);
          }

          if (userResponse) {
            try {
              await Api.delete(`/users/${data.userId}`);
              api.gridBodyCtrl.gridOptionsService.gridOptions.rowData.splice(node.rowIndex, 1);
              api.setRowData(api.gridBodyCtrl.gridOptionsService.gridOptions.rowData);
              toast.success(`Successfully deleted User ${data.userName}!`);
            } catch (error) {
              toast.error(`Failed to delete User ${data.userName}.`);
            }
          }
        },
        async onClickConfirmEdit() {
          try {
            const model = {
              sonarUserId: data.userId,
              role: data.userRole,
            };
            await Api.post('/users/assign-role', model);
            toast.success(`Successfully updated User ${data.userName}!`);
          } catch (error) {
            toast.error(`Failed to update User ${data.userName}.`);
          }
        },
      };
    },
  },
  {
    headerName: 'Email Confirmed',
    field: 'emailConfirmed',
    cellRenderer: (params) => {
      return params.value === true ? 'Yes' : 'No';
    },
  },
  {
    headerName: 'Phone',
    field: 'phoneNumber',
    valueFormatter: ({ value }) => formatPhoneNumber(value),
  },
  {
    headerName: 'Lock Out Expiration',
    field: 'lockoutEnd',
    valueFormatter: ({ value }) => getFormattedDateTime(value),
  },
  {
    headerName: 'Role',
    field: 'userRole',
    colId: 'role',
    cellEditor: 'agSelectCellEditor',
    cellEditorParams: {
      values: ROLES,
    },
    editable(params) {
      const { node } = params;
      const { editDeleteCR } = store.getState()?.grids || {};
      return node.data.userId === editDeleteCR.id;
    },
  },
  {
    headerName: 'Edit/Delete',
    colId: 'editDelete',
    suppressMovable: true,
    sortable: false,
    width: 100,
    cellRenderer: EditDeleteCellRenderer,
    cellRendererParams(params) {
      const { data, api, node } = params;
      const { userId: id } = data;
      return {
        id,
        onClickEdit() {
          let rowIndex = 0;
          api.forEachNodeAfterFilterAndSort((node, nodeIndex) => {
            if (node.data.userId === id) rowIndex = nodeIndex;
          });
          api.startEditingCell({ colKey: 'role', rowIndex: rowIndex });
        },
        onClickCancelEdit() {
          api.stopEditing(true);
        },
        async onClickDelete() {
          let userResponsePromise = new Promise((resolve, reject) => {
            MySwal.fire({
              allowOutsideClick: false,
              title: 'Are you sure?',
              text: 'This user will be deleted.',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'No',
              confirmButtonText: 'Yes',
            })
              .then((submit) => {
                resolve(submit.isConfirmed);
              })
              .catch((err) => {
                reject(err);
              });
          });

          let userResponse;

          try {
            userResponse = await userResponsePromise;
          } catch (error) {
            userResponse = false;
            console.log(error);
          }

          if (userResponse) {
            try {
              await Api.delete(`/users/${data.userId}`);
              api.gridBodyCtrl.gridOptionsService.gridOptions.rowData.splice(node.rowIndex, 1);
              api.setRowData(api.gridBodyCtrl.gridOptionsService.gridOptions.rowData);
              toast.success(`Successfully deleted User ${data.userName}!`);
              onDeleteSuccess();
            } catch (error) {
              toast.error(`Failed to delete User ${data.userName}.`);
            }
          }
        },
        async onClickConfirmEdit() {
          try {
            const model = {
              sonarUserId: data.userId,
              role: data.userRole,
            };
            await Api.post('/users/assign-role', model);
            toast.success(`Successfully updated User ${data.userName}!`);
          } catch (error) {
            toast.error(`Failed to update User ${data.userName}.`);
          }
        },
      };
    },
  },
];

/**
 * @param id - The id assigned by EF to each row. Note EF was rm for this Dto
 * @param emailConfirmed - Boolean if user comfirmed email
 * @param lockoutEnd - The date that the user acct will stop being locked. Ex "2023-03-20T10:00:41.380264-05:00"
 * @param phoneNumber - The users phone number
 * @param userId - The users unquie user Id
 * @param userName -The user email address
 * @param userRole - The users authorization. Ex. Admin, user
 */
export interface IAcctMgmtData {
  id: number | null;
  emailConfirmed: boolean;
  lockoutEnd: string | null;
  phoneNumber: string | null;
  userId: number | null;
  userName: string | null;
  userRole: string | null;
}
