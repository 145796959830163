import './trigger.scss';
import React, { useEffect, useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { AbilityTriggerTypes } from '../../../enums/AbilityTriggerTypes';
import { Abilities } from '../../../models/Ability';
import { useFormValidation } from './trigger.hooks';
import { TriggerWorkflowStepProps } from './TriggerWorkflowStepsProps';

export function AbilityForm({
  isSubmitted,
  trigger,
  onChange,
  disabled,
}: TriggerWorkflowStepProps) {
  //useState Hooks
  const [fallsBelow, setFallsBelow] = useState<any>(trigger.FallsBelow);
  const [risesAbove, setRisesAbove] = useState<any>(trigger.RisesAbove);
  const [
    conditionError,
    hasName,
    hasType,
    hasCondition,
    hasRisesAbove,
    hasFallsBelow,
    hasEntryExit,
    hasMap,
    hasPlace,
    hasFilter,
    isValid,
  ] = useFormValidation({ trigger });

  //useEffect Hooks
  useEffect(() => {
    setFallsBelow(trigger.FallsBelow);
    setRisesAbove(trigger.RisesAbove);
  }, [trigger.RisesAbove, trigger.FallsBelow]);

  function ShowFallsBelow(): boolean {
    let a = Abilities.find((a) => a.id === trigger.AbilityId);
    return (
      trigger.AbilityId > 0 &&
      (a?.triggerType === AbilityTriggerTypes.FallsBelow ||
        a?.triggerType === AbilityTriggerTypes.Both)
    );
  }

  function ShowRisesAbove(): boolean {
    let a = Abilities.find((a) => a.id === trigger.AbilityId);
    return (
      trigger.AbilityId > 0 &&
      (a?.triggerType === AbilityTriggerTypes.RisesAbove ||
        a?.triggerType === AbilityTriggerTypes.Both)
    );
  }

  function ShowOr(): boolean {
    let a = Abilities.find((a) => a.id === trigger.AbilityId);
    return trigger.AbilityId > 0 && a?.triggerType === AbilityTriggerTypes.Both;
  }

  function ShowUnits(): boolean {
    let a = Abilities.find((a) => a.id === trigger.AbilityId);
    trigger.Units = trigger?.Units || GetUnits()?.[0].key;
    return trigger.AbilityId > 0 && a?.units.length > 1;
  }

  function GetUnits(): any[] {
    let a = Abilities.find((a) => a.id === trigger.AbilityId);
    return a?.units;
  }

  const handleChange = (value: any, type: any) => {
    if (value !== '-') {
      if (value === '') {
        onChange(null, type);
      } else {
        onChange(value, type);
      }
    }
  };

  const requiresRoundedClass = (): boolean => {
    let a = Abilities.find((a) => a.id === trigger.AbilityId);
    const abilityName = a?.name?.toLowerCase();
    return ['temperature', 'pressure', 'frequency'].includes(abilityName);
  };

  return (
    <React.Fragment>
      <Form.Group as={Row} className="mb-3">
        <Form.Label column sm="2" htmlFor="condition" className="pe-0">
          Condition <span className="text-danger">*</span>
        </Form.Label>
        <Col>
          <Form.Select
            id="condition"
            isInvalid={!hasCondition && isSubmitted}
            isValid={hasCondition && isSubmitted}
            as={'select'}
            disabled={disabled}
            value={trigger.AbilityId || -1}
            onChange={(e) => {
              trigger.Units = undefined;
              const value = parseFloat(e.target.value);
              if (value > -1) {
                onChange(value, 'AbilityId');
              }
            }}
          >
            <option disabled value={'-1'}>
              -- Select Ability --
            </option>
            {Abilities.filter((a) => a.canTrigger === true).map((a) => {
              return (
                <option key={a.id} value={a.id}>
                  {a.name}
                </option>
              );
            })}
          </Form.Select>
          <Form.Control.Feedback type="invalid">* Please select a Condition</Form.Control.Feedback>
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3 flex-nowrap">
        {(ShowFallsBelow() || ShowRisesAbove()) && (
          <Col sm="2">
            <Form.Label column htmlFor="condition">
              Bounds <span className="text-danger">*</span>
            </Form.Label>
          </Col>
        )}
        <Col sm="10">
          <InputGroup className="d-flex flex-nowrap justify-content-between">
            {ShowFallsBelow() && (
              <Col sm="4" className="d-flex col-sm-4">
                <InputGroup.Text id="falls-below" className="p-auto">
                  Falls Below
                </InputGroup.Text>
                <Form.Control
                  isInvalid={!hasFallsBelow && isSubmitted}
                  isValid={hasFallsBelow && isSubmitted}
                  aria-label="Number value (units will be appended based on ability type)"
                  aria-describedby="falls-below"
                  type="text"
                  className="falls-below-input"
                  disabled={disabled}
                  value={fallsBelow ?? ''}
                  onChange={(e) => {
                    setFallsBelow(e.target.value);
                    handleChange(e.target.value, 'FallsBelow');
                  }}
                />
                <Form.Control.Feedback type="invalid">{conditionError}</Form.Control.Feedback>
              </Col>
            )}
            {ShowOr() && (
              <Col sm="1" className="d-flex">
                <InputGroup.Text>OR</InputGroup.Text>
              </Col>
            )}
            <Col sm="5" className="d-flex col-sm-6 col-lg-5">
              {ShowRisesAbove() && (
                <React.Fragment>
                  <InputGroup.Text id="rises-above" className="p-auto">
                    Rises Above
                  </InputGroup.Text>
                  <Form.Control
                    className={`p-1 rises-above-input ${requiresRoundedClass() ? 'rounded-0' : ''}`}
                    isInvalid={!hasRisesAbove && isSubmitted}
                    isValid={hasRisesAbove && isSubmitted}
                    aria-label="Number value (units will be appended based on ability type)"
                    aria-describedby="rises-above"
                    type="text"
                    disabled={disabled}
                    value={risesAbove ?? ''}
                    onChange={(e) => {
                      setRisesAbove(e.target.value);
                      handleChange(e.target.value, 'RisesAbove');
                    }}
                  />
                  <Form.Control.Feedback type="invalid">{conditionError}</Form.Control.Feedback>
                </React.Fragment>
              )}
              {ShowUnits() && (
                <Form.Control
                  as="select"
                  disabled={disabled}
                  className="trigger-unit-form text-center"
                  value={trigger.Units || GetUnits()[0].key}
                  onChange={(e) => {
                    onChange(e.target.value, 'Units');
                  }}
                >
                  {GetUnits().map((u) => {
                    return (
                      <option key={u.key} value={u.key}>
                        {u.text}
                      </option>
                    );
                  })}
                </Form.Control>
              )}
            </Col>
          </InputGroup>
        </Col>
      </Form.Group>
    </React.Fragment>
  );
}
