import { EditDeleteCellRenderer } from '@components';
import { PagedQueryParams, ServerSideGrid } from '@components/grids/AgGrid/ServerSideGrid';
import { CustomApi } from '@services';
import Axios from 'axios';
import { useState } from 'react';
import { Button, Card, Col, Modal, ModalBody, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { PagedModel } from '../../models/PagedModel';
import { adminAppColumnDefs } from './columnDefs';
import CreateAppModal from './CreateAppModal';

function AdminPortalApps() {
  const [rowData, setRowData] = useState(new PagedModel());
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const dispatch = useDispatch();

  const id = 'AdminPortalApp';
  const cancelToken = CustomApi.CancelToken;
  let fetchDataCancel;

  const onClickCreateApp = () => {
    setModal(!modal);
  };

  const fetchData = async (payload, csvExport, queryState) => {
    if (fetchDataCancel !== null && typeof fetchDataCancel === 'function') {
      fetchDataCancel();
      fetchDataCancel = null;
    }
    setIsLoading(true);
    CustomApi.get(
      `api/admin/applications?top=${queryState.pageSize}&skip=${queryState.skip()}&filter=${
        queryState.filter
      }&orderBy=${queryState.orderBy()}`,
      {
        cancelToken: new cancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      },
    )
      .then((data) => {
        let resp = data.data;
        setRowData(resp);
        setIsLoading(false);
      })
      .catch((e) => {
        if (!Axios.isCancel(e)) {
          setIsLoading(false);
        }
      });
  };

  const columns = adminAppColumnDefs(dispatch);

  return (
    <Card className="h-100">
      <Card.Body>
        <Row className="h-100">
          <Col className="d-flex flex-column h-100">
            <Modal centered show={modal} onHide={() => setModal(false)}>
              <ModalBody>
                <CreateAppModal
                  onClose={() => setModal(false)}
                  setTriggerUpdate={setTriggerUpdate}
                />
              </ModalBody>
            </Modal>
            <Row>
              <Col>
                <div className="d-flex flex-row justify-content-end">
                  <Button onClick={onClickCreateApp} variant="secondary" disabled={isLoading}>
                    Create Application
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className="h-100">
              <Col className="h-100">
                <ServerSideGrid
                  id={id}
                  columnDefs={columns}
                  rowData={rowData}
                  isLoading={isLoading}
                  disabledFilters={[]}
                  filterControl={false}
                  searchControl={true}
                  viewingControl={true}
                  pagerControl={true}
                  exportControl={false}
                  initQueryParams={new PagedQueryParams(id)}
                  handleFetchData={fetchData}
                  triggerUpdate={triggerUpdate}
                  gridProps={{
                    frameworkComponents: {
                      EditDeleteCellRenderer: EditDeleteCellRenderer,
                    },
                    suppressClickEdit: true,
                    editType: 'fullRow',
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default AdminPortalApps;
