import classNames from 'classnames';
import '../../../scss/variables.scss';
import './MapTourStartMenu.scss';
import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Text } from '../../../components';

export function MapTourStartMenu() {
  const [showInfo, setShowInfo] = useState<boolean>(true);
  const [hasInteracted, setHasInteracted] = useState<boolean>(false); // Track if the user has interacted

  let infoClasses = classNames({
    'info-container-tour-map': !showInfo,
    'info-container-hide-tour-map': showInfo,
  });

  let collapseClasses = classNames({
    'collapse-button-tour-map': showInfo,
    'collapse-button-hide-tour-map': !showInfo,
  });

  // Function to reload the page and start the tour
  const startTour = () => {
    // Set local storage flag to start the tour
    localStorage.removeItem('mapTourVersion');
    window.location.reload(); // Reload the page to restart the tour
  };

  return (
    <>
      <div className={collapseClasses}>
        <Card>
          <Button
            variant="dark"
            type="submit"
            className="collapse-card-tour-map"
            onClick={() => {
              setHasInteracted(true); // Mark interaction and start animations
              setShowInfo(!showInfo);
            }}
          >
            <i className={'sonarIcon sonarIcon-info fs-lg'} />
          </Button>
        </Card>
      </div>

      {hasInteracted && ( // Only show after the user interacts
        <div className={infoClasses}>
          <Card className="d-flex flex-row">
            <div className="collapse-container-tour-map">
              <Button
                variant="dark"
                type="submit"
                className="collapse-card-tour-map"
                onClick={() => setShowInfo(!showInfo)}
              >
                <i className={'sonarIcon sonarIcon-info fs-lg'} />
              </Button>
            </div>
            <div className="menu-bar-tour-map">
              <Text
                className="fs-md mb-3 fw-bold cursor-pointer tour-start-button"
                onClick={startTour}
              >
                Tour New Maps
              </Text>
            </div>
          </Card>
        </div>
      )}
    </>
  );
}
