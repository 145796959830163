import './AdvancedAsset.scss';
import React from 'react';
import { Button, ButtonGroup, Dropdown, Form } from 'react-bootstrap';

const CheckboxMenu = React.forwardRef(
  ({ children, className, 'aria-labelledby': labeledBy, onSelectAll, selectTitle }, ref) => {
    return (
      <div ref={ref} className={`${className} CheckboxMenu`} aria-labelledby={labeledBy}>
        {labeledBy !== 'Hours' && (
          <div className="dropdown-item text-center border-top pt-2 pb-0">
            <ButtonGroup size="sm">
              <Button variant="link" onClick={onSelectAll}>
                {selectTitle()}
              </Button>
            </ButtonGroup>
          </div>
        )}
        <div className="d-flex flex-column drop-down-item-inner">
          <ul className="list-unstyled flex-shrink mb-0">{children}</ul>
        </div>
      </div>
    );
  },
);

// Checkbox for Beacons & Ability
const CheckDropdownItem = React.forwardRef(({ children, id, checked, onChange }, ref) => {
  return (
    <Form.Group ref={ref} className="dropdown-item mb-0" controlId={id}>
      <Form.Check
        type="checkbox"
        label={children}
        checked={checked}
        onChange={onChange && onChange.bind(onChange, id)}
      />
    </Form.Group>
  );
});

// Radio Button for Hours
const RadioDropdownItem = React.forwardRef(({ children, id, checked, onChange }, ref) => {
  return (
    <Form.Label ref={ref} className="dropdown-item mb-0" controlid={id}>
      <Form.Check
        type="radio"
        label={children}
        checked={checked}
        onChange={onChange && onChange.bind(onChange, id)}
      />
    </Form.Label>
  );
});

const CheckboxDropdown = ({ title, items, handleSelect }) => {
  let selectAll = true;

  const handleChecked = (e) => {
    handleSelect(e);
  };

  const handleSelectAll = () => {
    let trueFalseArr = [];
    for (let i = 0; i < items.length; i++) {
      trueFalseArr.push(items[i].selected);
    }

    // If every Beacon/Ability is selected Select All toggles to Deselect All
    if (trueFalseArr.every((ar) => ar === true)) {
      items.forEach((i) => ((i.selected = false), handleSelect(i.id, selectAll)));
    } else {
      items.forEach((i) => ((i.selected = true), handleSelect(i.id, selectAll)));
    }
  };

  // Select All/Deselect All Text Logic
  const selectTitle = () => {
    let trueFalseArr = [];
    for (let i = 0; i < items.length; i++) {
      trueFalseArr.push(items[i].selected);
    }

    if (trueFalseArr.every((ar) => ar === true)) {
      return <div>Deselect All</div>;
    } else {
      return <div>Select All</div>;
    }
  };

  return (
    <Dropdown>
      <Dropdown.Toggle id={title} key={title} className="mr-4 btn-secondary">
        {title}
      </Dropdown.Toggle>

      <Dropdown.Menu
        as={CheckboxMenu}
        onSelectAll={handleSelectAll}
        selectTitle={selectTitle}
        className={title.toLowerCase()}
      >
        {items.map((i) => (
          <Dropdown.Item
            key={`${i.name}-${i.id}`}
            as={title == 'Hours' ? RadioDropdownItem : CheckDropdownItem}
            id={i.id}
            checked={i.selected}
            onChange={handleChecked}
            active={i.selected}
            className={title}
          >
            {i.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CheckboxDropdown;
