// Steps for Sonar New User Tour
// Steps are unique to each User Role

// Admin/Super Admin
export const AdminSteps = [
  {
    title: 'Assets Tab',
    content: (
      <p>
        View, search, and filter Assets.
        <br /> Use buttons to see Readings or
        <br /> go directly to Asset Details pages.
      </p>
    ),
    target: '#map-assets',
    placement: 'top',
    disableBeacon: true,
    hideFooter: true,
  },
  {
    title: 'Overview Tab',
    content: <p>View the Map's Description and Keywords</p>,
    target: '#map-overview',
    placement: 'top',
  },
  {
    title: 'Places Tab',
    content: (
      <p>
        View, search, and filter Places. <br /> Use the button to see more Place details.
      </p>
    ),
    target: '#map-places',
    placement: 'top',
    isFixed: true,
  },
  {
    title: 'Gateways Tab',
    content: (
      <p>
        View, search, and filter Gateways.
        <br /> Use the button to see more Gateway details.
      </p>
    ),
    target: '#map-gateways',
    placement: 'top',
  },
  {
    title: 'Stationary Assets Tab',
    content: (
      <p>
        View, search, and filter Stationary Assets.
        <br /> Use buttons to see Readings or go directly
        <br /> to Stationary Asset Details pages.
      </p>
    ),
    target: '#map-stationary-assets',
    placement: 'bottom-end',
  },
  {
    title: 'Favorites',
    content: (
      <p>
        Click the Star to add or remove this Map from Favorites.
        <br /> Favorites are accessible on the Dashboard.
      </p>
    ),
    target: '.map-favorite',
    placement: 'top',
    spotlightClicks: true,
  },
  {
    title: 'Edit Maps',
    content: <p>Click the Pencil to edit the Map.</p>,
    target: '.map-edit',
    placement: 'bottom-end',
  },
  {
    title: 'Search',
    content: (
      <p>
        Use the Search Box to Search by
        <br /> 'Name' on each tab.
      </p>
    ),
    target: '.map-search',
    placement: 'bottom',
  },
  {
    title: 'Filter or Load All',
    content: (
      <p>
        Use Filters to refine your data or select
        <br /> 'Load All' to load all items on this tab.
      </p>
    ),
    target: '.asset-filter-container',
    placement: 'bottom',
  },
  {
    title: 'Map Layers',
    content: (
      <p>
        Toggle between Satellite & Map view.
        <br />
        Show different Map Layers like Assets,
        <br /> Places, Gateways, & Stationary Assets.
      </p>
    ),
    target: '.map-layer-menu',
    placement: 'bottom',
  },
  {
    title: 'Tools Menu',
    content: <p>Adjust Map Opacity and Center Map Image</p>,
    target: '.map-tools-menu',
    placement: 'top-end',
  },
];

export const UserSteps = [
  {
    title: 'Assets Tab',
    content: (
      <p>
        View, search, and filter Assets.
        <br /> Use buttons to see Readings or
        <br /> go directly to Asset Details pages.
      </p>
    ),
    target: '#map-assets',
    placement: 'top',
    disableBeacon: true,
    hideFooter: true,
  },
  {
    title: 'Overview Tab',
    content: <p>View the Map's Description and Keywords</p>,
    target: '#map-overview',
    placement: 'top',
  },
  {
    title: 'Places Tab',
    content: (
      <p>
        View, search, and filter Places. <br /> Use the button to see more Place details.
      </p>
    ),
    target: '#map-places',
    placement: 'top',
    isFixed: true,
  },
  {
    title: 'Gateways Tab',
    content: (
      <p>
        View, search, and filter Gateways.
        <br /> Use the button to see more Gateway details.
      </p>
    ),
    target: '#map-gateways',
    placement: 'top',
  },
  {
    title: 'Stationary Assets Tab',
    content: (
      <p>
        View, search, and filter Stationary Assets.
        <br /> Use buttons to see Readings or go directly
        <br /> to Stationary Asset Details pages.
      </p>
    ),
    target: '#map-stationary-assets',
    placement: 'bottom-end',
  },
  {
    title: 'Favorites',
    content: (
      <p>
        Click the Star to add or remove this Map from Favorites.
        <br /> Favorites are accessible on the Dashboard.
      </p>
    ),
    target: '.map-favorite',
    placement: 'top',
    spotlightClicks: true,
  },
  {
    title: 'Search',
    content: (
      <p>
        Use the Search Box to Search by
        <br /> 'Name' on each tab.
      </p>
    ),
    target: '.map-search',
    placement: 'bottom',
  },
  {
    title: 'Filter or Load All',
    content: (
      <p>
        Use Filters to refine your data or select
        <br /> 'Load All' to load all items on this tab.
      </p>
    ),
    target: '.asset-filter-container',
    placement: 'bottom',
  },
  {
    title: 'Map Layers',
    content: (
      <p>
        Toggle between Satellite & Map view.
        <br />
        Show different Map Layers like Assets,
        <br /> Places, Gateways, & Stationary Assets.
      </p>
    ),
    target: '.map-layer-menu',
    placement: 'bottom',
  },
  {
    title: 'Tools Menu',
    content: <p>Adjust Map Opacity and Center Map Image</p>,
    target: '.map-tools-menu',
    placement: 'top-end',
  },
];

export const ReadOnlySteps = [
  {
    title: 'Assets Tab',
    content: (
      <p>
        View, search, and filter Assets.
        <br /> Use buttons to see Readings or
        <br /> go directly to Asset Details pages.
      </p>
    ),
    target: '#map-assets',
    placement: 'top',
    disableBeacon: true,
    hideFooter: true,
  },
  {
    title: 'Overview Tab',
    content: <p>View the Map's Description and Keywords</p>,
    target: '#map-overview',
    placement: 'top',
  },
  {
    title: 'Places Tab',
    content: (
      <p>
        View, search, and filter Places. <br /> Use the button to see more Place details.
      </p>
    ),
    target: '#map-places',
    placement: 'top',
    isFixed: true,
  },
  {
    title: 'Gateways Tab',
    content: (
      <p>
        View, search, and filter Gateways.
        <br /> Use the button to see more Gateway details.
      </p>
    ),
    target: '#map-gateways',
    placement: 'top',
  },
  {
    title: 'Stationary Assets Tab',
    content: (
      <p>
        View, search, and filter Stationary Assets.
        <br /> Use buttons to see Readings or go directly
        <br /> to Stationary Asset Details pages.
      </p>
    ),
    target: '#map-stationary-assets',
    placement: 'bottom-end',
  },
  {
    title: 'Favorites',
    content: (
      <p>
        Click the Star to add or remove this Map from Favorites.
        <br /> Favorites are accessible on the Dashboard.
      </p>
    ),
    target: '.map-favorite',
    placement: 'top',
    spotlightClicks: true,
  },
  {
    title: 'Search',
    content: (
      <p>
        Use the Search Box to Search by
        <br /> 'Name' on each tab.
      </p>
    ),
    target: '.map-search',
    placement: 'bottom',
  },
  {
    title: 'Filter or Load All',
    content: (
      <p>
        Use Filters to refine your data or select
        <br /> 'Load All' to load all items on this tab.
      </p>
    ),
    target: '.asset-filter-container',
    placement: 'bottom',
  },
  {
    title: 'Map Layers',
    content: (
      <p>
        Toggle between Satellite & Map view.
        <br />
        Show different Map Layers like Assets,
        <br /> Places, Gateways, & Stationary Assets.
      </p>
    ),
    target: '.map-layer-menu',
    placement: 'bottom',
  },
  {
    title: 'Tools Menu',
    content: <p>Adjust Map Opacity and Center Map Image</p>,
    target: '.map-tools-menu',
    placement: 'top-end',
  },
];
