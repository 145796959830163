import './RecentlyViewed.scss';
import { useDock } from '@react';
import { Api } from '@services';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { DockContainer, Favorites, Icon } from '../../components';
import { useApp } from '../../react';

async function fetchRecents({ appId }) {
  if (!appId) return [];
  const { data } = await Api.get(`/users/recentlyviewed/${appId}`);
  return data;
}

function RecentlyViewed({ edit, index, remove }) {
  const app = useApp();
  const history = useHistory();
  const { renderDock, closeDock } = useDock();
  const recentsRes = useQuery<any>(['home-recents'], () => fetchRecents({ appId: app?.id }));
  let truncatedName;

  const onClickItem = (item) => {
    const route = `/${item.itemType.toLowerCase()}s/${item.itemId}`;
    history.push(route);
  };

  const renderFavorites = () => {
    renderDock({
      render: () => (
        <DockContainer>
          <Favorites defaultActiveKey={'3'} onDone={closeDock} />
        </DockContainer>
      ),
    });
  };

  if (!recentsRes.isLoading && recentsRes.isSuccess && recentsRes.data[0] !== undefined) {
    truncatedName = recentsRes.data[0].name;
    if (truncatedName.length > 36) {
      truncatedName = truncatedName.substring(0, 33) + '...'; // Truncate and add ellipsis
    }
  }

  return (
    <div>
      <Card.Header className="recently-viewed-edit-header d-flex align-items-center justify-content-between">
        <Button
          className={`${
            edit ? 'pointer-none' : 'pointer-auto'
          }fs-md fw-bold d-flex align-items-center text-nowrap`}
          variant="light"
          onClick={() => {
            renderFavorites();
          }}
        >
          <div className="icon-wrap d-flex align-items-center text-right p-2">
            <i className={`sonarIcon sonarIcon-history cursor-pointer`} />
          </div>
          &nbsp; Recently Viewed
        </Button>
        {edit && (
          <Button
            className="delete-icon ignore-click"
            variant="light"
            onClick={() => {
              remove(index);
            }}
          >
            <i className="ignore-click sonarIcon sonarIcon-add sonarIcon-rotate-45 align-sub fs-lg" />
          </Button>
        )}
      </Card.Header>
      <Card.Body className="recently-viewed-edit-body d-flex flex-row justify-content-around align-items-center">
        {!recentsRes.isLoading && recentsRes.isSuccess && recentsRes.data[0] !== undefined && (
          <div className="d-flex flex-row justify-content-around align-items-center">
            {/* If recently viewed name is long add a overlay to display name on hover */}
            {recentsRes.data[0].name.length > 36 ? (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">{recentsRes.data[0].name}</Tooltip>}
              >
                <Button
                  className={`${
                    edit ? 'pointer-none' : 'pointer-auto'
                  } fs-md fw-bold d-flex flex-row justify-content-center align-items-center text-nowrap overflow-hidden text-ellipsis`}
                  variant="light"
                  onClick={() => onClickItem(recentsRes.data[0])}
                >
                  <i
                    className={`sonarIcon sonarIcon-${recentsRes.data[0].itemType.toLowerCase()} d-flex align-items-center text-nowrap overflow-hidden text-ellipsis`}
                  />
                  &nbsp; {truncatedName}
                </Button>
              </OverlayTrigger>
            ) : (
              <Button
                className={`${
                  edit ? 'pointer-none' : 'pointer-auto'
                } fs-md fw-bold d-flex flex-row justify-content-center align-items-center text-nowrap overflow-hidden text-ellipsis`}
                variant="light"
                onClick={() => onClickItem(recentsRes.data[0])}
              >
                <i
                  className={`sonarIcon sonarIcon-${recentsRes.data[0].itemType.toLowerCase()} d-flex align-items-center text-nowrap overflow-hidden text-ellipsis`}
                />
                &nbsp; {truncatedName}
              </Button>
            )}
          </div>
        )}
        {!recentsRes.isLoading && recentsRes.data[0] === undefined && (
          <>
            <div className="fs-md fw-bold d-flex flex-row justify-content-center align-items-center">
              There are no recently viewed Assets.
            </div>
          </>
        )}
      </Card.Body>
    </div>
  );
}

export default RecentlyViewed;
