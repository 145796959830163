import { useState } from 'react';
import { Button, ButtonGroup, Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

export function MapControls({
  mapInstance,
  mapLayers,
  isStatic,
  mapEdit,
  reading,
  bounds,
  setMapLayers,
  setReading,
  updateImageOpacity,
}) {
  const [showLayers, setShowLayers] = useState<any>([]);
  const [showTools, setShowTools] = useState<any>([]);
  const [opacity, setOpacity] = useState<String>('100');

  return (
    <div className="center-container">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <ToggleButtonGroup
          className="d-flex flex-row align-items-center justify-content-between me-3"
          type="checkbox"
          value={showLayers}
          onChange={(val) => {
            setShowLayers(val);
          }}
        >
          <ToggleButton
            className="d-flex flex-row align-items-center justify-content-between map-layer-menu"
            id={'Layer Menu'}
            variant={showLayers.includes('true') ? 'primary' : 'secondary'}
            value={'true'}
            title="Layer Menu"
          >
            <i className="sonarIcon sonarIcon-stack fs-md" />
          </ToggleButton>
        </ToggleButtonGroup>
        {showLayers.includes('true') && (
          <>
            <ToggleButtonGroup
              className="d-flex flex-row align-items-center justify-content-between me-3"
              type="checkbox"
              value={mapLayers}
              onChange={(val) => {
                setMapLayers(val);
              }}
            >
              <ToggleButton
                className="d-flex flex-row align-items-center justify-content-between"
                id={'edge'}
                variant={mapLayers.includes('edge') ? 'primary' : 'secondary'}
                value={'edge'}
                title="Toggle Map Edge"
              >
                <i className="sonarIcon sonarIcon-border_style fs-md" />
              </ToggleButton>
              {isStatic && (
                <ToggleButton
                  className="d-flex flex-row align-items-center justify-content-between"
                  id={'sat'}
                  variant={mapLayers.includes('sat') ? 'primary' : 'secondary'}
                  value={'sat'}
                  title="Toggle Satellite"
                >
                  <i className="sonarIcon sonarIcon-satellite fs-md" />
                </ToggleButton>
              )}
              {!mapEdit && (
                <ToggleButton
                  className="d-flex flex-row align-items-center justify-content-between"
                  id={'assets'}
                  variant={mapLayers.includes('assets') ? 'primary' : 'secondary'}
                  value={'assets'}
                  title="Toggle Assets"
                >
                  <i className="sonarIcon sonarIcon-asset fs-md" />
                </ToggleButton>
              )}
              <ToggleButton
                className="d-flex flex-row align-items-center justify-content-between"
                id={'places'}
                variant={mapLayers.includes('places') ? 'primary' : 'secondary'}
                value={'places'}
                title="Toggle Places"
              >
                <i className="sonarIcon sonarIcon-place fs-md" />
              </ToggleButton>
              <ToggleButton
                className="d-flex flex-row align-items-center justify-content-between"
                id={'gateways'}
                variant={mapLayers.includes('gateways') ? 'primary' : 'secondary'}
                value={'gateways'}
                title="Toggle Gateways"
              >
                <i className="sonarIcon sonarIcon-gateway fs-md" />
              </ToggleButton>
              <ToggleButton
                className="d-flex flex-row align-items-center justify-content-between"
                id={'stationary assets'}
                variant={mapLayers.includes('stationary assets') ? 'primary' : 'secondary'}
                value={'stationary assets'}
                title="Toggle Stationary Assets"
              >
                <i className="sonarIcon sonarIcon-stationary-beacon fs-md" />
              </ToggleButton>
            </ToggleButtonGroup>
            {/* {mapLayers.includes('stationary assets') && (
              <ToggleButtonGroup
                className="d-flex flex-row align-items-center justify-content-between me-3"
                type="radio"
                value={reading}
                name="readings"
                onChange={(val) => setReading(val)}
              >
                <ToggleButton
                  className="d-flex flex-row align-items-center justify-content-between"
                  id={'Temperature'}
                  variant={reading === 'Temperature' ? 'primary' : 'secondary'}
                  value="Temperature"
                  title="Toggle Temperature"
                >
                  <i className="sonarIcon sonarIcon-temperature fs-md" />
                </ToggleButton>
                <ToggleButton
                  className="d-flex flex-row align-items-center justify-content-between"
                  id={'Humidity'}
                  variant={reading === 'Humidity' ? 'primary' : 'secondary'}
                  value="Humidity"
                  title="Toggle Humidity"
                >
                  <i className="sonarIcon sonarIcon-humidity fs-md" />
                </ToggleButton>
                <ToggleButton
                  className="d-flex flex-row align-items-center justify-content-between"
                  id={'Vibration'}
                  variant={reading === 'Vibration' ? 'primary' : 'secondary'}
                  value="Vibration"
                  title="Toggle Vibration"
                >
                  <i className="sonarIcon sonarIcon-vibration fs-md" />
                </ToggleButton>
                <ToggleButton
                  className="d-flex flex-row align-items-center justify-content-between"
                  id={'Battery'}
                  variant={reading === 'Battery' ? 'primary' : 'secondary'}
                  value="Battery"
                  title="Toggle Battery"
                >
                  <i className="sonarIcon sonarIcon-battery fs-md" />
                </ToggleButton>
              </ToggleButtonGroup>
            )} */}
          </>
        )}
      </div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        {showTools.includes('true') && (
          <>
            <div className="opacity-slider">
              <Form.Range
                className="custom-range"
                title="Map Opacity"
                value={opacity.toString()}
                onChange={(e) => {
                  updateImageOpacity(e.target.value);
                  setOpacity(e.target.value);
                }}
              />
            </div>
            <ButtonGroup className="map-controls me-3">
              <Button
                title="Center Map"
                className="d-flex flex-row align-items-center justify-content-between"
                variant="secondary"
                onClick={() => {
                  mapInstance.fitBounds(bounds);
                  mapInstance.invalidateSize(true);
                }}
              >
                <i className="sonarIcon sonarIcon-center fs-md" />
              </Button>
            </ButtonGroup>
          </>
        )}
        <ToggleButtonGroup
          className="d-flex flex-row align-items-center justify-content-between"
          type="checkbox"
          value={showTools}
          onChange={(val) => {
            setShowTools(val);
          }}
        >
          <ToggleButton
            className="d-flex flex-row align-items-center justify-content-between map-tools-menu"
            id={'Tools Menu'}
            variant={showTools.includes('true') ? 'primary' : 'secondary'}
            value={'true'}
            title="Tools Menu"
          >
            <i className="sonarIcon sonarIcon-tools fs-md" />
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </div>
  );
}
