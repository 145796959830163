import './Keywords.scss';
import { isReadOnly, isUser } from '@utils';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { Badge } from 'react-bootstrap';
import { KEYBOARD_KEYS } from '../../constants';
import { isKeyboardKey } from '../../utils';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';

/**
 * @extends {React.Component<{isAddingKeyword:boolean.isRequired, onChange:Function.isRequired, //defaultsEmptyComp:oneOfType([func, element]), initKeywords:arrayOf(string), canDeleteKeywords:boolean}>}
 */
class Keywords extends Component {
  constructor(props) {
    super(props);

    const { initKeywords } = props;
    this.state = {
      newKeywordText: '',
      keywords: initKeywords,
    };

    this.inputRef = createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isAddingKeyword && this.props.isAddingKeyword) {
      this.inputRef.current.focus();
    }
    if (prevProps !== this.props) {
      if (prevState.keywords.length === 0 && this.props.initKeywords.length > 0) {
        this.setState({ keywords: this.props.initKeywords });
      }
    }
  }

  addKeyword = (keyword) => {
    this.setState({ keywords: this.state.keywords.concat(keyword) }, () => {
      this.props.onChange(this.state.keywords);
    });
  };

  deleteKeyword = (index) => {
    const keywords = [...this.state.keywords];
    keywords.splice(index, 1);
    this.setState({ keywords }, () => {
      this.props.onChange(this.state.keywords);
    });
  };

  renderKeywords = () => {
    const { keywords } = this.state;
    const { isAddingKeyword, EmptyComp, canDeleteKeywords } = this.props;

    if (!keywords.length && !isAddingKeyword) return <EmptyComp />;

    return keywords.map((keyword, index) => {
      // Returns keywords without ability to delete on /beacon-types
      if (!canDeleteKeywords) {
        return (
          <Badge className="d-flex align-items-center beacon-type-keywords" key={index} pill>
            <Text className="keyword-text-noedit">{keyword}</Text>
          </Badge>
        );
      }

      return (
        <Badge key={index} className="d-flex align-items-center keywords" bg="primary" pill>
          <Text className="keyword-text">{keyword}</Text>
          {/* Delete badge icon */}
          <Icon
            name={isReadOnly() ? '' : 'add'}
            size="sm"
            variant="dark"
            disabled={isReadOnly()}
            showPointer={true}
            className={
              isReadOnly()
                ? 'sonarIcon-circle-invisible'
                : 'sonarIcon-rotate-45 sonarIcon-circle keywords-delete-icon'
            }
            onClick={() => this.deleteKeyword(index)}
          />
        </Badge>
      );
    });
  };

  onKeyDown = (e) => {
    const { newKeywordText } = this.state;

    isKeyboardKey(e, KEYBOARD_KEYS.ENTER, () => {
      if (newKeywordText) {
        this.setState({ newKeywordText: '' }, () => {
          this.addKeyword(newKeywordText);
        });
      }

      e.preventDefault(); // prevent accidental form submissions
    });
  };

  onChange = (e) => {
    this.setState({ newKeywordText: e.target.value });
  };

  render() {
    const Comp = this.renderKeywords;
    return (
      <div className="d-flex flex-row align-items-center flex-wrap">
        <Comp />
        {/* New keyword input */}
        {this.props.isAddingKeyword && (
          <Badge className="d-flex align-items-center justify-content-center" bg="primary" pill>
            <input
              className="keyword-input"
              ref={this.inputRef}
              type="text"
              value={this.state.newKeywordText}
              placeholder="Enter keyword"
              onChange={this.onChange}
              onKeyDown={this.onKeyDown}
            />
            <Icon
              name="add"
              variant="dark"
              size="sm"
              disabled={this.state.newKeywordText ? false : true}
              className="keyword-add sonarIcon-circle"
              showPointer={true}
              onClick={() => {
                this.addKeyword(this.state.newKeywordText);
                this.setState({ newKeywordText: '' });
              }}
            />
          </Badge>
        )}
      </div>
    );
  }
}

Keywords.defaultProps = {
  EmptyComp: () => null,
  initKeywords: [],
  disabled: false,
  canDeleteKeywords: true,
};

Keywords.propTypes = {
  isAddingKeyword: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  EmptyComp: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  initKeywords: PropTypes.arrayOf(PropTypes.string),
  canDeleteKeywords: PropTypes.bool,
};

export default Keywords;
