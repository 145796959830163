import '../../MapMenu.scss';
import { useEffect, useState } from 'react';
import { Card, Form, InputGroup } from 'react-bootstrap';
import { Icon, Keywords, Text } from '../../..';
export function MapCreatePlace({
  selected,
  newObjCreated,
  setIsCreating,
  setCreatePlace,
  setObjectName,
  setObjectKeywords,
  setAddToMap,
  setRemoveTempItem,
}) {
  const [name, setName] = useState<string>('');
  const [showSave, setShowSave] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<any>([]);
  const [isInvalid, setIsInvalid] = useState<boolean>(false);

  useEffect(() => {
    setObjectName(name);
  }, [name, setObjectName]);

  useEffect(() => {
    setObjectKeywords(keywords);
  }, [keywords, setObjectKeywords]);

  useEffect(() => {
    newObjCreated ? setShowSave(true) : setShowSave(false);
  }, [newObjCreated]);

  const handleInput = (value) => {
    value !== '' ? setIsInvalid(false) : setIsInvalid(true);
    setName(value);
  };

  // Handle submission of item
  function startDraw() {
    setCreatePlace(true);
  }
  return (
    <Form className={selected !== 'place' ? 'object-wrapper' : 'object-wrapper-expand'}>
      <Card.Body>
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <Text className="fw-bold fs-md mb-3">Place Name:</Text>
            <div>
              {showSave ? (
                <Icon
                  name={'check'}
                  title="Confirm"
                  className="align-sub primary-icon"
                  variant="light"
                  disabled={name === '' ? true : false}
                  size="md"
                  onClick={() => {
                    setAddToMap(true);
                    setIsCreating(false);
                    setCreatePlace(false);
                    setShowSave(false);
                  }}
                />
              ) : (
                <Icon
                  name={'pin-edit'}
                  title="Draw Place"
                  className="align-sub primary-icon"
                  variant="light"
                  disabled={name === '' ? true : false}
                  size="md"
                  onClick={() => {
                    startDraw();
                  }}
                />
              )}

              <Icon
                name={'add'}
                title="Close"
                className="sonarIcon-rotate-45 align-sub nav-text-icon"
                variant="light"
                disabled={isInvalid}
                size="md"
                onClick={() => {
                  setCreatePlace(false);
                  setIsCreating(false);
                  setRemoveTempItem(true);
                }}
              />
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <InputGroup className="me-2">
              <Form.Control
                aria-label="Place Name"
                placeholder="Enter a Place name"
                isInvalid={isInvalid}
                value={name}
                onChange={(e) => {
                  handleInput(e.target.value);
                }}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a Place name.
              </Form.Control.Feedback>
            </InputGroup>
          </div>
          <Text className="fw-bold fs-md mb-3">Keywords:</Text>
          <Keywords isAddingKeyword onChange={(keywords) => setKeywords(keywords)} />
        </div>
      </Card.Body>
    </Form>
  );
}
