import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Api } from '../../services';
import { toast } from '../../components/utils';
import useExportDownload from '../../utils/useExportDownload';
import BulkDeleteScreen from './BulkDeleteScreen';

//Gets bulk delete template
const onTemplate = async () => {
  const params = {
    responseType: 'blob',
    headers: { Accept: 'text/csv' },
  };
  const csvFile = await Api.get('/assets/bulk-delete', params);
  return csvFile;
};

//Deletes Assets and returns response
const onUpload = async (e) => {
  let formData = new FormData();
  formData.append('bulkdelete.file', e);
  try {
    const response = await Api.post('/assets/bulk-delete', formData);
    return response.status;
  } catch (e) {
    return e.response.status;
  }
};

function BulkDelete({ app, onDone }) {
  const [templateData, setTemplateData] = useState<any>(null);
  const [response, setResponse] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const templateName = 'BulkDeleteTemplate';

  useExportDownload(templateData, templateName);

  //Temporary toasts for success and failure
  useEffect(() => {
    if (response) {
      if (response === 204) {
        toast.success('Assets successfully deleted.');
      } else {
        toast.error('There was an error deleting the selected Assets.');
      }
    }
  }, [response]);

  return (
    <BulkDeleteScreen
      itemName="Assets"
      closeDock={onDone}
      isLoading={isLoading}
      response={response}
      onTemplate={async () => {
        const res = await onTemplate();
        setTemplateData(res.data);
      }}
      onUpload={async (e) => {
        setIsLoading(true);
        const res = await onUpload(e);
        setResponse(res);
        setTimeout(() => {
          setIsLoading(false);
          onDone();
        }, 1000);
      }}
    />
  );
}

const mapStateToProps = ({ app }) => ({
  app: app.app,
});

export default connect(mapStateToProps)(BulkDelete);
