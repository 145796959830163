import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const PlotlyChart = ({ traces, title, normalize = false, onRangeUpdate }) => {
  const [selectedInteractiveRange, setSelectedInteractiveRange] = useState(null);

  const noData = !traces.length;

  const handleRelayout = (eventData) => {
    if (eventData && eventData['xaxis.range[0]'] && eventData['xaxis.range[1]']) {
      const rangeStart = eventData['xaxis.range[0]'];
      const rangeEnd = eventData['xaxis.range[1]'];
      setSelectedInteractiveRange([rangeStart, rangeEnd]);
      layout.xaxis.range = [rangeStart, rangeEnd];
    }
  };

  useEffect(() => {
    // Update the range in the parent component using the callback
    if (selectedInteractiveRange) {
      onRangeUpdate(selectedInteractiveRange);
    }
  }, [selectedInteractiveRange]);

  traces = traces.map((trace) => {
    const ymax = Math.max(...trace.y);
    const ymin = Math.min(...trace.y);
    if (normalize) {
      return {
        ...trace,
        y: trace.y.map((ypoint) => {
          if (ymin === ymax) {
            return 100;
          } else {
            return ((ypoint - ymin) / (ymax - ymin)) * 100;
          }
        }),
      };
    } else {
      return {
        ...trace,
      };
    }
  });
  let layout = {
    title: {
      text: title,
      font: {
        family: 'Arial',
        size: 22,
      },
      xref: 'paper',
      x: 0.05,
    },
    margin: {
      l: 50, // Left margin
      r: 50, // Right margin
      t: 80, // Top margin
      b: 80, // Bottom margin
      pad: 4, // Padding around the plot
    },
    xaxis: {
      showgrid: false,
      type: 'date',
      tickformat: '%I:%M %p \n %B %d, %Y',
    },
    showlegend: true,
    legend: {},
  };
  // Make sure to update the xaxis range if a selectedInteractiveRange exists
  if (selectedInteractiveRange) {
    layout.xaxis.range = selectedInteractiveRange;
  }
  let config = {
    responsive: true,
    displaylogo: false,
    modeBarButtonsToRemove: ['select2d', 'lasso2d', 'pan2d', 'zoom2d'],
  };
  if (noData) {
    layout['annotations'] = [
      {
        x: 0.5,
        y: 0.5,
        xref: 'paper',
        yref: 'paper',
        text: '',
        showarrow: false,
        font: {
          size: 44,
          family: 'Arial',
        },
        opacity: 0.7,
      },
    ];
    layout.xaxis = {
      showticklabels: false,
      showgrid: false,
      zeroline: false,
      linecolor: 'black',
      linewidth: 1,
      mirror: true,
    };
    layout.yaxis = {
      showticklabels: false,
      showgrid: false,
      zeroline: false,
      linecolor: 'black',
      linewidth: 1,
      mirror: true,
    };
  }
  return (
    <Plot
      data={traces}
      layout={layout}
      config={config}
      useResizeHandler={true}
      className="w-100 h-100"
      onRelayout={handleRelayout}
    />
  );
};

export default PlotlyChart;
