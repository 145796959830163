import '../../../../../src/scss/variables.scss';
import * as L from 'leaflet';
import {} from 'leaflet.awesome-markers';
import { responsivePopup } from 'leaflet-responsive-popup';
import { size } from 'lodash';
import ReactDOMServer from 'react-dom/server';
import { useHistory } from 'react-router-dom';
import { noop } from '../../../../utils';
import onlineStatusFunctions from '../../../../utils/onlineStatusFunctions';

const createLocationMarker = (beacon, color) => {
  if (beacon.location) {
    let marker = L.circle([beacon.location.y, beacon.location.x], {
      radius: 20,
      color: beacon.color || color,
      fillColor: beacon.fillColor || color,
      fillOpacity: beacon.fillOpacity || 0.2,
      weight: 1,
    });

    marker.bindPopup(responsivePopup().setContent(`<strong>${beacon.name}</strong>`));

    if (!window.markers) {
      window.markers = {};
      if (!window.markers.locations) {
        window.markers.locations = {};
      }
    } else {
      window.markers.locations[beacon.asset?.id] = () => {
        window.location.href = window.location.origin + '/assets/' + beacon.asset.id;
      };
    }

    marker.on('click', (e) => {
      e.data = beacon;
      e.marker = marker;
      // Ensure beacon.name is a string before using it in the onclick handler
      const name =
        typeof beacon.name === 'string' ? beacon.name.replace("'", '&#8217;') : 'Unknown';

      e.marker.bindPopup(
        responsivePopup().setContent(`
            <br/>
            <div class='d-flex flex-row align-items-center'>
              <strong class='col-10'>${beacon.name}</strong> 
              <a class='btn-light d-flex justify-content-left align-items-center' href='#'
                onclick="CBCopy('${name}')">
                <i class="col-1 far fa-lg fa-copy"></i>
              </a>
            </div>
            <hr/>
            <div class="row">
              <div class="col text-center">
                <span class="btn btn-primary btn-sm" type="button" 
                  onclick={window.markers.locations?.[${beacon.asset?.id}]()}>Go to Asset</span>
              </div>
            </div>
          `),
      );
      if (beacon.onmouseover) beacon.onmouseover(e);
      if (beacon.autoPopup) marker.openPopup();
    });

    marker.data = beacon;

    return marker;
  }
  return null;
};

const createGeoLocationMarker = (beacon, color) => {
  if (beacon.location) {
    let marker = L.circle([beacon.location.x, beacon.location.y], {
      radius: 20,
      color: color,
      fillColor: color,
      fillOpacity: 0.2,
      weight: 3,
    }).bindPopup(`<strong>${beacon.name}</strong>`);
    return marker;
  }
  return null;
};

const createHighlightedLocationMarker = (beacon, color) => {
  if (beacon.location) {
    let marker = L.marker([beacon.location.y, beacon.location.x], {
      icon: L.AwesomeMarkers.icon({ prefix: 'fa', icon: 'star', markerColor: 'green' }),
      title: beacon.name,
    });

    marker.beaconIndex = beacon.index;

    return marker;
  }
  return null;
};
const createGeoHighlightedLocationMarker = (beacon, color) => {
  if (beacon.location) {
    let marker = L.marker([beacon.location.x, beacon.location.y], {
      icon: L.AwesomeMarkers.icon({ prefix: 'fa', icon: 'star', markerColor: 'green' }),
      title: beacon.name,
    });

    marker.beaconIndex = beacon.index;

    return marker;
  }
  return null;
};

const createLocationCircle = (circle) => {
  return L.circle([circle.center.y, circle.center.x], {
    radius: circle.radius,
    opacity: 0.2,
    fillOpacity: 0.02,
  });
};

const createLocationsLayerGroup = (locations, { mapId, onSetLayers = noop } = {}) => {
  let filteredLocations = locations.filter((val) => val.location.type === 0 && val.mapId === mapId);
  return new L.FeatureGroup(createLocationsLayerArray(filteredLocations, { onSetLayers }));
};

const createGeoLocationsLayerGroup = (locations) => {
  let filteredLocations = locations.filter((val) => val.location.type === 1);
  return new L.FeatureGroup(createGeoLocationsLayerArray(filteredLocations));
};

const createGeoLocationsLayerArray = (locations) => {
  if (!locations) {
    return [];
  }

  let locationsLayerGroup = [];

  const sorted = locations.concat().sort(function (a, b) {
    return a.isHighlighted - b.isHighlighted;
  });

  for (let location of sorted) {
    for (let layer of createGeoLocationLayers(location)) {
      locationsLayerGroup.push(layer);
    }
  }
  return locationsLayerGroup;
};

const createLocationLayers = (location) => {
  if (!location.location) {
    location.location = {
      x: location.x,
      y: location.y,
    };
  }

  let layers = [];
  if (location.isVisible) {
    let layer = null;
    if (location.isHighlighted) {
      layer = createHighlightedLocationMarker(
        location,
        onlineStatusFunctions.getHexColorByStatus(location.onlineStatus),
      );

      if (layer != null) {
        layers.push(layer);
        if (location.circles) {
          for (let circle of location.circles) {
            layers.push(createLocationCircle(circle));
          }
        }
      }
    } else {
      layer = createLocationMarker(
        location,
        onlineStatusFunctions.getHexColorByStatus(location.onlineStatus),
      );
      if (layer != null) {
        layers.push(layer);
      }
    }
  }

  return layers;
};

const createGeoLocationLayers = (location) => {
  if (!location.location) {
    location.location = {
      x: location.x,
      y: location.y,
    };
  }

  let layers = [];
  if (location.isVisible) {
    let layer = null;
    if (location.isHighlighted) {
      layer = createGeoHighlightedLocationMarker(
        location,
        onlineStatusFunctions.getHexColorByStatus(location.onlineStatus),
      );

      if (layer != null) {
        layers.push(layer);
        if (location.circles) {
          for (let circle of location.circles) {
            layers.push(createLocationCircle(circle));
          }
        }
      }
    } else {
      layer = createGeoLocationMarker(
        location,
        onlineStatusFunctions.getHexColorByStatus(location.onlineStatus),
      );
      if (layer != null) {
        layers.push(layer);
      }
    }
  }

  return layers;
};

const createLocationsLayerArray = (locations, { onSetLayers = noop } = {}) => {
  if (!locations) {
    return [];
  }

  let locationsLayerGroup = [];

  const sorted = locations.concat().sort(function (a, b) {
    return a.isHighlighted - b.isHighlighted;
  });

  for (let location of sorted) {
    for (let layer of createLocationLayers(location)) {
      locationsLayerGroup.push(layer);
    }
  }

  onSetLayers(locationsLayerGroup);

  return locationsLayerGroup;
};

export {
  createGeoHighlightedLocationMarker,
  createGeoLocationsLayerArray,
  createGeoLocationsLayerGroup,
  createLocationLayers,
  createLocationsLayerArray,
  createLocationsLayerGroup,
};
