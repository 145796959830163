import './SonarMapTourModal.scss';
import { Button, Modal } from 'react-bootstrap';

const SonarMapTourModal = ({ onStartTour, onSkipTour, show }) => (
  <Modal
    className={`sonar-map-tour-modal ${show ? 'fade-in' : 'fade-out'}`}
    show={show}
    onHide={onSkipTour}
    backdrop="static"
    keyboard={false}
  >
    <Modal.Body>
      <Modal.Title className="tour-title">
        Introducing the New
        <br />
        <img className="logo" src={'/img/thinaer-white.png'} alt="Thinaer Sonar" />
        <br />
        <div className="tour-title-maps">Sonar Maps</div>
      </Modal.Title>

      <div className="d-flex justify-content-center">
        <img className="data-image" src={'/img/login-slide-1.png'} alt="Data Content" />
      </div>

      <div className="d-flex justify-content-center">
        <Button className="d-flex gap-2" id="start" variant="tertiary" onClick={onStartTour}>
          Start Tour
          <div className="circle">
            <div className="carrot"></div>
          </div>
        </Button>
      </div>
      <div className="d-flex justify-content-center">
        <Button id="skip" variant="tertiary" onClick={onSkipTour}>
          Skip the tour
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);

export default SonarMapTourModal;
