import '../../../../scss/variables.scss';
import './EditCellRenderer.scss';
import { isAdmin, isSuperAdmin, isSystemAdmin } from '@utils';
import { useHistory } from 'react-router-dom';
import Icon from '../../../Icon/Icon';

function EditCellRenderer(items) {
  const history = useHistory();
  const authorized =
    (isAdmin() || isSystemAdmin() || isSuperAdmin()) && items.data.mapType === 'Legacy';

  const _onClickEdit = () => {
    history.push(`/maps/${items.data.id}/edit`);
  };

  return (
    <div className="favorite-edit">
      {authorized && (
        <Icon
          name="edit"
          className="edit align-sub"
          variant="light"
          disabled={false}
          showPointer={true}
          size="lg"
          style={{ color: 'var(--primary)' }}
          onClick={_onClickEdit}
        />
      )}
    </div>
  );
}

export default EditCellRenderer;
