import './MapList.scss';
import { Api } from '@services';
import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Text } from '../../../components';
import {
  Icon,
  LinkCellRenderer,
  MapThumbnailCellRenderer,
  V2EditCellRenderer,
} from '../../../components';
import { PagedQueryParams, ServerSideGrid } from '../../../components/grids/AgGrid/ServerSideGrid';
import { PagedModel } from '../../../models/PagedModel';
import { mapListV2ColumnDefs } from './columnDefs';

export function MapListBeta() {
  const [showGrid, setShowGrid] = useState<Boolean>(true);
  const [rowData, setRowData] = useState<any>(new PagedModel());
  const [csvData, setCsvData] = useState<any>(new PagedModel());
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const FetchData = async (payload: any, isCSV: any, queryState: any) => {
    setIsLoading(true);
    Api.get(
      `v2/maps?pageSize=${queryState.pageSize}&page=${queryState.pageNumber}&search=${queryState.filter}`,
    ).then((results: any) => {
      results.data['count'] === 0 ? setShowGrid(false) : setShowGrid(true);
      if (isCSV) {
        setCsvData(results.data);
      } else {
        setRowData(results.data);
      }
      setIsLoading(false);
    });
  };

  const backToMaps = () => {
    setIsLoading(true);
    setShowGrid(true);
  };

  const handleDeleteSuccess = () => {
    // Update the rowData count by decrementing it by 1
    setRowData((prevRowData) => {
      const newCount = prevRowData.count - 1;
      return { ...prevRowData, count: newCount };
    });
  };

  return (
    <Card className="map-list-card h-100">
      <Card.Body>
        {showGrid ? (
          <ServerSideGrid
            id={'MapListGrid'}
            columnDefs={mapListV2ColumnDefs(handleDeleteSuccess)}
            handleFetchData={FetchData}
            rowData={rowData}
            csvData={csvData}
            isLoading={isLoading}
            disabledFilters={[]}
            filterControl={false}
            searchControl={true}
            viewingControl={true}
            pagerControl={true}
            exportControl={false}
            gridProps={{
              frameworkComponents: {
                V2EditCellRenderer,
                LinkCellRenderer,
                MapThumbnailCellRenderer,
              },
              rowHeight: 80,
              suppressClickEdit: true,
              editType: 'fullRow',
              enableBrowserTooltips: false,
              tooltipShowDelay: 0,
              tooltipMouseTrack: true,
            }}
            initQueryParams={new PagedQueryParams()}
          />
        ) : (
          <div className="map-list-empty">
            <Icon
              name="map"
              variant="dark"
              size="8x"
              className="mb-3"
              disabled={false}
              showPointer={false}
            />
            <Text className="mb-3" as="h5">
              Whoops looks like you don't have any maps!
            </Text>
            <Text className="mb-4" as="h5">
              Would you like to create one?
            </Text>
            <Link to="/maps/create">
              <Button className="create-map" variant="primary">
                Create Map
                <i className="sonarIcon sonarIcon-add fs-sm" />
              </Button>
            </Link>
            <Link to="/maps" onClick={backToMaps}>
              <Button className="mt-2 ps-5 pe-5" variant="secondary">
                Back
              </Button>
            </Link>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}
