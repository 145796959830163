import { Loading } from '@components';
import axios from 'axios';
import { isEmpty } from 'lodash';
import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { parseString } from 'xml2js';

const CancelToken = axios.CancelToken;
let fetchDataCancel;

const Articles = () => {
  const history = useHistory();
  const q = new URLSearchParams(window.location.search);

  const [articles, setArticles] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState(q.get('search') || '');
  const [topic, setTopic] = React.useState(q.get('topic') || '');
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(q.get('page') || 1);

  React.useEffect(() => {
    setLoading(true);
    setArticles([]);
    if (fetchDataCancel !== null && typeof fetchDataCancel === 'function') {
      fetchDataCancel();
      fetchDataCancel = null;
    }
    const url = !isEmpty(topic)
      ? `https://thinaer.io/feed/?post_type=article&s=${searchTerm}&paged=${page}&topics=${topic}`
      : `https://thinaer.io/feed/?post_type=article&s=${searchTerm}&paged=${page}`;
    axios
      .get(url, {
        cancelToken: new CancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      })
      .then(({ data }) => {
        parseString(data, { trim: true }, (err, result) => {
          if (result?.rss?.channel[0]?.item) {
            setArticles(
              result.rss.channel[0].item.map((i) => {
                i.title = i.title[0];
                i.id = i.guid[0]._.split('p=')[1];
                return i;
              }),
            );
          }
          setLoading(false);
        });
      });
  }, [page]);

  React.useEffect(() => {
    setLoading(true);
    setArticles([]);
    if (fetchDataCancel !== null && typeof fetchDataCancel === 'function') {
      fetchDataCancel();
      fetchDataCancel = null;
    }
    const url = !isEmpty(topic)
      ? `https://thinaer.io/feed/?post_type=article&s=${searchTerm}&paged=${page}&topics=${topic}`
      : `https://thinaer.io/feed/?post_type=article&s=${searchTerm}&paged=${page}`;
    axios
      .get(url, {
        cancelToken: new CancelToken(function executor(c) {
          fetchDataCancel = c;
        }),
      })
      .then(({ data }) => {
        parseString(data, { trim: true }, (err, result) => {
          if (result?.rss?.channel[0]?.item) {
            setArticles(
              result.rss.channel[0].item.map((i) => {
                i.title = i.title[0];
                i.id = i.guid[0]._.split('p=')[1];
                return i;
              }),
            );
          }
          setLoading(false);
        });
      });
  }, [searchTerm, topic]);

  React.useEffect(() => {
    let search = '?';
    search = Boolean(searchTerm.trim().length) ? search + '&search=' + searchTerm : search;
    search = Boolean(page > 1) ? search + '&page=' + page : search;
    search = Boolean(topic.trim().length > 1) ? search + '&topic=' + topic : search;
    search = search.replace('?&', '?');
    history.push(window.location.pathname.split('?')[0] + search);
  }, [searchTerm, page, topic]);

  const handlePage = (opperation) => {
    if (page >= 1) {
      if (opperation === 'subtract') {
        if (page !== 1) {
          setPage(page - 1);
        }
      } else {
        if (articles.length >= 10) {
          setPage(page + 1);
        }
      }
    }
  };

  const Pagination = () => {
    return (
      <Row className="mt-3">
        <Col>&nbsp;</Col>
        <Col lg={3} className="text-right">
          <Row>
            <Col>
              <Button variant="transparent" size="sm" onClick={() => handlePage('previous')}>
                <i
                  class={`fas fa-arrow-circle-left h5 text-${page > 1 ? 'primary' : 'secondary'}`}
                ></i>
              </Button>
            </Col>
            <Col xs={3}>
              <Button variant="transparent" size="sm" onClick={() => handlePage('next')}>
                <i
                  class={`fas fa-arrow-circle-right h5 text-${
                    articles.length >= 12 ? 'primary' : 'secondary'
                  }`}
                ></i>
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <Col lg={10} className="m-auto">
      <h3>
        <i class="far fa-question-circle text-primary mt-1"></i> Help Articles
      </h3>
      {true && (
        <Row className="mt-3">
          <Col>
            <h5>Topics</h5>
            <Row>
              {Boolean(articles[0]) &&
                JSON.parse(articles[0]['content:encoded'][0].split('|||')[1]).map((t) => {
                  return (
                    <Col lg={3} onClick={() => setTopic(t.slug)}>
                      <Card className="text-center" onClick={() => setTopic('gettings-started')}>
                        <Card.Body>{t.name}</Card.Body>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
          </Col>
        </Row>
      )}
      {Boolean(topic.trim().length) && (
        <Row>
          <Col className="mt-3">
            Currently showing results for the topic{' '}
            <b className="text-primary">"{q.get('topic')}"</b>{' '}
            <i
              class="btn p-0 h6 text-danger mt-1 far fa-times-circle"
              onClick={() => setTopic('')}
            />
          </Col>
        </Row>
      )}
      <hr />
      <Row>
        <Col>
          <Form.Control
            value={searchTerm}
            placeholder="Search help articles..."
            onInput={({ target }) => setSearchTerm(target.value)}
          />
        </Col>
      </Row>
      <Pagination />
      {!loading ? (
        <Row className="mt-3">
          <Col>
            {articles.length ? (
              articles?.map((article) => {
                return (
                  <Row className="mt-3">
                    <Col>
                      <Card className="p-3">
                        <Row>
                          <Col>
                            <Button
                              variant="transparent"
                              className="text-primary px-0"
                              onClick={() => history.push('/help/' + article.id)}
                            >
                              <h4>{article.title}</h4>
                            </Button>
                            <Row>
                              <Col>
                                {article['content:encoded'][0].indexOf('|||') > 240 ? (
                                  article['description'][0]
                                ) : (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: article['content:encoded'][0].split('|||')[0],
                                    }}
                                  />
                                )}
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <Col>
                                <Button
                                  size="sm"
                                  onClick={() => history.push('/help/' + article.id)}
                                >
                                  Read More
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                );
              })
            ) : (
              <h4>No results found.</h4>
            )}
            <Pagination />
          </Col>
        </Row>
      ) : (
        <Loading />
      )}
    </Col>
  );
};

export default Articles;
