import '../../../scss/variables.scss';
import './Header.scss';
import { useEffect } from 'react';
import { useNavigation } from '../../../react';
import Icon from '../../Icon/Icon';

function NavToggle() {
  const { toggleNavigation, ...navigation } = useNavigation();

  useEffect(() => {
    const navBar = document.querySelector('nav.sidenav');
    navBar.style.boxShadow = 'var(--box-shadow-right)';
  }, []);

  return (
    <Icon
      name="hamburger"
      className="nav-hamburger"
      variant="light"
      disabled={false}
      onClick={toggleNavigation}
      showPointer={true}
      title={navigation.isOpen ? 'Close Navigation' : 'Open Navigation'}
      size="2x"
    />
  );
}

export default NavToggle;
