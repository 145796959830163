// This is used within ServerSideGrid
// Creates export download for Beacons, Gateways, Reports,

import { useEffect } from 'react';

const useExportDownload = (templateData, templateName) => {
  useEffect(() => {
    if (templateData) {
      const href = URL.createObjectURL(templateData);

      // Create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', `${templateName}.csv`); //or any other extension
      document.body.appendChild(link);
      link.click();

      // Clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
  }, [templateData]);
};

export default useExportDownload;
