import '../../MapMenu.scss';
import '../../../../scss/variables.scss';
import { useRef } from 'react';
import { Button, Card, ToggleButtonGroup } from 'react-bootstrap';

export function MapInfoTabs({ selected, setSelected }) {
  const csvLinkRef = useRef(null);

  return (
    <div className="objects-bar">
      <Card.Header>
        <ToggleButtonGroup
          type="radio"
          name="info-tabs"
          className="d-flex flex-row justify-content-between"
        >
          <Button
            id="map-overview"
            className="d-flex flex-row align-items-center justify-content-center"
            variant={selected === 'overlay' ? 'primary' : 'secondary'}
            onClick={() => {
              selected === 'overlay' ? setSelected('') : setSelected('overlay');
            }}
          >
            <i className="sonarIcon sonarIcon-map fs-md" />
          </Button>
          <Button
            id="map-places"
            className="d-flex flex-row align-items-center justify-content-center"
            variant={selected === 'place' ? 'primary' : 'secondary'}
            onClick={() => {
              selected === 'place' ? setSelected('') : setSelected('place');
            }}
          >
            <i className="sonarIcon sonarIcon-place fs-md" />
          </Button>
          <Button
            id="map-assets"
            className="d-flex flex-row align-items-center justify-content-center"
            variant={selected === 'asset' ? 'primary' : 'secondary'}
            onClick={() => {
              selected === 'asset' ? setSelected('') : setSelected('asset');
            }}
          >
            <i className="sonarIcon sonarIcon-asset fs-md" />
          </Button>
          <Button
            id="map-gateways"
            className="d-flex flex-row align-items-center justify-content-center"
            variant={selected === 'gateway' ? 'primary' : 'secondary'}
            onClick={() => {
              selected === 'gateway' ? setSelected('') : setSelected('gateway');
            }}
          >
            <i className="sonarIcon sonarIcon-gateway fs-md" />
          </Button>
          <Button
            id="map-stationary-assets"
            className="d-flex flex-row align-items-center justify-content-center"
            variant={selected === 'stationary' ? 'primary' : 'secondary'}
            onClick={() => {
              selected === 'stationary' ? setSelected('') : setSelected('stationary');
            }}
          >
            <i className="sonarIcon sonarIcon-stationary-beacon fs-md" />
          </Button>
        </ToggleButtonGroup>
      </Card.Header>
    </div>
  );
}
