import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import './locationMap.scss';
import 'leaflet-responsive-popup/leaflet.responsive.popup.css';
import * as L from 'leaflet';
import { responsivePopup } from 'leaflet-responsive-popup';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Component } from 'react';
import { noop } from '../../../utils';
import { createBeaconsLayerGroup } from '../services/leafletLayerFactories/leafletBeaconFactory';
import { createGatewaysLayerGroup } from '../services/leafletLayerFactories/leafletGatewayFactory';
import {
  createLocationsLayerArray,
  createLocationsLayerGroup,
} from '../services/leafletLayerFactories/leafletLocationFactory';
import { createOutlierLayerGroup } from '../services/leafletLayerFactories/leafletOutliersFactory';
import { createZonesLayerGroup } from '../services/leafletLayerFactories/leafletZoneFactory';
import { createPath } from '../services/leafletLayerFactories/lineLayerFactory';

let locations;
let image;
let paths;

class LocationMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      selectedIndex: null,
      imageBlob: null,
    };
    this.mapContainerRef = React.createRef();
  }

  componentDidMount() {
    const mapContainer = this.mapContainerRef.current; // Access the map container using the ref
    if (!mapContainer) {
      console.error(`Map container not found.`);
      return; // Do not proceed if the container is missing
    }
    this.loadImageAndSetupMap();
  }

  componentDidUpdate(prevProps) {
    const { map } = this.state;

    let redrawCanvas = false;

    if (prevProps.map !== this.props.map) redrawCanvas = true;

    if (this.props.map.id !== prevProps.map.id) {
      if (map.hasLayer(image)) {
        map.removeLayer(image);
        this.loadImageAndSetupMap(); // Re-load image and setup map
      }
      redrawCanvas = true;
    }

    if (prevProps.reading !== this.props.reading) redrawCanvas = true;
    if (this.props.marker !== prevProps.marker) redrawCanvas = true;
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) redrawCanvas = true;

    if (redrawCanvas) this.drawCanvas(map);
  }

  componentWillUnmount() {
    const { map } = this.state;
    if (map) {
      map.off(); // Remove all event listeners
      map.remove(); // Remove map instance
    }
  }

  loadImageAndSetupMap() {
    const imageUrl = `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/api/v2/maps/${this.props.map.id}/image`;
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      const imageWidth = img.width;
      const imageHeight = img.height;

      // Set up the map with image dimensions
      this.setupMap(imageWidth, imageHeight);
    };

    img.onerror = () => {
      console.error('Failed to load image.');
    };
  }

  setupMap(imageWidth, imageHeight) {
    const mapContainer = this.mapContainerRef.current; // Access the map container via ref
    if (!mapContainer) {
      console.error(`Map container not found during setupMap.`);
      return;
    }

    // Check if map is already initialized, and destroy it if necessary
    if (this.state.map) {
      this.state.map.off(); // Remove event listeners
      this.state.map.remove(); // Remove the map
    }

    const bounds = [
      [0, 0],
      [imageHeight, imageWidth],
    ];

    const map = L.map(mapContainer, {
      scrollWheelZoom: true,
      zoomControl: true,
      crs: L.CRS.Simple,
      maxBoundsViscosity: 0.8,
      maxBounds: bounds,
      attributionControl: false,
      minZoom: -5,
      zoomDelta: 0.1,
      zoomSnap: 0.1,
    });

    image = L.imageOverlay(
      `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/api/v2/maps/${this.props.map.id}/image`,
      bounds,
    );
    image.addTo(map);
    map.fitBounds(bounds);

    this.setState({ map: map, loaded: true });
    this.drawCanvas(map);
  }

  drawPaths(paths, map) {
    for (let i = 0; i < this.props.selectedIndex; i++) {
      if (this.props.map.id !== this.props.locations[i].mapId) {
        paths.forEach((value) => {
          value.removeFrom(map);
        });
      } else {
        paths[i].addTo(map);
      }
    }
  }

  drawCanvas(map) {
    if (!map) {
      return;
    }

    map.eachLayer(function (layer) {
      if (layer._url == null) {
        map.removeLayer(layer);
      }
    });

    const zones = createZonesLayerGroup(this.props.zones);
    zones.addTo(map);
    const gateways = createGatewaysLayerGroup(this.props.gateways, {
      onSetLayers: this.props.onSetLayers,
    });
    gateways.addTo(map);
    const beacons = createBeaconsLayerGroup(this.props.beacons, {
      reading: this.props.reading,
      onSetLayers: this.props.onSetLayers,
    });
    beacons.addTo(map);

    const outliers = createOutlierLayerGroup(this.props.outliers, {
      reading: this.props.reading,
      onSetLayers: this.props.onSetLayers,
    });
    outliers.addTo(map);

    //marker with tooltip for last location
    if (
      this.props.marker &&
      this.props.marker.x &&
      !this.props.locations &&
      this.props.lastCheckin
    ) {
      let marker = new L.Marker([this.props.marker.y, this.props.marker.x]);
      marker.addTo(map);

      // Add a tooltip to the marker
      marker
        .bindTooltip(
          `Last Check-In: ${moment(this.props.lastCheckin).format('MMMM Do YYYY, h:mm a')}`,
        )
        .openTooltip(); // You can change the text
    }

    // tooltip displayed on asset detail page no tooltip data not working
    if (
      this.props.marker &&
      this.props.marker.x &&
      !this.props.locations &&
      !this.props.lastCheckin
    ) {
      let marker = new L.Marker([this.props.marker.y, this.props.marker.x]);
      marker.addTo(map);
    }

    //marker without tooltip for when data is in breadcrumbs table
    if (this.props.marker && this.props.marker.x && this.props.locations) {
      let marker = new L.Marker([this.props.marker.y, this.props.marker.x]);
      marker.addTo(map);
    }

    if (this.props.static === true) {
      locations = createLocationsLayerGroup(this.props.locations);
      locations.addTo(map);
    }

    if (this.props.locations && this.props.locations.length >= 1) {
      if (this.props.playback) {
        if (this.props.selectedIndex) {
          if (this.props.displayType === 'lines') {
            paths = createPath(this.props.locations);
            this.drawPaths(paths, map);
          } else {
            locations = createLocationsLayerArray(this.props.locations, {
              onSetLayers: this.props.onSetLocationLayers,
            });

            for (let i = 0; i < this.props.selectedIndex; i++) {
              if (this.props.locations[i].location.type === 0) {
                locations[i].addTo(map);
              }
            }
            for (let i = this.props.locations.length - 1; i < locations.length; i++) {
              locations[i].addTo(map);
            }
          }
        } else if (this.props.paused) {
          if (this.props.displayType === 'lines') {
            this.drawPaths(this.props.sortedLocations, map);
          } else {
            locations = createLocationsLayerArray(this.props.locations, {
              onSetLayers: this.props.onSetLocationLayers,
            });
            for (let i = 0; i < this.props.selectedIndex; i++) {
              if (this.props.locations[i].location.type === 0) {
                locations[i].addTo(map);
              }
            }
            for (let i = this.props.locations.length - 1; i < locations.length; i++) {
              locations[i].addTo(map);
            }
          }
        } else {
          if (this.props.displayType === 'lines') {
            paths = createPath(this.props.locations);
            this.drawPaths(paths, map);
          } else {
            locations = createLocationsLayerArray(this.props.locations, {
              onSetLayers: this.props.onSetLocationLayers,
            });
          }
        }
      } else {
        if (this.props.displayType === 'lines') {
          paths = createPath(this.props.locations);
          this.drawPaths(paths, map);
        } else {
          locations = createLocationsLayerGroup(this.props.locations, {
            mapId: this.props.map.id,
            onSetLayers: this.props.onSetLocationLayers,
          });
          locations.addTo(map);
        }
      }
    } else if (this.props.selectedIndex === 0) {
      if (this.props.displayType === 'lines') {
        this.drawPaths(this.props.sortedLocations, map);
      } else {
        locations = createLocationsLayerGroup(this.props.locations);
        locations.addTo(map);
      }
    }
  }

  render() {
    return (
      <div
        ref={this.mapContainerRef} // Attach the ref to the container element
        id={this.props.id}
        className="h-100"
      />
    );
  }
}

LocationMap.defaultProps = {
  id: 'map',
  onSetLayers: noop,
  onSetLocationLayers: noop,
};

LocationMap.propTypes = {
  id: PropTypes.string,
  map: PropTypes.object,
  locations: PropTypes.array,
  sortedLocations: PropTypes.array,
  zones: PropTypes.array,
  gateways: PropTypes.array,
  beacons: PropTypes.array,
  outliers: PropTypes.array,
  height: PropTypes.string,
  playback: PropTypes.bool,
  displayType: PropTypes.string,
  marker: PropTypes.object,
  reading: PropTypes.string,
  selectedIndex: PropTypes.number,
  pace: PropTypes.number,
  paused: PropTypes.bool,
  onSetLayers: PropTypes.func,
  onSetLocationLayers: PropTypes.func,
};

export default LocationMap;
