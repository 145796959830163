import './ReportTypeInput.scss';
import { useEffect, useState } from 'react';
import Select2 from 'react-select';

const ReportTypeInput = (props) => {
  const [selectedReport, setSelectedReport] = useState({ label: 'Select a Report', value: '' });
  const [highlight, setHighlight] = useState(false); // State to track if the select should be highlighted

  // Add future additional report types here
  const ReportTypes = [
    {
      value: 'battery',
      label: 'Battery Report',
    },
    // {
    //   value: 'environmental',
    //   label: 'Environmental Report',
    // },
    // {
    //     value: 'humidity',
    //     label: 'Humidity Trend Report'
    // },
    // {
    //     value: 'temperature',
    //     label: 'Temperature Trend Report'
    // },
    {
      value: 'trigger-resolution',
      label: 'Trigger Resolution Report',
    },
  ];

  const handleChange = (selectedOption) => {
    setSelectedReport(selectedOption);
    setHighlight(false);
    window.history.pushState({}, '', `/analytics/analytics-reports/${selectedOption.value}-report`);
  };

  //Func to take user to right pg when they manually input a reportType
  const handleManualUrlChange = (urlString, query) => {
    let strToLower = urlString.trim().toLowerCase();
    let index = strToLower.lastIndexOf('/');
    let subStr = strToLower.slice(index).slice(1);
    const newPath = ReportTypes.filter((obj, i) => {
      return `${obj.value}-report` === subStr;
    });

    if (newPath) {
      setSelectedReport(newPath[0]);
    }

    // If the query parameter includes highlight=analytics, enable highlighting
    if (query.get('highlight') === 'analytics') {
      setHighlight(true); // Set highlight state to true
    }
  };

  //useEffect Hooks
  useEffect(() => {
    if (selectedReport) {
      props.ChangeReportType(selectedReport.value, selectedReport.label);
    }
  }, [selectedReport, props]);

  useEffect(() => {
    const urlPathName = window.location.pathname;
    const queryParams = new URLSearchParams(window.location.search);
    handleManualUrlChange(urlPathName, queryParams);
    window.history.pushState({}, '', urlPathName);
  }, []);

  return (
    <Select2
      options={ReportTypes}
      value={selectedReport}
      className={highlight ? 'highlight-battery-report' : ''}
      placeholder="Select a Report"
      onChange={handleChange}
      styles={{
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        control: (base, state) => ({
          ...base,
          border: '1px solid lightgray',
          boxShadow: 'none',
          '&:hover': {
            border: '1px solid lightgray',
          },
          '&:active': {
            borderColor: 'var(--c-primary)',
            boxShadow: '0 0 0 1px var(--c-primary)',
          },
          '&:focus-within': {
            borderColor: 'var(--c-primary)',
            boxShadow: '0 0 0 1px var(--c-primary)',
          },
        }),
        option: (styles, state) => ({
          ...styles,
          backgroundColor: state.isSelected ? 'var(--c-primary)' : null,
          color: state.isSelected ? 'white' : 'black',
          '&:hover': {
            backgroundColor: state.isSelected ? 'var(--c-primary)' : '#e0ecfc',
          },
        }),
      }}
    />
  );
};

export default ReportTypeInput;
