import './ContactList.scss';
import { Typography } from '@mui/material';
import { Api } from '@services';
import { cloneDeep, find, map, remove } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import Select from 'react-select';
import Icon from '../../../components/Icon/Icon';

export const ContactList = () => {
  const [scheduleOpts, setScheduleOpts] = useState([]);
  const [deliverySchedule, setDeliverySchedule] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [notified, setNotified] = useState([]);
  const typeaheadRef = useRef(null);

  useEffect(() => {
    const fetch = async () => {
      const { data: contacts } = await Api.get('/contacts');
      setContacts(contacts);

      const { data: settings } = await Api.get('/reports/battery/settings');
      const deliverySchedules = map(settings.allDeliverySchedules, (schedule) => {
        return {
          value: schedule.id,
          label: schedule.value,
        };
      });
      setScheduleOpts(deliverySchedules);
      const currentSchedule = find(deliverySchedules, {
        value: settings.deliveryScheduleId,
      });
      setDeliverySchedule(currentSchedule);
      const _notified = map(settings.distributionList, (contact) => {
        return {
          id: contact.contactId,
          label: contact.email,
        };
      });
      setNotified(_notified);
    };
    fetch();
  }, []);

  const addNotified = (contact) => {
    const _notified = cloneDeep(notified);
    if (!find(_notified, { id: contact.id })) {
      _notified.push(contact);
      setNotified(_notified);
      updateContacts('post', contact.id);
    }
  };

  const removeNotified = (contactId) => {
    const _notified = cloneDeep(notified);
    remove(_notified, { id: contactId });
    setNotified(_notified);
    updateContacts('delete', contactId);
  };

  const onChangeDeliverySchedule = (schedule) => {
    setDeliverySchedule(schedule);
    updateSchedule(schedule.value);
  };

  const updateSchedule = async (schedule) => {
    const { data } = await Api.put('/reports/battery/settings', {
      deliveryScheduleId: schedule,
    });
  };

  const updateContacts = async (type, id) => {
    const { data } = await Api[type](`/reports/battery/contacts/${id}`);
  };

  return (
    <div className="contact-list">
      <div className="d-flex align-items-center justify-content-between mb-3">
        <Typography>Delivery Schedule</Typography>
        <Select
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: 'var(--c-primary)',
            },
          })}
          className="delivery-schedule"
          options={scheduleOpts}
          onChange={onChangeDeliverySchedule}
          value={deliverySchedule}
        ></Select>
      </div>
      <div className="mb-3">
        <Typeahead
          ref={typeaheadRef}
          id="contacts"
          placeholder="Search..."
          options={
            contacts &&
            contacts
              .filter((c) => c.emailAddress !== null)
              .map((c, i) => {
                return {
                  id: c.id,
                  label: c.emailAddress || c.phoneNumber,
                };
              })
          }
          onChange={(selected) => {
            addNotified(selected[0]);
            typeaheadRef.current.clear();
          }}
        ></Typeahead>
      </div>
      <div>
        {notified.map((notify) => {
          return (
            <div key={notify.id} className="d-flex align-items-center justify-content-between mb-1">
              <Typography>{notify.label}</Typography>
              <Icon
                name="add"
                variant="light"
                size="lg"
                disabled={false}
                onClick={(e) => {
                  e.preventDefault();
                  removeNotified(notify.id);
                }}
                showPointer={true}
                className="sonarIcon-rotate-45 align-sub"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
