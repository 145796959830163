import './Icon.scss';
import { Size, Variant } from '@types';
import classnames from 'classnames';
import { motion, Transition } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { HTMLAttributes } from 'react';
import { Button } from 'react-bootstrap';
import { noop } from '../../utils';

interface IconProps {
  name: string; // TODO: make strings with |
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  className?: string;
  variant?: Variant;
  size?: Size;
  containerClassName?: string;
  style?: HTMLAttributes<HTMLButtonElement>;
  title?: string;
  transition?: Transition;
  isOpen?: boolean;
  showPointer?: boolean;
  traces?: number;
  advancedAsset?: boolean;
}

function Icon({
  name,
  disabled = true,
  onClick = noop,
  className = '',
  size = '2x',
  containerClassName = '',
  title = '',
  variant,
  transition = undefined,
  isOpen = false,
  showPointer,
  traces,
  advancedAsset,
}: IconProps) {
  const iconClassName = classnames(`sonarIcon sonarIcon-${name} fs-${size}`, className);

  const rotationAnim = {
    init: {
      transform: 'rotate(0deg)',
    },
    clicked: {
      transform: 'rotate(180deg)',
    },
  };

  return (
    <Button
      type="button"
      variant={variant}
      className={`${containerClassName} ${showPointer ? 'cursor-pointer' : 'cursor-default'} ${
        advancedAsset && traces ? 'primary-background' : 'secondary-background'
      }`}
      title={title}
      disabled={disabled}
      onClick={onClick}
    >
      <motion.i
        className={iconClassName}
        variants={rotationAnim}
        //if component has animation, then start its animation based on whether it was clicked or not
        animate={transition !== undefined ? (isOpen ? 'clicked' : 'init') : ''}
        transition={transition}
      />
    </Button>
  );
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,

  // defaults
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.string,
  containerClassName: PropTypes.string,
  style: PropTypes.object,
  title: PropTypes.string,
  traces: PropTypes.number,
  advancedAsset: PropTypes.bool,
};

export default Icon;
