import './HomeStats.scss';
import { useContext, useEffect, useState } from 'react';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import Select2 from 'react-select';
import { ThemeContext } from '../../context/ThemeContext';
import { Api } from '../../services';
import { Text } from '..';
import DeviceChart from './DeviceChart';

async function fetchDeviceData({ type }) {
  if (!type) return;
  const { data } = await Api.get(`/${type}/counts`);
  return data;
}

function HomeStats({ type, index, edit, isValid, remove, updateWidget }) {
  const { theme } = useContext(ThemeContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const deviceRes = useQuery<any>([`${type}-staus`], () => fetchDeviceData({ type }));
  const history = useHistory();
  const types = [
    { value: 'Gateways', label: 'Gateways', index: index },
    { value: 'Beacons', label: 'Beacons', index: index },
  ];

  //forces update of plotly chart when theme is changed. otherwise it wont update until the page is refreshed
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, [theme]);

  useEffect(() => {
    if (type) {
      updateWidget({ index: index, isValid: true });
    }
  }, [type, index, updateWidget]);

  const renderTooltip = (tip) => <Tooltip id="button-tooltip">{tip}</Tooltip>;

  if (edit) {
    return (
      <div>
        <Card.Header className="fs-md fw-bold d-flex align-items-center justify-content-between dashboard-device-status-header-edit">
          {!type ? (
            <div> New Status </div>
          ) : (
            <div className="d-flex align-items-center justify-content-between">
              <div
                className={`icon-wrap d-flex align-items-center text-end p-2 ${type
                  .toLowerCase()
                  .slice(0, -1)}`}
              >
                <i
                  className={`sonarIcon sonarIcon-${type
                    .toLowerCase()
                    .slice(0, -1)} cursor-pointer`}
                />
              </div>
              &nbsp; {type}
            </div>
          )}
          <div className="d-flex align-items-center justify-content-between">
            {!isValid && (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(
                  'Please make selections in the following fields or remove the widget in order to save this layout.',
                )}
              >
                <i className="ignore-click sonarIcon sonarIcon-warning align-sub fs-lg" />
              </OverlayTrigger>
            )}
            <Button
              className="delete-icon ignore-click"
              variant="light"
              onClick={() => {
                remove(index);
              }}
            >
              <i className="ignore-click sonarIcon sonarIcon-add sonarIcon-rotate-45 align-sub fs-lg" />
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Select2
            className="select-2"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: 'var(--c-primary)',
              },
            })}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
              option: (styles, state) => ({
                ...styles,
                backgroundColor: state.isSelected ? 'var(--c-primary)' : null,
                color: state.isSelected ? 'white' : 'black',
                '&:hover': {
                  backgroundColor: state.isSelected ? 'var(--c-primary)' : '#e0ecfc',
                },
              }),
              singleValue: (base, style) => ({
                ...base,
                color: 'hsl(0, 0%, 50%)',
              }),
            }}
            autoFocus={!isValid}
            options={types}
            placeholder="Select a New Type"
            onChange={(e) => updateWidget(e)}
            defaultValue={type}
          />
        </Card.Body>
      </div>
    );
  }

  if (!isLoading)
    return (
      <div className="overflow-hidden">
        <Card.Header className="d-flex flex-row justify-content-between align-items-center dashboard-device-status-header">
          <Button
            className="d-flex align-items-center text-nowrap fs-md fw-bold"
            variant="light"
            onClick={() => {
              history.push(`admin/device-management/${type}`);
            }}
          >
            <div
              className={`icon-wrap d-flex align-items-center text-end p-2 ${type
                .toLowerCase()
                .slice(0, -1)}`}
            >
              <i
                className={`sonarIcon sonarIcon-${type.toLowerCase().slice(0, -1)} cursor-pointer`}
              />
            </div>
            &nbsp; {type}
          </Button>
          {edit && (
            <Button
              className="delete-icon ignore-click"
              variant="light"
              onClick={() => {
                remove(index);
              }}
            >
              <i className="ignore-click sonarIcon sonarIcon-add sonarIcon-rotate-45 align-sub fs-lg" />
            </Button>
          )}
        </Card.Header>
        {deviceRes.isLoading && (
          <Card.Body className="d-flex flex-row justify-content-center align-items-center dashboard-device-status-body-loading">
            <Text className="fw-bold fs-md d-flex flex-row justify-content-center align-items-center loading-txt">
              ...Loading
            </Text>
          </Card.Body>
        )}
        {!deviceRes.isLoading && deviceRes.data.total !== 0 && (
          <Card.Body className="d-flex flex-row justify-content-center align-items-center dashboard-device-status-body">
            <div className="d-flex flex-row justify-content-center align-items-center device-chart-div overflow-hidden">
              <DeviceChart
                type={type}
                online={deviceRes.data.online}
                offline={deviceRes.data.offline}
                inInventory={deviceRes.data.inInventory}
              />
            </div>
          </Card.Body>
        )}
        {!deviceRes.isLoading && deviceRes.data.total === 0 && (
          <Card.Body className="d-flex flex-row justify-content-center dashboard-device-status-body-no-data">
            <div className="fs-md fw-bold d-flex flex-row justify-content-center align-items-center">
              There are currently no {type}.
            </div>
          </Card.Body>
        )}
      </div>
    );
}

export default HomeStats;
