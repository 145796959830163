import { Card, DropdownButton, OverlayTrigger, Button, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { useQuery } from 'react-query';
import BeaconUpload from '../../screens/bulkUpload/BeaconUpload';
import GatewayUpload from '../../screens/bulkUpload/GatewayUpload';
import { Api } from '../../services';
import { isReadOnly, isUser } from '../../utils/authUtils';
import './DeviceStats.scss';
import { useState } from 'react';

async function fetchDeviceData({ deviceType }) {
  const { data } = await Api.get(`/${deviceType}/counts`);
  return data;
}

function DeviceStats({ deviceType, activeKey, disableButton, onSelect, gridRefresh }) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const deviceRes = useQuery<any>([`${deviceType}-status`], () => fetchDeviceData({ deviceType }));
  const TABS = [
    {
      key: 'Gateways',
    },
    {
      key: 'Beacons',
    },
  ];
  const renderTooltip = (tip) => <Tooltip id="button-tooltip">{tip}</Tooltip>;

  return (
    <Card className="mb-3">
      <Card.Header className="d-flex flex-row justify-content-between align-items-center">
        <Tabs className="fs-lg fw-bold" activeKey={activeKey} onSelect={onSelect}>
          {TABS.map((tab) => (
            <Tab key={tab.key} eventKey={tab.key} title={tab.key} disabled={disableButton} />
          ))}
        </Tabs>
        {isUser() === false && isReadOnly() === false && (
          <DropdownButton id="Add Devices" className="me-3" variant="secondary" title="Add Devices">
            <BeaconUpload gridRefresh={gridRefresh} />

            <GatewayUpload gridRefresh={gridRefresh} />
          </DropdownButton>
        )}
      </Card.Header>

      <div
        className={`collapse-wrap-btn d-flex justify-content-end me-2 ${
          isCollapsed ? '' : 'position-absolute'
        }`}
      >
        <Button onClick={() => setIsCollapsed(!isCollapsed)} className="device-stats-collapse-btn">
          <i
            className={`sonarIcon sonarIcon-right-arrow align-sub fs-lg ${
              isCollapsed ? 'close' : 'open'
            }`}
          />
        </Button>
      </div>

      {!isCollapsed && (
        <div className="device-mgmt-stats-body">
          <div className="w-100 status-counts">
            <div className="status-text">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(
                  `Total number of ${deviceType} available in this Application, separated by reading status.`,
                )}
              >
                <p className="fw-bold fs-md ms-4 mt-2">Status Counts:</p>
              </OverlayTrigger>
            </div>

            <div className="d-flex flex-row ms-4 wrap-data-count">
              <div className="d-flex me-auto">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    `Total number of ${deviceType} that have recently generated a reading in this Application.`,
                  )}
                >
                  <p className="fw-bold fs-md online">Online: &nbsp;</p>
                </OverlayTrigger>
                <span className="fw-bold fs-md online">{deviceRes.data?.online}</span>
              </div>
              <div className="d-flex me-auto">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    `Total number of ${deviceType} that have not recently generated a reading in this Application.`,
                  )}
                >
                  <p className="fw-bold fs-md offline">Offline: &nbsp;</p>
                </OverlayTrigger>
                <span className="fw-bold fs-md offline">{deviceRes.data?.offline}</span>
              </div>
              <div className="d-flex me-auto">
                <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip(
                    `Total number of ${deviceType} associated with this Application but not currently in use.`,
                  )}
                >
                  <p className="fw-bold fs-md inventory">In Inventory: &nbsp;</p>
                </OverlayTrigger>
                <span className="fw-bold fs-md inventory">{deviceRes.data?.inInventory}</span>
              </div>
            </div>
          </div>

          <div className="w-100 deployment-counts">
            <div className="deployment-text">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(
                  `Total number of ${deviceType} that have returned at least one reading in this Application and are not deleted.`,
                )}
              >
                <p className="fw-bold fs-md ms-4 mt-2">Deployment Counts:</p>
              </OverlayTrigger>
            </div>
            {activeKey === 'Gateways' && (
              <div className="d-flex flex-row justify-content-center">
                <div className="d-flex ms-4 me-auto">
                  <p className="fw-bold fs-md deployed">Deployed: &nbsp;</p>
                  <span className="fw-bold fs-md deployed">{deviceRes.data?.deployed}</span>
                </div>
              </div>
            )}

            {activeKey === 'Beacons' && (
              <div className="d-flex flex-row justify-content-center">
                <div className="d-flex ms-4 me-auto">
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(
                      `Total number of Active Beacons that have returned a reading and are not deleted.`,
                    )}
                  >
                    <p className="fw-bold fs-md deployed">Active Deployed: &nbsp;</p>
                  </OverlayTrigger>
                  <span className="fw-bold fs-md deployed">{deviceRes.data?.activeDeployed}</span>
                </div>
                <div className="d-flex me-auto">
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip(
                      `Total number of Passive Beacons that have returned a reading and are not deleted.`,
                    )}
                  >
                    <p className="fw-bold fs-md deployed">Passive Deployed: &nbsp;</p>
                  </OverlayTrigger>
                  <span className="fw-bold fs-md deployed">{deviceRes.data?.passiveDeployed}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </Card>
  );
}

export default DeviceStats;
