import {
  EditCellRenderer,
  LinkCellRenderer,
  MapThumbnailCellRenderer,
  MapTypeCellRenderer,
  V2EditCellRenderer,
} from '@components';
import { Api } from '@services';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from '../../../components';

const MySwal = withReactContent(Swal);

// Legacy Map Column List
export const mapListColumnDefs = [
  {
    headerName: '',
    colId: 'unassignBeacon',
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    cellClass: 'd-flex flex-row justify-content-center',
    cellRenderer: EditCellRenderer,
  },
  {
    headerName: '',
    headerClass: '',
    field: 'mapType',
    colId: 'mapType',
    width: 55,
    minWidth: 55,
    maxWidth: 55,
    cellRenderer: MapTypeCellRenderer,
    cellRendererParams: (params) => ({
      iconType: 'world',
      showIcon: params.data.mapType === 'Geospatial',
    }),
    tooltipValueGetter: (params) => {
      if (params.data.mapType === 'Geospatial') {
        return 'This is a Geospatial Map associated with a real-world location.';
      }
      return null;
    },
  },
  {
    headerName: '',
    headerClass: '',
    field: 'mapTypeSecondary',
    colId: 'mapTypeSecondary',
    width: 60,
    minWidth: 60,
    maxWidth: 60,
    cellRenderer: MapTypeCellRenderer,
    cellRendererParams: (params) => ({
      iconType: 'sparkle',
      showIcon: params.data.mapType === 'Static' || params.data.mapType === 'Geospatial',
    }),
    tooltipValueGetter: (params) => {
      if (params.data.mapType !== 'Legacy') {
        return 'This is a Beta Map that can be viewed as a Legacy Map or Beta Map.';
      }
      return null;
    },
  },
  {
    headerName: 'Map Name',
    headerClass: 'ag-theme-custom-text-right',
    field: 'name',
    colId: 'name',
    minWidth: 200,
    cellRenderer: LinkCellRenderer,
    cellRendererParams: ({ data }) => ({
      to: `/maps/${data.id}`,
      text: data.name || '--',
    }),
    headerComponentParams: {
      template: '<div><span class="maps-header">Map Name &nbsp;</span><div>',
    },
  },
  {
    headerName: 'Places',
    headerClass: 'ag-theme-custom-text-right',
    field: 'places',
    colId: 'places',
    type: 'rightAligned',
    headerComponentParams: {
      template:
        '<div><span class="maps-header">Places &nbsp;</span> <i class="sonarIcon sonarIcon-16 sonarIcon-place align-sub" /><div>',
    },
  },
  {
    headerName: 'Gateways',
    headerClass: 'ag-theme-custom-text-right',
    field: 'gateways',
    colId: 'gateways',
    type: 'rightAligned',
    headerComponentParams: {
      template:
        '<div><span class="maps-header">Gateways &nbsp;</span> <i class="sonarIcon sonarIcon-16 sonarIcon-gateway align-sub"/><div>',
    },
  },
  {
    headerName: 'Assets',
    headerClass: 'ag-theme-custom-text-right',
    field: 'assets',
    colId: 'assets',
    type: 'rightAligned',
    headerComponentParams: {
      template:
        '<div><span class="maps-header">Assets &nbsp;</span> <i class="sonarIcon sonarIcon-16 sonarIcon-asset align-sub" /><div>',
    },
    valueGetter(params) {
      const { data } = params;
      return data.assets + data.stationaryAssets;
    },
  },
  {
    headerName: '',
    headerClass: 'ag-theme-custom-text-right',
    field: 'maps',
    colId: 'maps',
    type: 'rightAligned',
    cellRenderer: MapThumbnailCellRenderer,
    cellRendererParams: ({ data }) => ({
      to: `/maps/${data.id}`,
      img64: `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/api/v2/maps/${data.id}/image?size=64`,
      img512: `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/api/v2/maps/${data.id}/image?size=512`,
    }),
  },
];

// Beta Map Column List
export const mapListV2ColumnDefs = (onDeleteSuccess) => [
  {
    headerName: '',
    colId: 'unassignBeacon',
    width: 100,
    minWidth: 100,
    maxWidth: 100,
    cellClass: 'd-flex flex-row justify-content-center',
    cellRenderer: V2EditCellRenderer,
    cellRendererParams(params) {
      const { data, api, node } = params;
      return {
        async onClickDelete() {
          let userResponsePromise = new Promise((resolve, reject) => {
            MySwal.fire({
              allowOutsideClick: false,
              title: 'Are you sure?',
              text: 'This Map will be permanently deleted.',
              icon: 'warning',
              showCancelButton: true,
              cancelButtonText: 'No',
              confirmButtonText: 'Yes',
            })
              .then((submit) => {
                resolve(submit.isConfirmed);
              })
              .catch((err) => {
                reject(err);
              });
          });

          let userResponse;

          try {
            userResponse = await userResponsePromise;
          } catch (error) {
            userResponse = false;
            console.log(error);
          }

          if (userResponse) {
            try {
              await Api.delete(`v2/maps/${data.mapId}`);
              api.gridBodyCtrl.gridOptionsService.gridOptions.rowData.splice(node.rowIndex, 1);
              api.setRowData(api.gridBodyCtrl.gridOptionsService.gridOptions.rowData);
              onDeleteSuccess();
              toast.success('Map was successfully deleted!');
            } catch (error) {
              console.log(error);
              toast.error('Failed to delete map.');
            }
          }
        },
      };
    },
  },
  {
    headerName: '',
    headerClass: '',
    field: 'mapType',
    colId: 'mapType',
    width: 55,
    minWidth: 55,
    maxWidth: 55,
    cellRenderer: MapTypeCellRenderer,
    cellRendererParams: (params) => ({
      iconType: 'world',
      showIcon: params.data.mapType === 'Geospatial',
    }),
    tooltipValueGetter: (params) => {
      if (params.data.mapType === 'Geospatial') {
        return 'This is a Geospatial Map associated with a real-world location.';
      }
      return null;
    },
  },
  {
    headerName: 'Map Name',
    headerClass: 'ag-theme-custom-text-right',
    field: 'name',
    colId: 'name',
    minWidth: 200,
    cellRenderer: LinkCellRenderer,
    cellRendererParams: ({ data }) => ({
      to: `/mapbeta/${data.mapId}`,
      text: data.name || '--',
    }),
    headerComponentParams: {
      template: '<div><span class="maps-header">Map Name &nbsp;</span><div>',
    },
  },
  {
    headerName: 'Places',
    headerClass: 'ag-theme-custom-text-right',
    field: 'placesCount',
    colId: 'placesCount',
    type: 'rightAligned',
    headerComponentParams: {
      template:
        '<div><span class="maps-header">Places &nbsp;</span> <i class="sonarIcon sonarIcon-16 sonarIcon-place align-sub" /><div>',
    },
  },
  {
    headerName: 'Gateways',
    headerClass: 'ag-theme-custom-text-right',
    field: 'gatewaysCount',
    colId: 'gatewaysCount',
    type: 'rightAligned',
    headerComponentParams: {
      template:
        '<div><span class="maps-header">Gateways &nbsp;</span> <i class="sonarIcon sonarIcon-16 sonarIcon-gateway align-sub"/><div>',
    },
  },
  {
    headerName: 'Assets',
    headerClass: 'ag-theme-custom-text-right',
    field: 'assetsCount',
    colId: 'assetsCount',
    type: 'rightAligned',
    headerComponentParams: {
      template:
        '<div><span class="maps-header">Assets &nbsp;</span> <i class="sonarIcon sonarIcon-16 sonarIcon-asset align-sub" /><div>',
    },
  },
  {
    headerName: '',
    headerClass: 'ag-theme-custom-text-right',
    field: 'maps',
    colId: 'maps',
    type: 'rightAligned',
    cellRenderer: MapThumbnailCellRenderer,
    cellRendererParams: ({ data }) => ({
      to: `mapbeta/${data.mapId}`,
      img64: `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/api/v2/maps/${data.mapId}/image?size=64`,
      img512: `${window._sonar_env.REACT_APP_BASE_API_URL_V2}/api/v2/maps/${data.mapId}/image?size=512`,
    }),
  },
];
