import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import * as L from 'leaflet';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { createBeaconsLayerGroup } from '../../services/leafletLayerFactories/leafletBeaconFactory';
// import { createLocationsLayerGroup } from '../../services/leafletLayerFactories/leafletLocationFactory';
import { createGatewaysLayerGroup } from '../../services/leafletLayerFactories/leafletGatewayFactory';
import { createZonesLayerGroup } from '../../services/leafletLayerFactories/leafletZoneFactory';
let drawControl;
let zones;

const fallBackImage =
  'https://thinaer.io/wp-content/uploads/2020/10/Thinaer_Wordmark_DarkBlue_FINAL-01.svg';

class MapEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      image: fallBackImage,
      temporaryMarkers: [],
    };

    this.onItemAdded = this.onItemAdded.bind(this);
    this.onItemMoved = this.onItemMoved.bind(this);
    this.submitZoneEdit = this.submitZoneEdit.bind(this);
  }

  // Add Temporary Markers when user is adding Gateway/Beacon to Map
  addTemporaryMarker(layer, markerType) {
    this.setState((prevState) => {
      return {
        temporaryMarkers: [...prevState.temporaryMarkers, { layer, markerType }],
      };
    });
  }
  // Clear Marker when user confirms/cancels
  clearTemporaryMarkers() {
    this.setState({
      temporaryMarkers: [],
    });
  }

  drawCanvas(map) {
    if (!map) {
      return;
    }

    map.eachLayer(function (layer) {
      if (layer._url == null) {
        map.removeLayer(layer);
      }
    });

    zones = createZonesLayerGroup(this.props.map.zones);
    zones.addTo(map);
    const gateways = createGatewaysLayerGroup(this.props.map.gateways);
    gateways.addTo(map);
    const beacons = createBeaconsLayerGroup(this.props.map.beacons);
    beacons.addTo(map);

    if (this.props.editGateway) {
      gateways.eachLayer((gateway) => {
        if (gateway.gatewayIndex === this.props.editGateway) {
          gateway.options.editing = {};
          gateway.editing.enable();
        }
      });
    }

    if (this.props.addGateway) {
      new L.Draw.Marker(map, drawControl.options.marker).enable();
    }

    if (this.props.editZone) {
      zones.eachLayer((zone) => {
        if (zone.zoneIndex === this.props.editZone) {
          zone.options.editing = {};
          zone.editing.enable();
        }
      });
    }

    if (this.props.addZone) {
      new L.Draw.Polygon(map, { showLength: false }).enable();
    }

    if (this.props.editBeacon) {
      beacons.eachLayer((beacon) => {
        if (beacon.beaconIndex === this.props.editBeacon) {
          beacon.options.editing = {};
          beacon.editing.enable();
        }
      });
    }

    if (this.props.addBeacon) {
      new L.Draw.Marker(map, drawControl.options.marker).enable();
    }

    if (this.props.addMeasure) {
      new L.Draw.Polyline(map, { showLength: false }).enable();
    }

    // Display temporary markers
    this.state.temporaryMarkers.forEach((temporaryMarker) => {
      map.addLayer(temporaryMarker.layer);
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { map } = this.state;
    this.drawCanvas(map);
  }

  createMapWithImageFallBack(image) {
    this.setState({ image: image });
    let x = this.props.map.width || 1000;
    let y = this.props.map.height || 1000;
    let bounds = [
      [0, 0],
      [y, x],
    ];

    let map = L.map('map', {
      zoomControl: true,
      crs: L.CRS.Simple,
      maxBoundsViscosity: 0.8,
      maxBounds: bounds,
      attributionControl: false,
      minZoom: -5,
    });

    let drawPluginOptions = {
      draw: false,
      edit: false,
    };

    drawControl = new L.Control.Draw(drawPluginOptions);
    map.addControl(drawControl);

    L.imageOverlay(image, bounds).addTo(map);
    map.fitBounds(bounds);

    this.setState({ map: map, loaded: true });
    this.drawCanvas(map);
    map.on(L.Draw.Event.CREATED, this.onItemAdded);
    map.on(L.Draw.Event.EDITMOVE, this.onItemMoved);
  }

  componentDidMount() {
    this.props.mapURL
      ? this.createMapWithImageFallBack(this.props.mapURL)
      : this.createMapWithImageFallBack(fallBackImage);
  }

  render() {
    return (
      <div>
        <div
          id="map"
          className="w-100 h-100 m-0 min-height-55vh"
          ref={(mapContainer) => (this.mapContainer = mapContainer)}
        />
      </div>
    );
  }

  submitZoneEdit() {
    zones.eachLayer((zone) => {
      if (zone.zoneIndex === this.props.editZone) {
        this.props.onZoneChanged(this.props.editZone, zone._latlngs[0]);
        return;
      }
    });
  }

  onItemMoved(event) {
    const layer = event.layer;
    if (this.props.editGateway) {
      this.props.onGatewayChanged(this.props.editGateway, layer._latlng);
    }
    if (this.props.editBeacon) {
      this.props.onBeaconChanged(this.props.editBeacon, layer._latlng);
    }
  }

  onItemAdded(event) {
    const layer = event.layer;

    // Adding Gateway
    if (this.props.addGateway) {
      this.props.onGatewayChanged(this.props.addGateway, layer._latlng);
      this.addTemporaryMarker(layer, 'gateway');
    }
    // Adding Places
    if (this.props.addZone) {
      this.props.onZoneChanged(this.props.addZone, layer._latlngs[0]);
      this.addTemporaryMarker(layer, 'zone');
    }
    // Adding Beacons
    if (this.props.addBeacon) {
      this.props.onBeaconChanged(this.props.addBeacon, layer._latlng);
      this.addTemporaryMarker(layer, 'beacon');
    }
    // Adding Static Measurement
    if (this.props.addMeasure) {
      let a = layer._latlngs[0].lat - layer._latlngs[1].lat;
      let b = layer._latlngs[0].lng - layer._latlngs[1].lng;
      let c = Math.sqrt(a * a + b * b);
      this.props.onMeasureAdded(c);
    }
  }

  saveChangesHandleZoneSave = () => {
    this.submitZoneEdit();
  };
}

MapEdit.propTypes = {
  map: PropTypes.object,
  url: PropTypes.string,
  addGateway: PropTypes.number,
  editGateway: PropTypes.number,
  onGatewayChanged: PropTypes.func,
  editZone: PropTypes.number,
  addZone: PropTypes.number,
  onZoneChanged: PropTypes.func,
  editBeacon: PropTypes.number,
  addBeacon: PropTypes.number,
  onBeaconChanged: PropTypes.func,
};

export default MapEdit;
