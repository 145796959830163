import '../../../../../scss/_export.module.scss';
import './BeaconSearch.scss';
import { Icon, PaginatedSearch, Text } from '@components';
import { useTableState } from '@react';
import { Api } from '@services';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { V2FilterPayload } from '../../../../AssetFilter/functions/V2FilterPayload';

export const ASSET_TYPE = {
  NEW: 'NEW',
  EXISTING: 'EXISTING',
};

const limit = 50;

async function fetchData({ search, page = 1 }) {
  const defaultPayload = V2FilterPayload([], 'and', (page - 1) * limit, [], limit, [], search);

  try {
    const { data } = await Api.post(`v2/beacons/filter`, defaultPayload);
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

function BeaconSearch({
  handleExistingAsset,
  handleNewAsset,
  onSelectBeacon,
  initAssetType = '',
  onAssetDetailsPage,
  selectedBeacon,
}) {
  const [assetType, setAssetType] = useState(initAssetType); // NEW|EXISTING
  const [beacon, setBeacon] = useState(selectedBeacon);
  const [filterAssigned, setFilterAssigned] = useState(false);

  const { search, setSearch, page, setPage } = useTableState({ initSearch: selectedBeacon?.name });

  const _setBeacon = (beacon) => {
    setBeacon(beacon);
    onSelectBeacon(beacon);
  };

  const res = useQuery(
    ['beaconSearch-listBeacons', page, { search }],
    () => fetchData({ page, search }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  const isNextBtnDisabled = () => {
    if (!beacon || !assetType) return true;

    return false;
  };

  const onSelectItem = ({ item: beacon, hideItems }) => {
    setSearch(beacon.name);
    hideItems();
    _setBeacon(beacon);
  };

  const renderItem = ({ item }) => {
    const renderAttachedAsset = () => {
      if (item.assetName)
        return (
          <Text className="attached-text">
            Attached to Asset <Text className="asset-name">{item.assetName}</Text>.
          </Text>
        );
      return <Text>Not Attached to an Asset.</Text>;
    };

    return (
      <div>
        <Text className="fs-lg item-name">{item.name}</Text>
        <br />
        <Text className="device-id">{item.deviceId}</Text>
        <br />
        {renderAttachedAsset()}
      </div>
    );
  };

  //Func to display beacon name text when selected.
  const renderSelectedItem = ({ item: beacon }) => {
    const beaconName = beacon?.name ? <Text variant="primary">{beacon.name}</Text> : '';
    return <Text>Selected Beacon {beaconName}</Text>;
  };

  const onClearSelectedItem = () => {
    _setBeacon(null);
    setSearch('');
  };

  const renderFilter = () => {
    return (
      <Form.Check
        label="Filter Assigned Beacons"
        id={'filter-assigned-beacons-checkbox'}
        checked={filterAssigned}
        onChange={(e) => setFilterAssigned(e.target.checked)}
      />
    );
  };

  const filteredBeacons = filterAssigned
    ? res.data?.items.filter((beacon) => !beacon.assetName)
    : res.data?.items;

  return (
    <Container
      className={`text-center d-flex flex-column justify-content-between h-100 add-beacon-container`}
    >
      <Row>
        <Col xs={12}>
          <div className="mb-4">
            <Icon
              name="search"
              variant="dark"
              size="lg"
              className="fw-bold"
              disabled={false}
              showPointer={false}
            />
            <Text className="fs-lg mb-3" variant="dark">
              Search Beacon by Name or MAC ID
            </Text>
          </div>
          <PaginatedSearch
            items={filteredBeacons}
            search={search}
            setSearch={setSearch}
            page={page}
            setPage={setPage}
            pageCount={Math.ceil(res.data?.count / limit)}
            loading={res.isLoading || res.isFetching}
            renderItem={renderItem}
            refetch={res.refetch}
            placeholder="Search Beacons"
            onSelectItem={onSelectItem}
            renderSelectedItem={renderSelectedItem}
            onClearSelectedItem={onClearSelectedItem}
            renderExtra={renderFilter}
            initSelectedItem={selectedBeacon}
          />
        </Col>

        <Col xs={12} className="my-5"></Col>
        {!onAssetDetailsPage && (
          <Col xs={12}>
            <Text as="p" className="fs-lg mb-5">
              Creating a New Asset or adding to an Existing Asset?
            </Text>
            <div className="d-flex justify-content-center align-items-center">
              <Button
                variant={assetType === ASSET_TYPE.NEW ? 'primary' : 'secondary'}
                className="my-3 beacon-asset-btn"
                onClick={() => setAssetType(ASSET_TYPE.NEW)}
              >
                New Asset
              </Button>
              <div className="divider"></div>
              <Button
                variant={assetType === ASSET_TYPE.EXISTING ? 'primary' : 'secondary'}
                className="my-3 beacon-asset-btn"
                onClick={() => setAssetType(ASSET_TYPE.EXISTING)}
              >
                Existing Asset
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <Row>
        <Col>
          <Button
            variant="primary"
            className="my-2 next-btn"
            disabled={isNextBtnDisabled()}
            onClick={assetType === ASSET_TYPE.NEW ? handleNewAsset : handleExistingAsset}
          >
            Next
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

BeaconSearch.propTypes = {
  handleNewAsset: PropTypes.func,
  handleExistingAsset: PropTypes.func.isRequired,
  onSelectBeacon: PropTypes.func.isRequired,

  // defaults
  initAssetType: PropTypes.string,
  selectedBeacon: PropTypes.object,
};

export default BeaconSearch;
