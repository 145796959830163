import { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { CsvModalV2, Loading } from '../../components';

const MySwal = withReactContent(Swal);

function useOutsideAlerter(ref, isModified, callBack) {
  useEffect(() => {
    function handleClickOutside(event) {
      const selectedNav = event.target.closest('a');
      if (ref.current && !ref.current.contains(event.target) && !isModified) {
        callBack();
      } else if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.className.includes('swal2')
      ) {
        MySwal.fire({
          allowOutsideClick: false,
          title: 'Are you sure you want to leave?',
          text: 'Any CSV files will not be available once this window is closed.',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        }).then((submit) => {
          if (submit.isConfirmed) {
            callBack();
            if (selectedNav) {
              window.location = selectedNav.attributes.href.value;
            }
          } else {
            MySwal.close();
          }
        });
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModified]);
}

function BulkDeleteScreen({ closeDock, itemName, isLoading, response, onTemplate, onUpload }) {
  const wrapperRef = useRef(null);
  const [isModified, setIsModified] = useState(false);
  const [page, setPage] = useState('CsvModal');

  useEffect(() => {
    if (response) {
      setPage('ResultsModal');
    }
    if (isLoading === true) {
      setPage('Loading');
    }
  }, [isLoading, response]);

  useOutsideAlerter(wrapperRef, isModified, closeDock);

  const text = `Download the CSV template to include the ${itemName} you would like to delete. Then upload the completed CSV.`;
  const renderCSVModal = (
    <CsvModalV2
      title={`Delete ${itemName}`}
      text={text}
      onTemplate={() => onTemplate()}
      onUpload={(e) => onUpload(e)}
      setModified={(e) => setIsModified(e)}
      itemName={itemName}
      isDock={true}
    />
  );

  const renderLoading = (
    <div className=" d-flex justify-content-center align-items-center w-100 h-100">
      <Loading />
    </div>
  );

  const pageSwitcher = () => {
    switch (page) {
      case 'CsvModal':
        return renderCSVModal;
      case 'Loading':
        return renderLoading;
    }
  };

  return (
    <div ref={wrapperRef} className="d-flex flex-column p-3 h-100 w-100">
      {pageSwitcher()}
    </div>
  );
}

export default BulkDeleteScreen;
