import './EditDeleteCellRenderer.scss';
import '../../../../scss/variables.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { gridsSetEditDeleteCRAction } from '../../../../redux';
import { isAdmin, isSystemAdmin } from '../../../../utils';
import Icon from '../../../Icon/Icon';

function EditDeleteCellRenderer({
  id,
  onClickEdit,
  onClickDelete,
  onClickCancelEdit,
  onClickConfirmEdit,
  api,
  editDeleteCR,
  setEditDeleteCR,
}) {
  const authorized = isAdmin() || isSystemAdmin();

  const resetContext = () => {
    const ctx = { id: null, isEditing: false };
    setEditDeleteCR(ctx);
  };

  const _onClickEdit = () => {
    const ctx = { id, isEditing: true };
    setEditDeleteCR(ctx);
    onClickEdit();
  };

  const _onClickConfirmEdit = () => {
    api.stopEditing();
    resetContext();
    onClickConfirmEdit();
  };

  const _onClickCancelEdit = () => {
    api.stopEditing(true);
    resetContext();
    onClickCancelEdit();
  };

  useEffect(() => {
    resetContext();
  }, []);

  if (editDeleteCR.isEditing && editDeleteCR.id !== id) return '';

  const className = classnames('edit-delete-cellRenderer', { show: editDeleteCR.isEditing });
  return (
    <>
      {authorized && (
        <div className={className}>
          {editDeleteCR.isEditing ? (
            <Icon
              className="align-sub"
              name="check"
              variant="light"
              size="lg"
              disabled={false}
              showPointer={true}
              onClick={_onClickConfirmEdit}
            />
          ) : (
            <Icon
              className="align-sub"
              name="edit"
              variant="light"
              size="lg"
              disabled={false}
              showPointer={true}
              onClick={_onClickEdit}
            />
          )}

          <Icon
            name="add"
            size="lg"
            disabled={false}
            onClick={
              editDeleteCR.isEditing
                ? _onClickCancelEdit
                : () => {
                    onClickDelete();
                  }
            }
            showPointer={true}
            className="sonarIcon-rotate-45 align-sub"
            variant="light"
          />
        </div>
      )}
    </>
  );
}

EditDeleteCellRenderer.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onClickEdit: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickCancelEdit: PropTypes.func.isRequired,
  onClickConfirmEdit: PropTypes.func.isRequired,
  api: PropTypes.object.isRequired,
  editDeleteCR: PropTypes.object.isRequired,
  setEditDeleteCR: PropTypes.func.isRequired,
};

const mapStateToProps = ({ grids }) => ({
  editDeleteCR: grids.editDeleteCR,
});

const mapDispatchToProps = (dispatch) => ({
  setEditDeleteCR: (editDeleteCR) => dispatch(gridsSetEditDeleteCRAction({ editDeleteCR })),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDeleteCellRenderer);
