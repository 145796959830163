import '../../components/DeviceStats/HomeStats.scss';
import { useDock } from '@react';
import { useEffect } from 'react';
import { Button, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select2 from 'react-select';
import { DockContainer, Favorites, Text } from '../../components';
import { useApp } from '../../react';
import { setFavoritesAction, store } from '../../redux';
import { Api, favoriteService } from '../../services';

async function fetchCountData({ type }) {
  if (!type) return;
  const { data } = await Api.get(`/${type}`);
  return data;
}

async function fetchFavorites({ appId }) {
  if (!appId) return [];
  const { data } = await favoriteService.getFavorites(appId);
  const group = data.reduce((acc, item) => {
    if (!acc[item.itemType]) {
      acc[item.itemType] = [];
    }
    acc[item.itemType].push(item);
    return acc;
  }, {});
  store.dispatch(setFavoritesAction(group));
  return group;
}

function Counter({ type, favorites, index, edit, isValid, remove, updateWidget }) {
  const types = [
    { value: 'Assets', label: 'Assets', index: index },
    { value: 'Maps', label: 'Maps', index: index },
    { value: 'Places', label: 'Places', index: index },
  ];
  const app = useApp();
  const { renderDock, closeDock } = useDock();
  const countRes = useQuery<any>([`${type}-staus`], () => fetchCountData({ type }));
  const favoritesRes = useQuery<any>(['home-favorites'], () => fetchFavorites({ appId: app?.id }));
  const history = useHistory();

  useEffect(() => {
    if (type) {
      updateWidget({ index: index, isValid: true });
    }
  }, [type, index, updateWidget]);

  const renderFavorites = (type) => {
    let key;
    switch (type) {
      case 'Assets':
        key = '0';
        break;
      case 'Maps':
        key = '1';
        break;
      case 'Places':
        key = '2';
        break;
      default:
        key = '0';
        break;
    }
    renderDock({
      render: () => (
        <DockContainer>
          <Favorites defaultActiveKey={key} onDone={closeDock} />
        </DockContainer>
      ),
    });
  };

  const renderTooltip = (tip) => <Tooltip id="button-tooltip">{tip}</Tooltip>;

  if (edit) {
    return (
      <div>
        <Card.Header className="fs-md fw-bold d-flex align-items-center justify-content-between dashboard-counter-header-edit">
          {!type ? (
            <div> New Counter </div>
          ) : (
            <div className="d-flex align-items-center justify-content-between">
              <div
                className={`icon-wrap d-flex align-items-center text-end p-2 ${type
                  .toLowerCase()
                  .slice(0, -1)}`}
              >
                <i
                  className={`sonarIcon sonarIcon-${type
                    .toLowerCase()
                    .slice(0, -1)} cursor-pointer`}
                />
              </div>
              &nbsp; {type} Counter
            </div>
          )}
          <div className="d-flex align-items-center justify-content-between">
            {!isValid && (
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip(
                  'Please make selections in the following feilds or remove the widget in order to save this layout.',
                )}
              >
                <i className="ignore-click sonarIcon sonarIcon-warning align-sub fs-lg" />
              </OverlayTrigger>
            )}
            <Button
              className="delete-icon ignore-click"
              variant="light"
              onClick={() => {
                remove(index);
              }}
            >
              <i className="ignore-click sonarIcon sonarIcon-add sonarIcon-rotate-45 align-sub fs-lg" />
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Select2
            className="select-2"
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: 'var(--c-primary)',
              },
            })}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
              option: (styles, state) => ({
                ...styles,
                backgroundColor: state.isSelected ? 'var(--c-primary)' : null,
                color: state.isSelected ? 'white' : 'black',
                '&:hover': {
                  backgroundColor: state.isSelected ? 'var(--c-primary)' : '#e0ecfc',
                },
              }),
              singleValue: (base, style) => ({
                ...base,
                color: 'hsl(0, 0%, 50%)',
              }),
            }}
            autoFocus={!isValid}
            options={types}
            placeholder="Select a New Type"
            onChange={(e) => {
              updateWidget(e);
            }}
          />
        </Card.Body>
      </div>
    );
  }

  return (
    <div>
      <Card.Header className="d-flex align-items-center justify-content-between dashboard-counter-header">
        <Button
          className={`fs-md fw-bold d-flex align-items-center text-nowrap ${type
            .toLowerCase()
            .slice(0, -1)}`}
          variant="light"
          onClick={() => {
            history.push(`/${type.toLowerCase()}`);
          }}
        >
          <div
            className={`icon-wrap d-flex align-items-center text-end p-2 ${type
              .toLowerCase()
              .slice(0, -1)}`}
          >
            <i
              className={`sonarIcon sonarIcon-${type.toLowerCase().slice(0, -1)} cursor-pointer`}
            />
          </div>
          &nbsp; {type}
        </Button>
      </Card.Header>
      {countRes.isLoading && (
        <Card.Body className="dashboard-counter-body-loading">
          <Text className="fw-bold fs-1x loading-txt">...Loading</Text>
        </Card.Body>
      )}

      <Card.Body className="d-flex flex-row justify-content-center align-items-center dashboard-counter-body">
        {!countRes.isLoading && !favoritesRes.isLoading && (
          <>
            <div className="d-flex flex-row w-50 justify-content-center d-flex align-items-center text-nowrap">
              <Text className="fw-bold fs-md">Total: &nbsp;</Text>
              <span className="fw-bold fs-md">{countRes?.data?.count}</span>
            </div>
            <div className="d-flex flex-row w-50 justify-content-center">
              <Button
                className="fw-bold fs-md d-flex align-items-center"
                variant="light"
                title={`Favorite ${type}`}
                onClick={() => renderFavorites(type)}
              >
                <i className={`sonarIcon sonarIcon-star-fill ${type.toLowerCase().slice(0, -1)}`} />
                &nbsp;
                <span className="fw-bold">
                  {' '}
                  {favorites[type.slice(0, -1)]?.length ? favorites[type.slice(0, -1)]?.length : 0}
                </span>
              </Button>
            </div>
          </>
        )}
      </Card.Body>
    </div>
  );
}

function mapStateToProps(state) {
  return { favorites: state.favorites.favorites };
}

export default connect(mapStateToProps)(Counter);
