import './Layout.scss';
import { isAdmin, isReadOnly, isSystemAdmin, isUser } from '@utils';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import JoyRide from 'react-joyride';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from '../../../context/ThemeContext';
import Toast from '../../utils/toastComponent';
import Dock from '../Dock/Dock';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';
import { SonarTourModal } from '../SonarTour';
import { AdminSteps, ReadOnlySteps, UserSteps } from '../SonarTour/';

function Layout({ children, progressBar, navigation, dock }) {
  const [scroll, setScroll] = useState('0');
  const [showTourModal, setShowTourModal] = useState(false);
  const [tourStarted, setTourStarted] = useState(false);
  const isDashboard = window.location.pathname === '/dashboard';
  let history = useHistory();

  const listenScroll = (event) => {
    setScroll(event.currentTarget.scrollTop);
  };
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    // Check if there is information about a new registration confirmation
    const newUserConfirmation = localStorage.getItem('newUserConfirmation');
    if (isDashboard && newUserConfirmation) {
      // Display the modal after a delay
      const showTourModalTimeout = setTimeout(() => {
        setShowTourModal(true);
      }, 1400);

      return () => clearTimeout(showTourModalTimeout);
    }
  }, [isDashboard]);

  useEffect(() => {
    // Check if the tour has started and handle modal removal after skipping
    if (tourStarted) {
      const removeModalTimeout = setTimeout(() => {
        setShowTourModal(false);
      }, 1000); // 1000 milliseconds delay (adjust as needed)

      return () => clearTimeout(removeModalTimeout);
    }
  }, [tourStarted]);

  useEffect(() => {
    theme === 'light'
      ? document.documentElement.setAttribute('data-theme', 'light')
      : document.documentElement.setAttribute('data-theme', 'dark');
  }, [theme]);

  const customToolTip = ({
    continuous,
    index,
    isLastStep,
    size,
    step,
    backProps,
    closeProps,
    primaryProps,
    skipProps,
    tooltipProps,
  }: iCustomToolTip) => {
    const tooltipArrowColor =
      step.title === 'Navigation Menu' ? 'white' : 'var(--primary-to-black)';
    return (
      <div className="custom-tool-tip-tour" {...tooltipProps}>
        <div className="c-tool-tip-blue">
          <span className="c-tool-tip-title">{step.title && <h6>{step.title}</h6>}</span>

          <span
            className="c-tool-tip-exit"
            {...skipProps}
            onClick={() => {
              setTourStarted(false);
              localStorage.removeItem('newUserConfirmation');
            }}
          >
            <i className="sonarIcon sonarIcon-add fs-md"></i>
          </span>
        </div>

        <div className="c-tool-tip-content">
          {step.content && <div>{step.content}</div>}
          <div className="c-tool-tip-footer">
            <div>
              {index + 1}/{size}
            </div>
            {index > 0 && (
              <div className="c-tool-back" {...backProps}>
                <i className="sonarIcon sonarIcon-left-arrow fs-sm"></i>
              </div>
            )}
            {!isLastStep ? (
              <div className="c-tool-next" {...primaryProps}>
                <i className="sonarIcon sonarIcon-right-arrow fs-sm"></i>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {/* Setting arrow color dynamically */}
        <style>
          {`
          .__floater__arrow > span > svg > polygon {
            fill: ${tooltipArrowColor} !important;
          }
        `}
        </style>
      </div>
    );
  };

  useEffect(() => {
    const unlisten = history.listen(() => {
      setScroll('0');
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <div className={theme}>
      <div className="h-100 w-100">
        <SonarTourModal
          onStartTour={() => {
            setShowTourModal(false);
            const removeStartTourTimeout = setTimeout(() => {
              setTourStarted(true);
            }, 650);
            return () => clearTimeout(removeStartTourTimeout);
          }}
          onSkipTour={() => {
            setShowTourModal(false);
            localStorage.removeItem('newUserConfirmation');
          }}
          show={showTourModal}
        />

        {tourStarted && isDashboard && (
          <JoyRide
            disableOverlayClose
            tooltipComponent={customToolTip}
            disableBeacon
            continuous={true}
            hideCloseButton
            scrollToFirstStep
            showProgress
            locale={{
              last: 'Completed',
              skip: 'End Tour',
            }}
            showSkipButton
            spotlightPadding={2}
            steps={
              isUser()
                ? UserSteps
                : isReadOnly()
                ? ReadOnlySteps
                : isSystemAdmin()
                ? AdminSteps
                : isAdmin()
                ? AdminSteps
                : ''
            }
            styles={{
              options: {
                backgroundColor: 'var(--primary)',
                primaryColor: 'var(--primary)',
                textColor: '#FFFFFF',
                width: 380,
                zIndex: 2000,
              },
              tooltip: {
                borderRadius: 10,
              },
              buttonBack: {
                color: '#FFFFFF',
              },
            }}
          />
        )}
        {/* Header */}
        <Header scroll={scroll} setShowTourModal={setShowTourModal} />
        <ProgressBar
          now={progressBar.progress}
          className={`layout-progressbar ${navigation.navWidth === '275px' ? 'opened' : 'closed'} ${
            progressBar.inProgress ? 'in-progress' : 'no-progress'
          }`}
        />

        {/* Content */}
        <div className="layout-main-container">
          <div className="layout-inner-container">
            {/* Navigation */}
            <Navigation className={theme} />

            {/* Main Content */}
            <main
              className={`layout-main ${navigation.navWidth === '275px' ? 'opened' : 'closed'}`}
              onScroll={listenScroll}
            >
              <div id="main-content" className="h-100 w-100">
                {children}
              </div>
            </main>
          </div>
        </div>
      </div>

      {/* Absolutely positioned Toast component */}
      <Toast />

      {/* Absolutely positioned Dock component */}
      <Dock render={dock.render} />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.object.isRequired,
  progressBar: PropTypes.shape({
    progress: PropTypes.number.isRequired,
    inProgress: PropTypes.bool.isRequired,
  }).isRequired,
  navigation: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    progressBar: state.progressBar,
    navigation: state.navigation,
    dock: state.dock,
  };
};

export default connect(mapStateToProps)(Layout);
