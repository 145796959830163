import { useState } from 'react';
import { useQuery } from 'react-query';
import { useApp } from '../../react';
import { Accordion, Button, Card, Col, Row } from 'react-bootstrap';
import { Text } from '../../components';
import { Api, favoriteService } from '../../services';
import './Favorites.scss';

async function fetchFavorites({ appId }) {
  if (!appId) return [];
  const data = await favoriteService.getFavorites(appId);
  return data;
}

export function RecentlyViewedTab({ eventKey, items, onClickItem, refresh, reset }) {
  const app = useApp();
  const [reload, setReload] = useState<boolean>(false);
  const favoritesRes = useQuery<any>(['recents-favorites', reload, reset], () =>
    fetchFavorites({ appId: app?.id }),
  );

  const unfavoriteItem = async (type, id) => {
    switch (type) {
      case 'Asset':
        await favoriteService.unfavoriteAsset(id);
        break;
      case 'Map':
        await Api.delete(`/maps/${id}/favorites`);
        break;
      case 'Place':
        await favoriteService.unfavoritePlace(id);
        break;
    }
    setReload(!reload);
    refresh();
  };

  const favoriteItem = async (type, id) => {
    switch (type) {
      case 'Asset':
        await favoriteService.favoriteAsset(id);
        break;
      case 'Map':
        await Api.post(`/maps/${id}/favorites`);
        break;
      case 'Place':
        await favoriteService.favoritePlace(id);
        break;
    }
    setReload(!reload);
    refresh();
  };

  return (
    <Accordion.Item eventKey={eventKey} className="recently-viewed-accordion">
      <Accordion.Header className="d-flex align-items-center cursor-pointer fs-lg fw-bold btn-light recently-viewed-accordion-header">
        <div className="icon-wrap d-flex align-items-center text-right p-2">
          <i className="sonarIcon sonarIcon-history cursor-pointer" />
        </div>
        &emsp; Recently Viewed
      </Accordion.Header>
      <Accordion.Body className="recently-viewed-accordion-body">
        <Row>
          <Col>
            <Card.Body>
              {!favoritesRes.isLoading && favoritesRes.isSuccess && (
                <div className="d-flex flex-column">
                  {items.map((item, index) => {
                    let colorClass;
                    switch (item.itemType) {
                      case 'Asset':
                        colorClass = 'primary-color';
                        break;
                      case 'Map':
                        colorClass = 'alt-color';
                        break;
                      case 'Place':
                        colorClass = 'tertiary-color';
                        break;
                    }
                    return (
                      <div
                        key={index}
                        className="favorite-tab d-flex flex-row justify-content-left align-items-center mb-1"
                      >
                        {favoritesRes.data.data?.filter((e) => e.itemId === item.itemId).length >
                        0 ? (
                          <i
                            className={`${colorClass} remove-favorite sonarIcon sonarIcon-star-fill fs-1x cursor-pointer`}
                            onClick={() => unfavoriteItem(item.itemType, item.itemId)}
                          />
                        ) : (
                          <i
                            className={`${colorClass} remove-favorite sonarIcon sonarIcon-star-empty fs-1x cursor-pointer`}
                            onClick={() => favoriteItem(item.itemType, item.itemId)}
                          />
                        )}
                        &emsp;
                        <Button
                          className="d-flex flex-row justify-content-left align-items-center text-nowrap"
                          variant="light"
                          onClick={() => onClickItem(item)}
                        >
                          <i
                            className={`${colorClass} sonarIcon sonarIcon-${item.itemType.toLowerCase()}`}
                          />
                          <Text title={item.name}>&nbsp; {item.name}</Text>
                        </Button>
                      </div>
                    );
                  })}
                </div>
              )}
            </Card.Body>
          </Col>
        </Row>
      </Accordion.Body>
    </Accordion.Item>
  );
}
