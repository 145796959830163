import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { Component, FormEvent } from 'react';
import styled from 'styled-components';

// #region STYLED COMPS
const InputWrapper = styled.div`
  background-color: var(--body);
  border-color: #ced4da;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  cursor: text;
  max-width: 100%;
  display: flex;
  height: 32px;
  font-size: 14px;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  overflow-wrap: break-word;
  transition: background 0.15s ease-in-out, border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:active,
  &:focus-within {
    border-color: none;
    box-shadow: none;
  }
`;

const Input = styled.input`
  background-color: transparent;
  border: 0px none;
  color: inherit;
  cursor: inherit;
  font-family: inherit;
  min-width: 0px;
  outline: currentcolor none medium;
  padding: 0px 6px;
  width: 100%;
  height: 100%;
  line-height: 1.5;
`;

const Button = styled.button`
  display: inline-flex;
  max-width: 100%;
  width: auto;
  height: auto;
  border: none;
  padding: 0px;
  margin-right: 4px;
  outline: currentcolor none medium !important;
  background: rgba(0, 0, 0, 0) none repeat scroll 0% 0%;
  border-radius: 3px;
  transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
  &:hover {
    background-color: transparent;
  }
  &:focus,
  &:active {
    background-color: #e0ecfc;
  }
`;
const IconWrapper = styled.div`
  display: inline-flex;
  max-width: 100%;
  width: auto;
  height: auto;
  padding: 0px;
  margin-right: 4px;
`;
const Span = styled.span`
  height: 16px;
  width: 16px;
  color: currentcolor;
  display: inline-block;
  fill: #fff;
  flex-shrink: 0;
  line-height: 1;
`;
// #endregion STYLED COMPS

const defaultProps = {
  id: null,
  initSearch: '',
  placeholder: '',
  debounceTimeSec: 0,
};

type Props = typeof defaultProps & {
  onChange: (search: string) => void;
};

type State = {
  search: string;
};

class SearchInput extends Component<Props, State> {
  static defaultProps = defaultProps;
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      search: props.initSearch || defaultProps.initSearch,
    };
  }

  // These mount functions are just to ensure search persists thru screen refreshes, and are removed after navigation away
  componentDidMount() {
    let searchHistory = JSON.parse(sessionStorage.getItem('searchHistory'));
    let key = this.props.id;

    if (!searchHistory) {
      sessionStorage.setItem('searchHistory', JSON.stringify({}));
      this.setState({ search: defaultProps.initSearch });
    } else if (!key || !searchHistory[key]) {
      return;
    } else {
      this.setState({ search: searchHistory[key] });
    }
  }
  componentWillUnmount() {
    let searchHistory = JSON.parse(sessionStorage.getItem('searchHistory'));
    let key = this.props.id;
    delete searchHistory[key];

    sessionStorage.setItem('searchHistory', JSON.stringify(searchHistory));
  }

  onChange = (e: FormEvent<HTMLInputElement>) => {
    const search = e.currentTarget.value;

    let searchHistory = JSON.parse(sessionStorage.getItem('searchHistory'));
    let key = this.props.id;
    searchHistory[key] = search;

    sessionStorage.setItem('searchHistory', JSON.stringify(searchHistory));
    this.setState({ search }, () => {
      this.debouncedOnChange();
    });
  };

  debouncedOnChange = debounce(() => {
    const encodedSearch = encodeURIComponent(this.state.search);
    this.props.onChange(encodedSearch);
  }, this.props.debounceTimeSec * 1000);

  clearSearch = () => {
    // @ts-ignore
    // TODO: figure out how to type onChange with fake event
    this.onChange({ currentTarget: { value: '' } });
  };

  render() {
    return (
      <InputWrapper>
        <Input
          value={this.state.search}
          onChange={this.onChange}
          placeholder={this.props.placeholder || 'Search'}
        />
        {this.state.search.length === 0 && (
          <IconWrapper>
            <Span role="presentation">
              <svg width="16" height="16" viewBox="0 0 24 24" role="presentation" focusable="false">
                <path
                  d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
                  fill="var(--dark)"
                  fillRule="evenodd"
                ></path>
              </svg>
            </Span>
          </IconWrapper>
        )}
        {this.state.search.length > 0 && (
          <Button aria-label="Clear filter" type="button" onClick={this.clearSearch}>
            <Span role="presentation">
              <svg width="16" height="16" viewBox="0 0 24 24" role="presentation">
                <path
                  d="M12 10.586L6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 1 0-1.414-1.414L12 10.586z"
                  fill="var(--dark)"
                ></path>
              </svg>
            </Span>
          </Button>
        )}
      </InputWrapper>
    );
  }
}

export default SearchInput;
