import './AssetSearch.scss';
import { Icon, PaginatedSearch, Text } from '@components';
import { useTableState } from '@react';
import { Api } from '@services';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { V2FilterPayload } from '../../../../AssetFilter/functions/V2FilterPayload';

const limit = 20;

async function fetchData({ search }) {
  try {
    const { data } = await Api.get(`v2/assets/asset-list?filter=${search}`);
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const AssetSearch = ({ onSelectAsset, goBack, onClickNext, initAsset = null }) => {
  const [asset, setAsset] = useState(initAsset);

  const { search, setSearch, page, setPage } = useTableState({ initSearch: initAsset?.name });

  const _setAsset = (theAsset) => {
    setAsset(theAsset);
    onSelectAsset(theAsset);
  };

  const onSelectItem = ({ item: asset, hideItems }) => {
    setSearch(asset.name);
    hideItems();
    _setAsset(asset);
  };

  const renderSelectedItem = ({ item: asset }) => {
    const assetName = asset?.name ? <Text variant="primary">{asset.name}</Text> : '';
    return <Text>Selected Asset {assetName}</Text>;
  };

  const res = useQuery(
    ['assetSearch-listAssets', page, { search }],
    () => fetchData({ page, search }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  );

  const isBtnDisabled = () => {
    if (!asset) return true;

    return false;
  };

  return (
    <Container className="text-center d-flex flex-column justify-content-between h-100">
      <Row>
        <Col xs={12}>
          <div className="mb-3">
            <Icon name="search" variant="dark" size="lg" className="fw-bold" />
            <Text className="fs-lg">Search Asset by Name</Text>
          </div>
          <PaginatedSearch
            items={res.data?.items}
            search={search}
            setSearch={setSearch}
            page={page}
            setPage={setPage}
            pageCount={Math.ceil(res.data?.count / limit)}
            loading={res.isLoading || res.isFetching}
            renderItem={({ item }) => <Text>{item.name}</Text>}
            refetch={res.refetch}
            placeholder="Search Assets"
            onSelectItem={onSelectItem}
            renderSelectedItem={renderSelectedItem}
            renderNotSelectedItem={() => (
              <Text variant="danger">Search for an existing Asset with the Search Bar above</Text>
            )}
            onClearSelectedItem={() => _setAsset(null)}
            initSelectedItem={initAsset}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="d-flex flex-row justify-content-center">
            <Button variant="secondary" className="my-2 back-btn" onClick={goBack}>
              Back
            </Button>
          </div>

          <Button
            variant="primary"
            className="my-2 next-btn"
            onClick={onClickNext}
            disabled={isBtnDisabled()}
          >
            Next
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

AssetSearch.propTypes = {
  onSelectAsset: PropTypes.func.isRequired,
  onClickNext: PropTypes.func,

  // defaults
  initAsset: PropTypes.object,
};

export default AssetSearch;
