import '../../../scss/_export.module.scss';
import { withDock } from '../../../react';
import Icon from '../../Icon/Icon';

/**
 * @desc Wrapper component to easily close Dock.
 */
function DockContainer({ children, closeDock }) {
  return (
    <div className="h-100 d-flex flex-column p-3">
      <div className="d-flex flex-row justify-content-end">
        <Icon
          name="add"
          variant="light"
          size="lg"
          disabled={false}
          onClick={closeDock}
          showPointer={true}
          className="sonarIcon-rotate-45 align-sub exit-icon"
        />
      </div>
      {children}
    </div>
  );
}

export default withDock(DockContainer);
