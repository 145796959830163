// Asset Screen Id, Map, & Place Details
import PropTypes from 'prop-types';
import Icon from '../../components/Icon/Icon';
import Text from '../../components/Text/Text';

function AssetScreenDetails({ assetId, mapName, placeName }) {
  return (
    <div className="d-flex flex-row flex-wrap justify-content-between w-100 px-5 asset-details">
      <div key="Asset ID" className="d-flex mt-3 mb-3 justify-content-center">
        <i className={'fa fa-passport fs-3x px-1 sonarIcon-primary me-auto'}></i>
        <div className="d-flex flex-column align-items-center me-auto">
          <Text as="h6">Asset Id</Text>
          <Text>{assetId || '--'}</Text>
        </div>
      </div>
      <div key="Map Name" className="d-flex mt-3 mb-3 justify-content-center">
        <Icon name="map" variant="dark" size="3x" disabled={false} showPointer={false} />
        <div className="d-flex flex-column align-items-center me-auto">
          <Text as="h6">Map</Text>
          <Text>{mapName || '--'}</Text>
        </div>
      </div>
      <div key="Place Name" className="d-flex mt-3 mb-3 justify-content-center">
        <Icon name="place" variant="dark" size="3x" disabled={false} showPointer={false} />
        <div className="d-flex flex-column align-items-center me-auto">
          <Text as="h6">Place</Text>
          <Text>{placeName || '--'}</Text>
        </div>
      </div>
    </div>
  );
}

AssetScreenDetails.propTypes = {
  assetId: PropTypes.string,
  mapName: PropTypes.string,
  placeName: PropTypes.string,
};

export default AssetScreenDetails;
