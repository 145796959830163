import { Button } from 'react-bootstrap';
import { Text } from '../..';
export function ObjectPopup({ properties }) {
  return (
    <div className="d-flex flex-column align-items-left">
      <div className="d-flex flex-row align-items-center">
        <Text className="fw-bold fs-md">Name:&nbsp;</Text>
        <Text className="fs-md">{properties.Name}</Text>
      </div>
      <div className="d-flex flex-row align-items-center">
        <Text className="fw-bold fs-md">Type:&nbsp;</Text>
        <Text className="fs-md">{properties.EntityType}</Text>
      </div>

      <div className="d-flex flex-row align-items-center justify-content-between mt-3">
        <Button
          id={`copy-btn-${properties.Id}`}
          title="Copy name"
          className="btn-light d-flex justify-content-between align-items-center nav-text-icon me-3"
          variant="light"
        >
          <i className="col-1 far fa-lg fa-copy" />
        </Button>
        {properties.EntityType === 'Asset' ||
          (properties.EntityType === 'Stationary Asset' && (
            <Button
              id={`go-to-btn-${properties.Id}`}
              title="Go to Asset"
              className="btn-light d-flex justify-content-between align-items-center nav-text-icon me-3"
              variant="light"
            >
              <i className="sonarIcon sonarIcon-open-in fs-md"></i>
            </Button>
          ))}
        <Button
          id={`more-btn-${properties.Id}`}
          title="Show more"
          className="btn-light d-flex justify-content-between align-items-center nav-text-icon"
          variant="light"
        >
          <i className="sonarIcon sonarIcon-h-dots-1 fs-md" />
        </Button>
      </div>
    </div>
  );
}
