import { useRef } from 'react';
import { Row, Col, Container, ListGroup, Button } from 'react-bootstrap';
import Text from '../../Text/Text';
import { CSVLink } from 'react-csv';
import '../../../scss/variables.scss';
import '../../../scss/icons/icons.scss';
import './OverrideModal.scss';

function OverrideModal({ overrideItems, forceUpload, cancel, csvFile, itemName }) {
  const csvLinkRef = useRef<any>();

  return (
    <Container className="override-modal">
      <Row>
        <Col>
          <Text as="h1">
            Are You Sure?
            <div className="d-flex flex-row justify-content-center">
              <div className="sa mb-3">
                <div className="sa-warning mx-auto">
                  <div className="sa-warning-body"></div>
                  <div className="sa-warning-dot"></div>
                </div>
              </div>
            </div>
          </Text>
        </Col>
        <Col xs={12}>
          <Text as="p">
            {`By uploading this file, the following Beacons will be reassigned from previous Assets. Select 'Yes' to continue. Select 'Cancel' to make changes.`}
          </Text>
          <ListGroup>
            {overrideItems.map((item, index) => (
              <ListGroup.Item key={index}>
                <Text>{item.model.existingBeaconIds}</Text>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>

      <Row className="my-4">
        <Col>
          <div className="d-flex flex-row justify-content-center">
            <Button
              className="cancel"
              variant="secondary"
              onClick={() => {
                cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              className="yes"
              onClick={() => {
                forceUpload();
              }}
            >
              Yes
            </Button>
          </div>
        </Col>
      </Row>
      <div className="d-flex flex-row justify-content-center">
        <CSVLink
          className="download-csv"
          ref={csvLinkRef}
          filename={`Override-${itemName}`}
          headers={csvFile.headers}
          data={overrideItems.map((item) => {
            if (item.model.hasOwnProperty('name')) {
              return {
                name: item.model.name,
                deviceId: item.model.deviceId,
              };
            } else {
              return {
                newAssetName: item.model.newAssetName,
                keywords: item.model.keywords,
                keyValuePairs: item.model.keyValuePairs,
                existingBeaconDeviceName: item.model.existingBeaconIds,
              };
            }
          })}
        >
          Download CSV with reassigned Beacons.
        </CSVLink>
      </div>
    </Container>
  );
}

export default OverrideModal;
