import './BeaconNameAssignedDateRenderer.scss';
import moment from 'moment';

const BeaconNameAssignedDateRenderer = ({ data }) => {
  const assignedDate = moment(data.assigned).format('lll');

  return (
    <div className="d-flex flex-column associated-beacons-name-column">
      <span className="fs-lg">{data.name}</span>

      <span className="fs-xsm">Assigned On: {assignedDate}</span>
    </div>
  );
};

export default BeaconNameAssignedDateRenderer;
