import './dropzone.scss';
import PropTypes from 'prop-types';
import { Component, Fragment } from 'react';
import ReactDropzone from 'react-dropzone';
import Text from '../Text/Text';

/**
 * @desc Customized Dropzone for files.
 */
class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      className: '',
      files: '',
    };
  }

  onDrop = (files) => {
    if (!files.length) return;

    // if multiple isnt specified, just pass the file for ease
    const _files = this.props.multiple ? files : files[0];

    this.setState({ className: 'dropZoneReady', files: _files }, () => {
      this.props.onDrop(_files);
    });
  };

  renderFileSelected = () => {
    return (
      <div>
        <i className="fas fa-check fa-6x" />

        {/* Display names of selected file(s) */}
        {this.props.multiple ? (
          <Fragment>
            <div>Selected Files:</div>
            {this.state.files.map((file, index) => (
              <div key={index}>{file.name}</div>
            ))}
          </Fragment>
        ) : (
          <div>Selected file: {this.state.files.name}</div>
        )}
      </div>
    );
  };

  renderFileNotSelected = () => {
    return (
      <div>
        <div>Drag and drop your file</div>
        <div>or</div>
        <div>Select a file from your computer</div>
        <button type="button" className="btn btn-link" title="Choose File">
          Choose File
        </button>
      </div>
    );
  };

  render() {
    const { disabled, disabledText } = this.props;

    return (
      <div className="d-flex flex-column w-100 align-items-center">
        <ReactDropzone
          id="hoverZone"
          className={`${this.state.className} ${
            disabled ? 'opacity-5' : ''
          } hoverZone d-flex flex-row flex-nowrap justify-content-center align-items-center align-content-center p-2 cursor-pointer`}
          onDrop={this.onDrop}
          accept={this.props.accept}
          multiple={this.props.multiple}
          disabled={disabled}
        >
          <div className="text-center">
            {this.state.className.length > 2
              ? this.renderFileSelected()
              : this.renderFileNotSelected()}
          </div>
        </ReactDropzone>
        {this.props.disabled && <Text variant="warning">{disabledText}</Text>}
      </div>
    );
  }
}

Dropzone.defaultProps = {
  multiple: false,
  disabled: false,
  disabledText: '',
};

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  accept: PropTypes.string.isRequired,

  // defaults
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledText: PropTypes.string,
};

export default Dropzone;
