import { Icon, Text } from '@components';
import { useOnMount } from '@react';
import { Api } from '@services';
import { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Select2 from 'react-select';
import styled from 'styled-components';
import { V2FilterPayload } from '../AssetFilter/functions/V2FilterPayload';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  FilterTypes,
  IAssetFilterModel,
  IdentifierFilter,
  IIdentifierFilter,
} from './IAssetFilterModel';
import '../AssetChart/HomeCharts.scss';

interface IRenderIdentifierProps {
  model: IIdentifierFilter;
  onRemove: (id: string) => void;
  onChange: (id: string, value: string) => void;
}

interface IAssetFilterProps {
  model: IAssetFilterModel;
  onChange: (filter: string) => void;
  layout?: String;
  label?: String;
  mapId?: Number;
  initFilter?: Number;
  doNotUse?: Array<String>;
  uniqueNameList?: Boolean;
  isMulti?: Boolean;
  formik?: any;
}
const renderTooltip = () => (
  <Tooltip id="button-tooltip">
    <ul className="me-3">
      {' '}
      <li className="mt-3">
        <strong>Name filter</strong> - Filter and displays assets that are named alike.
      </li>{' '}
      <li className="mt-3">
        <strong>Keywords</strong> - Filter and display assets by their keyword associations.
      </li>{' '}
    </ul>
  </Tooltip>
);

const StyledSelect2 = styled(Select2)`
  .Select__control {
    background-color: #fff;
    border-color: #ced4da;
    border-radius: 0.25rem;
    border-width: 1px;
    border-style: solid;
    transition: background 0.15s ease-in-out, border 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:active,
    &:focus-within {
      border-color: var(--c-primary);
      box-shadow: 0 0 0 1px var(--c-primary);
    }
    &:hover {
      background-color: #fafafb;
    }
  }
  .Select__menu {
    z-index: 2 !important;
  }
  .Select__menu-list {
    color: black;
    [class*='option--is-selected'] {
      background-color: var(--c-primary) !important;
    }
    [class*='option--is-focused'] {
      background-color: #e0ecfc;
    }
  }
`;

export function AnalyticsAssetFilter({
  model,
  onChange,
  layout,
  label,
  mapId,
  doNotUse,
  uniqueNameList,
  initFilter,
  isMulti,
  formik,
}: IAssetFilterProps) {
  const [stateModel, setStateModel] = useState<IAssetFilterModel>(model);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [identifiers, setIdentifiers] = useState<string[]>([]);
  const [assets, setAssets] = useState<any[]>([]);
  const firstFocus = useRef(null);
  useOnMount(() => firstFocus.current?.focus());

  function ToFilterString(model: IAssetFilterModel): string {
    switch (model.FilterType) {
      case FilterTypes.All:
        return 'all';
      case FilterTypes.Identifiers:
        return (
          'identifiers(' + model.Identifiers.map((i) => i.Key + '=' + i.Filter).join(',') + ')'
        );
      case FilterTypes.Keywords:
        return 'keywords(' + model.Keywords.join(',') + ')';
      case FilterTypes.Name:
        return 'name(' + model.Filter + ')';
    }
  }

  function HandleChange(value: any, fieldName: keyof IAssetFilterModel) {
    setStateModel({ ...stateModel, [fieldName]: value });
  }

  function AddRemoveIdentifier(value: string) {
    const { Identifiers } = stateModel;
    if (Identifiers.findIndex((x) => x.Key === value) > -1) {
      HandleChange(
        Identifiers.filter((_, i) => i !== Identifiers.findIndex((x) => x.Key === value)),
        'Identifiers',
      );
    } else {
      HandleChange([...Identifiers, new IdentifierFilter(value)], 'Identifiers');
    }
  }

  useEffect(() => {
    let filter = ToFilterString(stateModel);
    onChange(filter);
  }, [stateModel]);

  useEffect(() => {
    const nState = { ...stateModel };
    delete nState.FilterType;
    setStateModel(nState);
    let filter = ToFilterString(stateModel);
    onChange(filter);

    formik.setValues({ ...formik.values, filterType: '' });
  }, [mapId]);

  useEffect(() => {
    switch (stateModel.FilterType) {
      case FilterTypes.All: {
        const defaultPayload = V2FilterPayload(
          [],
          '',
          0,
          [{ field: 'name', ascending: true }],
          20,
          [{ field: 'mapId', type: 'contains', filter: mapId }],
          '',
        );

        const fetch = async () => {
          try {
            const result = await Api.post<any, any>('/v2/assets/filter/', defaultPayload);
            setAssets(result.data);
          } catch (ex) {}
        };
        fetch();
        break;
      }
      case FilterTypes.Identifiers: {
        const fetch = async () => {
          try {
            const result = await Api.get<string[]>(
              '/assets/filtered/*/identifiers' + (mapId ? `?mapId=${mapId}` : ''),
            );
            setIdentifiers(result.data);
          } catch (ex) {}
        };

        fetch();
        break;
      }
      case FilterTypes.Name: {
        const fetch = async () => {
          try {
            const result = await Api.get<any>(
              '/assets/filtered/*/namesAndIds?top=10000' + (mapId ? `&mapId=${mapId}` : ''),
            );
            setAssets(result.data);
          } catch (ex) {}
        };

        fetch();
        break;
      }
      case FilterTypes.Keywords: {
        const fetch = async () => {
          try {
            const result = await Api.get<string[]>(
              '/assets/filtered/*/keywords' + (mapId ? `?mapId=${mapId}` : ''),
            );
            setKeywords(result.data.map((k) => k.trim()));
          } catch (ex) {}
        };

        fetch();
      }
    }
  }, [stateModel.FilterType]);

  return (
    <>
      <Form.Group className={`${layout !== 'vertical' ? 'w-100 d-flex flex-row' : 'pt-2'}`}>
        <div className="row">
          <div className="col">
            <Form.Label className={layout !== 'vertical' ? 'w-25 pt-2' : 'w-100 pt-2'}>
              {label ?? 'Asset Filter'}
            </Form.Label>
          </div>
          {layout === 'vertical' && (
            <div className="col-1 me-3">
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip()}
              >
                <span className="d-inline-block">
                  <div>
                    <Icon name="info" variant="dark" size="md" disabled={false} />
                  </div>
                </span>
              </OverlayTrigger>
            </div>
          )}
        </div>
        <div className={layout !== 'vertical' ? 'w-25' : 'w-100'}>
          <StyledSelect2
            classNamePrefix={'Select'}
            ref={firstFocus}
            onChange={(e) => {
              let value = e.value;
              if (value !== '-1') {
                HandleChange(FilterTypes[value], 'FilterType');
              }
            }}
            options={Object.values(FilterTypes)
              ?.filter((f) =>
                doNotUse ? !doNotUse.map((dn) => dn.toLowerCase()).includes(f.toLowerCase()) : true,
              )
              ?.map((f, i) => {
                return {
                  value: f,
                  label: f,
                };
              })}
            value={{
              value: stateModel.FilterType ?? FilterTypes.All,
              label: stateModel.FilterType ?? 'Select a Filter',
            }}
            placeholder={'Select a Filter'}
          />
        </div>
        <div className={`${layout !== 'vertical' ? 'w-75' : 'w-100 pt-2'} d-flex flex-wrap`}>
          {[FilterTypes.Name].includes(stateModel.FilterType) && (
            <div className="w-100">
              <StyledSelect2
                isMulti={isMulti}
                classNamePrefix={'Select'}
                onChange={(e: any) => {
                  if (stateModel.FilterType === FilterTypes.Name) {
                    if (isMulti) {
                      HandleChange(
                        e
                          .map((f) => f.label)
                          .filter((value: any, index: any, self: any) => {
                            return self.indexOf(value) === index;
                          })
                          .join(','),
                        'Filter',
                      );
                    } else {
                      HandleChange(e.label, 'Filter');
                    }
                  } else {
                    let value = parseInt(e.value);
                    if (value > -1) {
                      HandleChange(value, 'AssetId');
                    }
                  }
                }}
                value={
                  !isMulti && assets.filter
                    ? assets
                        .filter((a) =>
                          Boolean(stateModel.AssetId === a.id || stateModel.Filter === a.name),
                        )
                        .map((a, i) => {
                          return {
                            value: a.id,
                            label: a.name,
                          };
                        })
                    : ''
                }
                options={
                  assets.filter
                    ? assets
                        .filter((value, index, self) => {
                          if (uniqueNameList) {
                            return self.map((s) => s.name).indexOf(value.name) === index;
                          } else {
                            return true;
                          }
                        })
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((a, i) => {
                          return {
                            value: a.id,
                            label: a.name,
                          };
                        })
                    : null
                }
              />
            </div>
          )}

          {stateModel.FilterType === FilterTypes.Identifiers && (
            <div className={'w-100'}>
              <StyledSelect2
                classNamePrefix={'Select'}
                onChange={(e: any) => {
                  const value = e.value;
                  if (value !== '') {
                    AddRemoveIdentifier(value);
                  }
                }}
                options={identifiers.sort().map((k, i) => {
                  return {
                    value: k,
                    label: k,
                  };
                })}
              />

              <div>
                {stateModel.Identifiers.map((f, i) => (
                  <RenderIdentifier
                    key={i}
                    model={f}
                    onRemove={(key) => AddRemoveIdentifier(key)}
                    onChange={(key, value) => {
                      const { Identifiers } = stateModel;
                      HandleChange(
                        Identifiers.map((i: IdentifierFilter) => {
                          if (i.Key === key) {
                            i.Filter = value;
                          }
                          return i;
                        }),
                        'Identifiers',
                      );
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          {stateModel.FilterType === FilterTypes.Keywords && (
            <div className="w-100">
              <StyledSelect2
                classNamePrefix={'Select'}
                className="w-100"
                isMulti={isMulti}
                value={keywords
                  .filter((k) => {
                    return Boolean(stateModel.Keywords.includes(k) && k.trim().length);
                  })
                  .sort()
                  .map((k, i) => {
                    return {
                      value: k,
                      label: k,
                    };
                  })}
                options={keywords.sort().map((k, i) => {
                  return {
                    value: k,
                    label: k,
                  };
                })}
                onChange={(e) => {
                  HandleChange(
                    (Array.isArray(e) ? e : [e])
                      .map((f) => f.label)
                      .filter((value, index, self) => {
                        return self.indexOf(value) === index;
                      }),
                    'Keywords',
                  );
                }}
              />
            </div>
          )}
        </div>
      </Form.Group>
    </>
  );
}

function RenderIdentifier({ model, onRemove, onChange }: IRenderIdentifierProps) {
  return (
    <div className={'w-100 d-flex flex-row align-items-center'} key={model.Key}>
      <div className={'w-20'}>
        <Text>{model.Key}</Text>
      </div>
      <Form.Control
        type="text"
        as={'input'}
        value={model.Filter}
        onChange={(e) => {
          onChange(model.Key, e.target.value);
        }}
      />
      <button
        className={'btn btn-small m-2'}
        onClick={() => {
          onRemove(model.Key);
        }}
      >
        x
      </button>
    </div>
  );
}
