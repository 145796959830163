import './AdminUsers.scss';
import { Dropzone, Icon } from '@components';
import { forwardRef } from 'react';
import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import AppSelector from '../../userMgmt/AppSelector';
import InviteUsersModal from '../../userMgmt/InviteUsersModal';
import { inviteUsers } from '../../userMgmt/utils';

function getCsvHeaders() {
  return [
    { label: 'Email', key: 'userName' },
    { label: 'Role (ReadOnly | User | Admin)', key: 'role' },
  ];
}

function getExampleCsvData() {
  return [{ userName: 'replacethis@email.com', role: 'ReadOnly' }];
}

const AdminAddUser = ({ closeDock, inviteType, appIdSelected, appNameSelected }, ref) => {
  const [users, setUsers] = useState([]);
  const [appVal, setAppVal] = useState(appIdSelected);
  const [appName, setAppName] = useState(appNameSelected);
  const [loading, setLoading] = useState(false);

  const onUploadUsers = async () => {
    setLoading(true);
    await inviteUsers({ users, appName });
    closeDock();
    setLoading(false);
  };

  const onDropCsv = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const csvUsers = e.target.result
        .replace(/(\r\n|\r)/g, '\n')
        .split('\n')
        .filter(Boolean)
        .slice(1) // remove headers
        .map((csvString) => {
          const [userName, role] = csvString.split(',').map((str) => str.replace(/"/g, ''));

          return {
            userName: userName.trim(),
            role: role.trim(),
            applicationId: appVal,
          };
        });

      setUsers(csvUsers);
    };

    reader.onerror = (e) => {
      toast.error('Failed to read the CSV file. Please try again.');
    };

    reader.readAsText(file);
  };

  return (
    <div ref={ref} className="d-flex flex-column p-3 h-100 invite-users-slide-out">
      {/* X Close Icon */}
      <div className="d-flex flex-row justify-content-end exit">
        <Icon
          name="add"
          variant="light"
          size="lg"
          disabled={false}
          onClick={closeDock}
          showPointer={true}
          className="sonarIcon-rotate-45 align-sub exit-icon"
        />
      </div>
      {/* Title */}
      <div className="d-flex flex-row justify-content-center position-relative">
        <h4>Invite Users</h4>
      </div>
      {/* CSV Invite Users */}
      {inviteType === 'CSV' && (
        <div className="add-user-wrapper">
          <AppSelector
            appVal={appVal}
            appName={appName}
            setAppVal={setAppVal}
            setAppName={setAppName}
          />

          <hr />

          <p className="text-center">
            Download the CSV template to include the Users you would like to add, and then upload
            the completed CSV
          </p>
          <div className="d-flex mt-4 mb-4 justify-content-center">
            <CSVLink
              data={getExampleCsvData()}
              headers={getCsvHeaders()}
              filename="Invite-Users-Template.csv"
              variant="secondary"
              className="btn btn-secondary"
            >
              Download CSV
            </CSVLink>
          </div>

          <div className="d-flex mb-4 justify-content-center">
            <Dropzone
              onDrop={onDropCsv}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              disabled={!appVal}
              disabledText="Please select an application first."
            />
          </div>

          <div className="upload-btn d-flex flex-row justify-content-center">
            <Button
              variant="primary"
              title="Invite Users"
              onClick={onUploadUsers}
              disabled={!appVal || !users.length}
            >
              Upload
            </Button>

            <Button variant="secondary" title="Cancel" onClick={closeDock}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      {/* Email Invite Users */}
      {inviteType === 'EMAIL' && (
        <InviteUsersModal
          appVal={appVal}
          appName={appName}
          setAppVal={setAppVal}
          setAppName={setAppName}
          onCancel={closeDock}
        />
      )}
    </div>
  );
};

export default forwardRef(AdminAddUser);
